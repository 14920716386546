import { OrgType } from "source/Types";
import { IntegrationStatus } from "source/types/matrix/fastBuild.types";
import { FastBuildSource } from "source/types/matrix/fastBuild.types";
import { queryClient } from "pages/_app";
import { OrgsData } from "source/api/orgs/useQueryOrgs";
import api from "source/api";

export const getIntegrationStatuses = (
  currentOrg: OrgType | undefined | null
): Partial<Record<FastBuildSource, any>> => {
  if (!currentOrg?.integrations)
    return { [FastBuildSource.LOCAL]: IntegrationStatus.ENABLED_ACTIVE };

  return Object.values(FastBuildSource).reduce((statuses, source) => {
    const integrationKey = `${source}_integration`;

    if (source === FastBuildSource.S3) {
      const s3Integration = currentOrg.integrations.s3_integration || {};
      statuses[source] = Object.fromEntries(
        Object.entries(s3Integration).map(([bucketName, details]) => {
          return [bucketName, details];
        })
      );
    } else if (source === FastBuildSource.LOCAL) {
      // if no entry for local, default to true
      statuses[source] =
        currentOrg.integrations[integrationKey]?.status ||
        IntegrationStatus.ENABLED_ACTIVE;
    } else {
      statuses[source] =
        currentOrg.integrations[integrationKey]?.status || null;
    }
    return statuses;
  }, {});
};

export const getDisableLocalUpload = (currentOrg: OrgType | null) => {
  const localUploadStatus = currentOrg?.integrations?.local_integration?.status;
  // when a new org is created, it will have no localUploadStatus in its integrations column
  // in that case, we want showLocalUpload to default to true
  const showLocalUpload =
    localUploadStatus !== IntegrationStatus.ENABLED_INACTIVE;
  return !showLocalUpload;
};

export const updateIntegrationStatus = async (
  orgId: string,
  integrationType: FastBuildSource,
  status: IntegrationStatus,
  bucketName?: string
) => {
  const org = await api.orgs.updateIntegrationStatus(
    orgId,
    integrationType,
    status,
    bucketName
  );
  queryClient.setQueryData<OrgsData>(
    [{ method: "GET", route: "/v2/orgs" }],
    (orgs) => {
      if (!orgs) return orgs;
      return {
        orgs: orgs?.orgs.map((prevOrg) => {
          if (prevOrg.id === orgId) {
            return org;
          } else {
            return prevOrg;
          }
        }),
      };
    }
  );
  return org;
};
