import React, { forwardRef } from "react";
import { IconButtonProps, IconButton, Spinner } from "../../index";

export interface LoadingIconButtonProps extends IconButtonProps {
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | Promise<void>;
  isLoading?: boolean;
}

const LoadingIconButton = forwardRef<HTMLButtonElement, LoadingIconButtonProps>(
  (props: LoadingIconButtonProps, ref) => {
    const {
      children,
      onClick,
      isLoading: isLoadingProps,
      disabled,
      ...rest
    } = props;

    const [isLoading, setIsLoading] = React.useState(false);

    return (
      <IconButton
        {...rest}
        ref={ref}
        disabled={isLoadingProps || isLoading || disabled}
        onClick={async (e) => {
          if (onClick) {
            setIsLoading(true);
            await onClick(e);
            setIsLoading(false);
          }
        }}
      >
        {isLoadingProps || isLoading ? (
          <Spinner color="inherit" size={"1rem"} />
        ) : (
          children
        )}
      </IconButton>
    );
  }
);

export default LoadingIconButton;
