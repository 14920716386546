import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { ReduxState } from ".";
import {
  ReduxTab,
  Report,
  ReportTab,
  ReportStatus,
  ViewConfig,
  SheetColumnMetadata,
} from "source/components/matrix/types/reports.types";
import { DEFAULT_TOOL_PARAM_OUTPUT_TYPE } from "source/constants";
import logger from "source/utils/logger";
import {
  CellContent,
  ReportCellType,
} from "source/components/matrix/types/cells.types";
import { MatrixHomePanelType } from "source/components/matrix/home/types";
import { DEFAULT_REPORT_NAME_REGEX } from "source/hooks/matrix/useRenameReport";
import { DEFAULT_MARKDOWN_RENDERED } from "source/components/matrix/tables/config";
import {
  generateStaticColumnIdDataMapping,
  getRetrieveColumnFromToolMap,
  getRetrieveColumnIdFromToolMap,
  isAnswerToolType,
  transformReportToolDependencyToReduxTool,
} from "source/utils/matrix/tools";
import { Placement } from "@floating-ui/react";
import {
  ColumnConfig,
  ReduxTool,
  ReportPrompt,
  ReportToolDependency,
  ReportToolParamType,
} from "source/components/matrix/types/tools.types";
import { v4 as uuidv4 } from "uuid";
import { getIsMatrixChatLoading } from "./matrixChat";
import { ModelType } from "source/constants/llms";
import { getDefaultModel } from "source/utils/matrix/models";

export const INITIAL_NUM_COLS = 2;

export type CellType = { static_column_id: string; y: number };

export type CellIdToResultMapType = {
  [id: string]: CellContent;
};

export type ColumnIdYToCellMapType = {
  [tabId: string]: { [columnId: string]: { [y: string]: ReportCellType } };
};

export type ColumnIdToToolMapType = { [columnId: string]: ReduxTool };
export type ColumnIdToPromptMapType = { [columnId: string]: string };
export type ColumnIdToColumnHeaderMapType = { [columnId: string]: string };
export type ColumnIdToToolParamMapType = {
  [columnId: string]: ReportToolParamType;
};

export type ColumnEditorState = {
  columnId: string;
  referenceElement: string | null;
  isExistingColumn: boolean;
  isAISuggestion?: boolean;
  columnOverrides?: ColumnConfig;
  placement?: Placement;
  animate?: boolean;
  outputTypeIsHighlighted?: boolean; // highlight output type for CTA
};

export type PromptGeneratorState = {
  referenceElement: string | null;
};

export type SelectOptionPayload = {
  promptKey: string;
  value: string[] | null;
};

export type AddMatrixUserFailuresPayload = {
  doc_id: string;
  title: string;
  integration?: string;
  failure_reason?: string;
};

export type AddMatrixUserPayload = {
  success: boolean;
  global_failure_reason: string | null;
  failures?: AddMatrixUserFailuresPayload[];
};

export type AddDocsFailedPermissionsUserMap = {
  [userId: string]: AddMatrixUserFailuresPayload[];
};

export type ReportReduxType = {
  activeReport: Report | null;

  tabs: { byId: { [tabId: string]: ReduxTab }; allIds: string[] }; // allIDs stores tab order

  tools: { [tabId: string]: ColumnIdToToolMapType };

  bulkRunCompletedVersion: string;
  isBulkRunning: boolean;

  // Represents how much the header should be set to if it should be overriden for a given row group
  rowGroupHeaderHeights: {
    [groupKey: string]: number | undefined;
  };
  // This is used to tell viewers to refresh their page
  // if the report has been updated
  isReportOutOfDateForViewer: boolean;

  // Used for controlling loading progress of Matrix operation
  reportLoadingProgress: {
    numTotalChunks: number;
    numCompletedChunks: number;
    queueLength: number;
  };

  matrixHomePanel: MatrixHomePanelType;

  // A status to indicate if the report has been fetched, is in error, etc
  reportStatus: ReportStatus;
  initialRowMount: boolean;

  // Tracks view config state for viewers
  localViewConfig: ViewConfig | null;
  isMarkdownRendered: boolean;

  leaseId: string | null;

  nextRowsToRun: number[];

  columnEditor: ColumnEditorState & {
    open: boolean;
  };

  promptGenerator: PromptGeneratorState & {
    open: boolean;
  };

  selectOptions: {
    [promptKey: string]: string[] | null;
  };

  numSelectedRows: number | null;

  // Used to track the status doc access when sharing a matrix
  addMatrixUserStatus: AddMatrixUserPayload | null;

  // Used to track the status doc access when sharing a matrix
  addDocsFailedPermissionsUserMap: AddDocsFailedPermissionsUserMap | null;

  /**
   * @deprecated This is just a hack, ag-grid should support this natively
   */
  temporarySelectAllCheckboxHack: number;
};

export const DEFAULT_COLUMN_EDITOR_STATE = {
  open: false,
  isReadOnly: false,
  columnId: uuidv4(),
  referenceElement: null,
  isExistingColumn: false,
};

const initialState: ReportReduxType = {
  activeReport: null,
  tabs: { byId: {}, allIds: [] },
  tools: {},
  columnEditor: DEFAULT_COLUMN_EDITOR_STATE,
  promptGenerator: {
    open: false,
    referenceElement: null,
  },
  selectOptions: {},
  bulkRunCompletedVersion: "",
  isBulkRunning: false,
  rowGroupHeaderHeights: {},
  isReportOutOfDateForViewer: false,
  reportLoadingProgress: {
    numTotalChunks: 0,
    numCompletedChunks: 0,
    queueLength: 0,
  },
  matrixHomePanel: MatrixHomePanelType.HOME,
  reportStatus: "fetching",
  initialRowMount: false,
  localViewConfig: null,
  leaseId: null,
  isMarkdownRendered: DEFAULT_MARKDOWN_RENDERED,
  nextRowsToRun: [],
  numSelectedRows: null,
  addMatrixUserStatus: null,
  addDocsFailedPermissionsUserMap: null,
  temporarySelectAllCheckboxHack: 0,
};

export const getReportStatus = (state: ReduxState) =>
  state.reports.reportStatus;
export const getReportTabs = (state: ReduxState) => state.reports.tabs;
export const getReportTabsById = (state: ReduxState) => state.reports.tabs.byId;
export const getReportTabsAllIds = (state: ReduxState) =>
  state.reports.tabs.allIds;

export const getBulkRunCompletedVersion = (state: ReduxState) =>
  state.reports.bulkRunCompletedVersion;
export const getIsBulkRunning = (state: ReduxState) =>
  state.reports.isBulkRunning;
export const getRowGroupHeaderHeights = (state: ReduxState) =>
  state.reports.rowGroupHeaderHeights;
export const getMatrixHomePanel = (state: ReduxState) =>
  state.reports.matrixHomePanel;
export const getReportLoadingProgress = (state: ReduxState) =>
  state.reports.reportLoadingProgress;
// Report object and cell properties
export const getActiveReport = (state: ReduxState) =>
  state.reports.activeReport;
/**
 * @deprecated Use useActiveMatrixId hook instead (or just the router)
 */
export const getActiveReportId = (state: ReduxState) =>
  state.reports.activeReport?.id;
export const getActiveReportVersionId = (state: ReduxState) =>
  state.reports.activeReport?.version_id;
export const getActiveReportName = (state: ReduxState) =>
  state.reports.activeReport?.name;
export const getDisplayReport = (state: ReduxState) =>
  state.reports.activeReport;
export const getDisplayReportName = (state: ReduxState) =>
  state.reports.activeReport?.name;
export const getIsReportNameDefault = createSelector(
  getDisplayReport,
  (report) => DEFAULT_REPORT_NAME_REGEX.test(report?.name || "")
);
export const getDisplayReportIsGiga = (state: ReduxState) =>
  state.reports.activeReport?.is_giga;

/**
 * @deprecated Use react-query like  a real person...
 */
export const getDisplayReportUpdatedAt = createSelector(
  getDisplayReport,
  (report) => report?.updated_at
);
export const getSocketLeaseId = (state: ReduxState) => state.reports.leaseId;
export const getActiveTabId = (state: ReduxState) =>
  state.reports.activeReport?.active_tab_id;
export const getReportTools = (state: ReduxState) => state.reports.tools;
export const getisReportOutOfDateForViewer = (state: ReduxState) =>
  state.reports.isReportOutOfDateForViewer;
export const getInitialRowMount = (state: ReduxState) =>
  state.reports.initialRowMount;
export const getLocalViewConfig = (state: ReduxState) =>
  state.reports.localViewConfig;
export const getIsMarkdownRendered = (state: ReduxState) =>
  state.reports.isMarkdownRendered;
export const getNextRowsToRun = (state: ReduxState) =>
  state.reports.nextRowsToRun;
export const getColumnEditorState = (state: ReduxState) =>
  state.reports.columnEditor;
export const getPromptGeneratorState = (state: ReduxState) =>
  state.reports.promptGenerator;
export const getSelectOptions = (state: ReduxState) =>
  state.reports.selectOptions;
export const getNumSelectedRows = (state: ReduxState) =>
  state.reports.numSelectedRows;
export const getAddMatrixUserStatus = (state: ReduxState) =>
  state.reports.addMatrixUserStatus;
export const getAddDocsFailedPermissionsUserMap = (state: ReduxState) =>
  state.reports.addDocsFailedPermissionsUserMap;
export const getTemporarySelectAllCheckboxHack = (state: ReduxState) =>
  state.reports.temporarySelectAllCheckboxHack;

export const getFlattenedAddDocsFailures = createSelector(
  getAddDocsFailedPermissionsUserMap,
  (userMap): AddMatrixUserFailuresPayload[] => {
    return userMap && !_.isEmpty(userMap)
      ? _.flatten(Object.values(userMap))
      : [];
  }
);

/**
 * Gets the active tab object
 * @returns {Object} - ReduxTab
 */
export const getDisplayReportTab = createSelector(
  [getReportTabs, getActiveTabId],
  (tabs, tabId): ReduxTab | undefined => {
    // If no active tab ID try returning first tab
    if (!tabId) return Object.values(tabs.byId)[0];
    return tabs.byId[tabId];
  }
);

export const getDisplayReportTabById = (tabId?: string) =>
  createSelector(getReportTabs, (tabs) =>
    tabId ? tabs.byId[tabId] : undefined
  );

/**
 * Gets the filters object for the active tab
 * @returns {Object} - BackendReportFilter
 */
export const getDisplayReportFilters = createSelector(
  getDisplayReportTab,
  (tab) => tab?.filters
);

// The following mappings are helpful to get a mapping of the specific
// piece of data we want to access, but all of them could be accessed directly
// from the report tools:

/** Get the tools for the current tab
 * @returns {Object} - { [x: number]: ReduxTool }: XToToolMapType
 */
export const getDisplayReportToolMap = createSelector(
  [getReportTools, getActiveTabId],
  (toolMap, tabId) => {
    if (!tabId) return;
    return toolMap[tabId] ?? {};
  }
);

export const getToolOutputTypeForColumn = (columnId: string) =>
  createSelector(
    [getDisplayReportToolMap],
    (toolMap) => toolMap?.[columnId]?.tool_params?.output_type
  );

/** Get the list find columns in the active report tab
 * @returns {number}
 */
export const getDisplayReportFindColumns = createSelector(
  [getDisplayReportToolMap],
  (toolMap): ReduxTool[] =>
    Object.values(toolMap ?? {}).filter(({ tool }) => isAnswerToolType(tool))
);

/** Get the names of the find columns in the active report tabs
 * @returns {string[]}
 */
export const getFindToolColumnNames = createSelector(
  [getDisplayReportToolMap],
  (toolMap): string[] =>
    Object.values(toolMap ?? {})
      .filter(({ tool, name }) => isAnswerToolType(tool, false) && !!name)
      .map(({ name }) => name) as string[]
);

/**
 * Get a mapping of column number to prompt
 * @returns {Object} - { [x: number]: string }: XToPromptMapType
 */
export const getDisplayReportPromptMap = createSelector(
  getDisplayReportToolMap,
  (toolMap): ColumnIdToPromptMapType => {
    if (!toolMap) return {} as ColumnIdToPromptMapType;
    return Object.entries(toolMap).reduce((acc, [x, tool]) => {
      if (tool.prompt) acc[x] = tool.prompt;
      return acc;
    }, {} as ColumnIdToPromptMapType);
  }
);

/**
 * Get a mapping of column number to column header
 * @returns {Object} - { [x: number]: string }: XToColumnHeaderMapType
 */
export const getDisplayReportColumnHeaders = createSelector(
  getDisplayReportToolMap,
  (toolMap) => {
    if (!toolMap) return {};
    return Object.entries(toolMap).reduce((acc, [x, tool]) => {
      if (tool.name) acc[x] = tool.name;
      return acc;
    }, {} as ColumnIdToColumnHeaderMapType);
  }
);

/**
 * Get a mapping of column number to column tool params
 * @returns {Object} - { [x: number]: ReportToolParamType }: XToToolParamMapType
 */
export const getDisplayReportColumnToolParams = createSelector(
  getDisplayReportToolMap,
  (toolMap) => {
    const defaultModel = getDefaultModel();
    if (!toolMap) return {};
    const defaultParams: ReportToolParamType = {
      output_type: DEFAULT_TOOL_PARAM_OUTPUT_TYPE,
      model: defaultModel,
    };
    return Object.entries(toolMap).reduce((acc, [columnId, tool]) => {
      if (tool.name) acc[columnId] = tool.tool_params ?? defaultParams;
      return acc;
    }, {} as ColumnIdToToolParamMapType);
  }
);

/**
 * Get matching report tool by column name. Case-insensitive.
 */
export const getReportToolByColumnName = (columnName: string) =>
  createSelector(getDisplayReportToolMap, (toolMap) => {
    if (toolMap) {
      return Object.values(toolMap).find(
        (tool) => tool.name?.toLowerCase() == columnName.toLowerCase()
      );
    }
  });

export const getSavedPromptForTabAndColumn = (columnId: string) =>
  createSelector(getDisplayReportPromptMap, (reportPromptMap) => {
    if (reportPromptMap) {
      return {
        prompt: reportPromptMap[columnId] ?? "",
        isExistingPrompt: columnId in reportPromptMap,
      };
    }
    return { prompt: "", isExistingPrompt: false };
  });

export const getReportColumnToolParamsForColSelector = (
  columnId: string,
  defaultModel: ModelType
) =>
  createSelector([getReportTools, getActiveTabId], (tools, tabId) => {
    const defaultParams: ReportToolParamType = {
      output_type: DEFAULT_TOOL_PARAM_OUTPUT_TYPE,
      model: defaultModel,
    };
    if (!tabId || !(tabId in tools)) return defaultParams;

    if (tools?.[tabId]?.[columnId]?.tool_params) {
      return tools?.[tabId]?.[columnId]?.tool_params;
    }
    return defaultParams;
  });

export const getCellOverflowMethod = createSelector(
  [getLocalViewConfig, getDisplayReportTab],
  (localViewConfig, tab) => {
    return (
      localViewConfig?.cell_overflow_method ??
      tab?.cell_overflow_method ??
      undefined
    );
  }
);

export const getIsGroupByEnabled = createSelector(
  [getLocalViewConfig],
  (localViewConfig) => {
    return (
      localViewConfig?.grid_configuration?.some((column) => column.rowGroup) ??
      false
    );
  }
);

export const getGridConfiguration = createSelector(
  [getLocalViewConfig, getDisplayReportTab],
  (localViewConfig, tab) => {
    return localViewConfig?.grid_configuration ?? tab?.grid_configuration;
  }
);

export const getColumnViewConfiguration = (columnId: string) =>
  createSelector(getLocalViewConfig, (localViewConfig) => {
    return localViewConfig?.column_view_configuration?.[columnId];
  });

/**
 * Takes in ReportTab.
 * Generates map from columns to ReduxTool types
 */
export const generateTabToolMap = (tab: ReportTab): ColumnIdToToolMapType => {
  const colHeaderMap = generateStaticColumnIdDataMapping<
    SheetColumnMetadata,
    string
  >(tab.column_metadata ?? [], "name");

  const colPromptMap = generateStaticColumnIdDataMapping<ReportPrompt, string>(
    tab.prompts ?? [],
    "prompt"
  );

  const colToolDepsMap = generateStaticColumnIdDataMapping<
    ReportToolDependency,
    ReportToolDependency
  >(tab.tools ?? []);

  const columnIds = _.uniq(
    Object.keys(colHeaderMap)
      .concat(Object.keys(colPromptMap))
      .concat(Object.keys(colToolDepsMap))
  );

  const toolMap = columnIds.reduce(
    (toolMap: ColumnIdToToolMapType, columnId) => {
      const toolDep = colToolDepsMap[columnId];

      if (toolDep)
        toolMap[columnId] = transformReportToolDependencyToReduxTool(toolDep, {
          name: colHeaderMap[columnId],
          prompt: colPromptMap[columnId],
        });
      return toolMap;
    },
    {}
  );

  return toolMap;
};

export const getReportRetrieveTool = createSelector(
  [getReportTools, getActiveTabId],
  (tools, tabId) => {
    if (!tabId || !(tabId in tools)) return;
    // find tool where tool.tool === "retrieve"
    return getRetrieveColumnFromToolMap(tools[tabId] ?? {});
  }
);
export const getRetrieveColumnId = createSelector(
  [getDisplayReportToolMap],
  (tools) => getRetrieveColumnIdFromToolMap(tools)
);

export const disableReportButtons = createSelector(
  [getIsBulkRunning, getIsMatrixChatLoading],
  (isBulkRunning, isMatrixChatLoading) => isBulkRunning || isMatrixChatLoading
);

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    resetGlobalReportState: (state: ReportReduxType) => {
      state = initialState;
      return state;
    },
    setReportStatus: (
      state: ReportReduxType,
      action: PayloadAction<ReportStatus>
    ) => {
      state.reportStatus = action.payload;
      return state;
    },
    updateReportVersion: (
      state: ReportReduxType,
      action: PayloadAction<{ createdAt: string; versionId: string }>
    ) => {
      if (state.activeReport) {
        state.activeReport.created_at = action.payload.createdAt;
        state.activeReport.version_id = action.payload.versionId;
      }
      return state;
    },
    setActiveReportName: (
      state: ReportReduxType,
      action: PayloadAction<string>
    ) => {
      if (!state.activeReport) return;
      state.activeReport.name = action.payload;
      return state;
    },
    setActiveReport: (
      state: ReportReduxType,
      action: PayloadAction<Report>
    ) => {
      state.activeReport = action.payload;
      const toolMap = {} as {
        [tabId: string]: ColumnIdToToolMapType;
      };
      action.payload.tabs.forEach((tab) => {
        toolMap[tab.tab_id] = generateTabToolMap(tab);
      });
      state.tools = toolMap;
      return state;
    },
    setReportTools: (
      state: ReportReduxType,
      action: PayloadAction<{ tabId: string; tools: ColumnIdToToolMapType }>
    ) => {
      const { tabId, tools } = action.payload;
      if (!(tabId in state.tools)) state.tools[tabId] = {};
      state.tools[action.payload.tabId] = tools;
      return state;
    },
    addReportTab: (state: ReportReduxType, action: PayloadAction<string>) => {
      if (state.tabs.allIds.some((tabId) => tabId === action.payload)) {
        logger.error(
          "Attempted to add a tabId that already exists in the report",
          { tabId: action.payload }
        );
        console.error(
          "Attempted to add a tabId that already exists in the report",
          { tabId: action.payload }
        );
        return;
      }
      state.tabs.allIds.push(action.payload);
      state.tabs.byId[action.payload] = {
        tab_id: action.payload,
      };
      return state;
    },
    duplicateReportTab: (
      state: ReportReduxType,
      action: PayloadAction<{
        oldTabId: string;
        newTab: ReduxTab;
      }>
    ) => {
      const { oldTabId, newTab } = action.payload;
      const tools = state.tools;
      const tool = state.tools[oldTabId];
      if (tool) {
        // Copy over tool state
        tools[newTab.tab_id] = tool;
        // Add new tab
        state.tabs.allIds.push(newTab.tab_id);
        state.tabs.byId[newTab.tab_id] = newTab;
      }

      return state;
    },
    /** Overwrites the existing report, but updates cells (adding the new ones, only
     * overwriting the ones that conflict with the old). */
    addReportResponse: (
      state: ReportReduxType,
      action: PayloadAction<{
        tabId: string;
        report: Report;
      }>
    ) => {
      const { tabId, report } = action.payload;

      // Happens if initial report load
      state.activeReport = report;
      const tab = report.tabs.find((tab) => tab.tab_id === tabId);
      if (tab) {
        state.tools[tab.tab_id] = generateTabToolMap(tab);
        // Add tab
        state.tabs.byId[tabId] = { ...tab };
        if (!state.tabs.allIds.includes(tabId)) state.tabs.allIds.push(tabId);
      }

      return state;
    },
    setBulkRunCompletedVersion: (
      state: ReportReduxType,
      action: PayloadAction<string>
    ) => {
      state.bulkRunCompletedVersion = action.payload;
      return state;
    },
    setIsBulkRunning: (
      state: ReportReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.isBulkRunning = action.payload;
      return state;
    },
    setActiveTabId: (
      state: ReportReduxType,
      action: PayloadAction<string | undefined>
    ) => {
      if (state.activeReport) state.activeReport.active_tab_id = action.payload;
      return state;
    },
    setIsReportOutOfDateForViewer: (
      state: ReportReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.isReportOutOfDateForViewer = action.payload;
      return state;
    },
    moveTabLeft: (state: ReportReduxType, action: PayloadAction<string>) => {
      const tabIdx = state.tabs.allIds.findIndex(
        (tabId) => tabId === action.payload
      );
      if (tabIdx === -1) {
        console.error("Tab not found when moving tab right");
        return state;
      }
      if (tabIdx > 0) {
        const neighbor = state.tabs.allIds[tabIdx - 1];
        if (neighbor) {
          const nextTab = state.tabs.allIds[tabIdx];
          if (nextTab) state.tabs.allIds[tabIdx - 1] = nextTab;
        }
        if (neighbor) state.tabs.allIds[tabIdx] = neighbor;
      }
      return state;
    },
    moveTabRight: (state: ReportReduxType, action: PayloadAction<string>) => {
      const tabIdx = state.tabs.allIds.findIndex(
        (tabId) => tabId === action.payload
      );
      if (tabIdx === -1) {
        console.error("Tab not found when moving tab right");
        return state;
      }
      if (tabIdx < state.tabs.allIds.length - 1) {
        const neighbor = state.tabs.allIds[tabIdx + 1];
        const prevTab = state.tabs.allIds[tabIdx];
        if (prevTab) state.tabs.allIds[tabIdx + 1] = prevTab;
        if (neighbor) state.tabs.allIds[tabIdx] = neighbor;
      }
      return state;
    },
    setTabName: (
      state: ReportReduxType,
      action: PayloadAction<{ tabId: string; name: string }>
    ) => {
      if (!(action.payload.tabId in state.tabs.byId)) {
        console.error("Tab not found when setting tab name");
        return state;
      }
      const tab = state.tabs.byId[action.payload.tabId];
      if (tab) {
        tab.name = action.payload.name;
      }
      return state;
    },
    deleteReportTab: (
      state: ReportReduxType,
      action: PayloadAction<{ tabId: string }>
    ) => {
      Object.keys(state.tabs.byId).forEach((tabId) => {
        if (tabId === action.payload.tabId) delete state.tabs.byId[tabId];
      });
      return state;
    },
    setReportProgressUpdate: (
      state: ReportReduxType,
      action: PayloadAction<{
        report_id: string;
        num_total_chunks?: number;
        num_completed_chunks?: number;
        queue_length?: number;
      }>
    ) => {
      const {
        report_id,
        num_total_chunks,
        num_completed_chunks,
        queue_length,
      } = action.payload;
      const { numTotalChunks, numCompletedChunks, queueLength } =
        state.reportLoadingProgress;

      if (state.activeReport?.id !== report_id) return;
      else if (
        num_total_chunks === undefined &&
        num_completed_chunks === undefined &&
        queue_length === undefined
      )
        return;

      state.reportLoadingProgress = {
        numTotalChunks: num_total_chunks ?? numTotalChunks,
        numCompletedChunks: num_completed_chunks ?? numCompletedChunks,
        queueLength: queue_length ?? queueLength,
      };

      return state;
    },
    setReportColumnHeader: (
      state: ReportReduxType,
      action: PayloadAction<{ name: string; tabId: string; columnId: string }>
    ) => {
      const { name, tabId, columnId } = action.payload;
      const tabTool = state.tools[tabId]?.[columnId];
      if (!tabTool) {
        console.error("Tab not found when setting column header");
        return state;
      }
      tabTool.name = name;
      return state;
    },
    upsertReportTool: (
      state: ReportReduxType,
      action: PayloadAction<{ tool: ReduxTool; tabId: string }>
    ) => {
      const { tool, tabId } = action.payload;
      if (!(tabId in state.tools)) state.tools[tabId] = {};
      const tabTools = state.tools[tabId];
      if (tabTools) {
        tabTools[tool.static_column_id] = {
          ...(tabTools[tool.static_column_id] ?? {}),
          ...tool,
        };
      }
      return state;
    },
    replaceReportToolVersionedColumnId: (
      state: ReportReduxType,
      action: PayloadAction<{
        staticColumnId?: ReduxTool["static_column_id"];
        versionedColumnId?: ReduxTool["versioned_column_id"];
        tabId: string;
      }>
    ) => {
      const { staticColumnId, versionedColumnId, tabId } = action.payload;
      if (!(tabId in state.tools)) state.tools[tabId] = {};
      const tabTools = state.tools[tabId];
      if (tabTools && staticColumnId) {
        tabTools[staticColumnId] = {
          ...(tabTools[staticColumnId] ?? {}),
          versioned_column_id: versionedColumnId,
        } as ReduxTool;
      }
      return state;
    },
    setMatrixHomePanel: (
      state: ReportReduxType,
      action: PayloadAction<MatrixHomePanelType>
    ) => {
      state.matrixHomePanel = action.payload;
      return state;
    },
    setInitialRowMount: (
      state: ReportReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.initialRowMount = action.payload;
      return state;
    },
    setLocalViewConfig: (
      state: ReportReduxType,
      action: PayloadAction<ViewConfig | null>
    ) => {
      state.localViewConfig = action.payload;
    },
    setLeaseId: (
      state: ReportReduxType,
      action: PayloadAction<string | null>
    ) => {
      state.leaseId = action.payload;
      return state;
    },
    setIsMarkdownRendered: (
      state: ReportReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.isMarkdownRendered = action.payload;
      return state;
    },
    setNextRowsToRun: (
      state: ReportReduxType,
      action: PayloadAction<number[]>
    ) => {
      state.nextRowsToRun = action.payload;
      return state;
    },
    openColumnEditor: (
      state: ReportReduxType,
      action: PayloadAction<ColumnEditorState>
    ) => {
      const editorState = action.payload;
      state.columnEditor = {
        open: true,
        ...editorState,
      };
      return state;
    },
    closeColumnEditor: (state: ReportReduxType) => {
      state.columnEditor = {
        ...DEFAULT_COLUMN_EDITOR_STATE,
        animate: state.columnEditor.animate,
      };
      return state;
    },
    openPromptGenerator: (
      state: ReportReduxType,
      action: PayloadAction<PromptGeneratorState>
    ) => {
      const promptGenratorState = action.payload;
      state.promptGenerator = {
        open: true,
        ...promptGenratorState,
      };
      return state;
    },
    closePromptGenerator: (state: ReportReduxType) => {
      state.promptGenerator = {
        open: false,
        referenceElement: null,
      };
      return state;
    },
    setSelectOptions: (
      state: ReportReduxType,
      action: PayloadAction<SelectOptionPayload>
    ) => {
      state.selectOptions[action.payload.promptKey ?? ""] =
        action.payload.value;
      return state;
    },
    setEntireSelectOptions: (
      state: ReportReduxType,
      action: PayloadAction<Record<string, string[] | null>>
    ) => {
      state.selectOptions = action.payload;
      return state;
    },
    setNumSelectedRows: (
      state: ReportReduxType,
      action: PayloadAction<number | null>
    ) => {
      state.numSelectedRows = action.payload;
      return state;
    },
    setAddMatrixUserStatus: (
      state: ReportReduxType,
      action: PayloadAction<AddMatrixUserPayload>
    ) => {
      state.addMatrixUserStatus = action.payload;
      return state;
    },
    clearAddMatrixUserStatus: (state: ReportReduxType) => {
      state.addMatrixUserStatus = null;
      return state;
    },
    setAddDocsFailedPermissionsUserMap: (
      state: ReportReduxType,
      action: PayloadAction<AddDocsFailedPermissionsUserMap>
    ) => {
      state.addDocsFailedPermissionsUserMap = action.payload;
      return state;
    },
    updateTemporarySelectAllCheckboxHack: (state: ReportReduxType) => {
      state.temporarySelectAllCheckboxHack =
        state.temporarySelectAllCheckboxHack + 1;
      return state;
    },
  },
});

export const {
  resetGlobalReportState,
  setReportStatus,
  updateReportVersion,
  setActiveReport,
  setActiveReportName,
  addReportResponse,
  setBulkRunCompletedVersion,
  setIsBulkRunning,
  setActiveTabId,
  setIsReportOutOfDateForViewer,
  upsertReportTool,
  addReportTab,
  duplicateReportTab,
  moveTabLeft,
  moveTabRight,
  setTabName,
  deleteReportTab,
  setReportProgressUpdate,
  setReportColumnHeader,
  setReportTools,
  setMatrixHomePanel,
  setInitialRowMount,
  setLocalViewConfig,
  setLeaseId,
  setIsMarkdownRendered,
  setNextRowsToRun,
  openColumnEditor,
  closeColumnEditor,
  openPromptGenerator,
  closePromptGenerator,
  replaceReportToolVersionedColumnId,
  setSelectOptions,
  setEntireSelectOptions,
  setNumSelectedRows,
  setAddMatrixUserStatus,
  clearAddMatrixUserStatus,
  setAddDocsFailedPermissionsUserMap,
  updateTemporarySelectAllCheckboxHack,
} = reportSlice.actions;
export const reportReducer = reportSlice.reducer;
