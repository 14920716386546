import LogRocket from "logrocket";
import { datadogLogs, StatusType } from "@datadog/browser-logs";

const _log = (
  message: string,
  context?: Record<string, unknown>,
  status?: StatusType,
  error?: unknown
) => {
  datadogLogs.logger.log(
    message,
    { ...context, lr_session: LogRocket.sessionURL },
    status,
    error as Error
  );

  if (status === "debug") LogRocket.debug(message, context ?? {});
  else if (status === "info") LogRocket.info(message, context ?? {});
  else if (status === "warn") LogRocket.warn(message, context ?? {});
  else if (status === "error") {
    LogRocket.error(message, context ?? {});
    if (error) LogRocket.captureException(error as Error);
  }
};

export default {
  log: (
    message: string,
    context?: Record<string, unknown>,
    status?: StatusType,
    error?: unknown
  ) => _log(message, context, status, error),
  debug: (
    message: string,
    context?: Record<string, unknown>,
    error?: unknown
  ) => {
    // Don't log debug messages in production because it's too noisy
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") return;
    return _log(message, context, "debug", error);
  },
  info: (message: string, context?: Record<string, unknown>, error?: unknown) =>
    _log(message, context, "info", error),
  warn: (message: string, context?: Record<string, unknown>, error?: unknown) =>
    _log(message, context, "warn", error),
  error: (
    message: string,
    context?: Record<string, unknown>,
    error?: unknown
  ) => {
    _log(message, context, "error", error);
    console.error(message, context);
  },
};
