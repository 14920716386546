import {
  FastBuildStatus,
  FastBuildDocData,
  DetectTablesAndChartsStatus,
} from "source/types/matrix/fastBuild.types";
import { ReduxState } from ".";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

export type FastBuildReduxType = {
  docStatusMap: { [docId: string]: FastBuildDocData };
  fileLimitReached: boolean;
};

const initialState: FastBuildReduxType = {
  docStatusMap: {},
  fileLimitReached: false,
};

export const getFastBuildDocStatusMap = (state: ReduxState) =>
  state.fastBuild.docStatusMap;
export const getFastBuildFileLimitReached = (state: ReduxState) =>
  state.fastBuild.fileLimitReached;

// selector that gets doc status by doc id
export const getFastBuildStatusByDocId = (docId?: string) =>
  createSelector([getFastBuildDocStatusMap], (docStatusMap) => {
    return docId ? docStatusMap[docId] : undefined;
  });

const fastBuildSlice = createSlice({
  name: "fastBuild",
  initialState,
  reducers: {
    clearFastBuildDocStatus: (state: FastBuildReduxType) => {
      state.docStatusMap = {};
      return state;
    },
    // set fast build doc status map, using a list of docs
    setFastBuildDocStatusData: (
      state: FastBuildReduxType,
      action: PayloadAction<FastBuildDocData[]>
    ) => {
      state.docStatusMap = action.payload.reduce(
        (currStatusMap, doc) => {
          currStatusMap[doc.doc_id] = doc;
          return currStatusMap;
        },
        {} as { [docId: string]: FastBuildDocData }
      );
      return state;
    },
    setFastBuildStatusByDocId: (
      state: FastBuildReduxType,
      action: PayloadAction<{ docId: string; status: FastBuildStatus }>
    ) => {
      const { docId, status } = action.payload;
      const doc = state.docStatusMap[docId];
      if (doc) {
        state.docStatusMap[docId] = {
          ...doc,
          status: status,
        };
      }
      return state;
    },
    setDetectTablesAndChartsStatusByDocId: (
      state: FastBuildReduxType,
      action: PayloadAction<{
        docId: string;
        status: DetectTablesAndChartsStatus;
      }>
    ) => {
      const { docId, status } = action.payload;
      const doc = state.docStatusMap[docId];
      if (doc) {
        state.docStatusMap[docId] = {
          ...doc,
          detect_tables_and_charts_status: status,
        };
      }
      return state;
    },
    setFastBuildFileLimitReached: (
      state: FastBuildReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.fileLimitReached = action.payload;
      return state;
    },
    resetAllFastBuildStates: (state: FastBuildReduxType) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  clearFastBuildDocStatus,
  setFastBuildDocStatusData,
  setFastBuildStatusByDocId,
  setDetectTablesAndChartsStatusByDocId,
  setFastBuildFileLimitReached,
  resetAllFastBuildStates,
} = fastBuildSlice.actions;
export const fastBuildReducer = fastBuildSlice.reducer;
