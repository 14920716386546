import { ColumnState, FilterModel } from "ag-grid-community";
import {
  BackendReportFilter,
  DocumentWithError,
  MatrixRoleType,
} from "source/Types";
import {
  FindResultType,
  GroupingContent,
  ReportCellType,
  SSRMRow,
} from "./cells.types";
import {
  ReportPrompt,
  ReportToolDependency,
  ReportToolParamType,
  ToolOutputType,
} from "./tools.types";
import { ModelType } from "source/constants/llms";

export const OUTPUT_FORMATS = [
  "freeform",
  "single_line",
  "list",
  "select",
  "multiselect",
  "currency",
  "emoji",
  "percent",
  "table",
  "date",
  "number",
];

// fancy syntax for: any string in the OUTPUT_FORMATS array
export type OutputFormat = (typeof OUTPUT_FORMATS)[number];

export const NUMERIC_OUTPUT_TYPES: OutputFormat[] = ["float", "datetime.date"];
export const SELECT_OUTPUT_FORMATS: OutputFormat[] = [
  "select",
  "multiselect",
  "emoji",
];

export type ToolSpecType = {
  output_format?: OutputFormat;
  output_options?: string[];
  output_unit?: string;
  list_output_length?: number;
};

/** This type is used for transactions and AG Grid, it maps each row's y coords to its row data  */
export type AGGridReportRowMapType = {
  [y: string]: SSRMRow;
};

export type ReportStatus =
  | "fetching"
  | "fetched"
  | "error"
  | "invalid_permissions"
  | "expired";

export type ReportSortOrder = "timestamp" | "relevance";

export type ReportSortType = {
  order: ReportSortOrder;
  query_for_relevance?: string;
  filter_by_relevance?: boolean;
};

export type RowState = {
  pinned: boolean;
  y: number;
  order: number | null;
  height: number | null;
};

export type ColumnViewConfiguration = {
  output_option_config?: {
    name?: string;
    color?: string;
  }[];
  numeric_config?: {
    precision?: number;
    format?: string;
  };
  date_config?: {
    format?: string;
  };
};

export type ViewConfig = {
  id: string;
  column_metadata?: SheetColumnMetadata[]; // DEPRECATED - moved back to ReportTab
  grid_configuration: (Omit<ColumnState, "aggFunc"> & {
    // Omit non-serializable type for aggFunc
    aggFunc: string | null;
  })[];
  row_configuration?: RowState[];
  cell_overflow_method?: CellOverflowMethod;
  filter_state?: FilterModel;
  column_view_configuration?: Record<string, ColumnViewConfiguration>;
};

// Contains all report data needed to render a report
// Matches backend SheetMeta object (stored in S3)
export type Report = {
  id: string;
  name?: string;
  version_id: string;
  created_at?: string;
  updated_at?: string;
  tabs: ReportTab[];
  active_tab_id?: string;
  is_giga?: boolean;
};

// Tab without column-specific data
export type ReduxTab = {
  tab_id: string;
  name?: string;
  grouping_content?: GroupingContent[];
  filters?: BackendReportFilter;
  run_errors?: boolean;
  sort_by?: ReportSortType;
  view_config_id?: string;

  // For backwards compatability with olds report:
  grid_configuration?: ColumnState[]; // DEPRECATED
  row_configuration?: RowState[]; // DEPRECATED
  cell_overflow_method?: CellOverflowMethod; // DEPRECATED
};

// Report Tab
export type ReportTab = {
  tab_id: string;
  tools?: ReportToolDependency[]; // defines tools to run
  name?: string;
  prompts: ReportPrompt[]; // queries for relevant columns
  column_metadata?: SheetColumnMetadata[]; // column header names
  cells: ReportCellType[];
  grouping_content?: GroupingContent[];
  filters?: BackendReportFilter;
  run_errors?: boolean;
  sort_by?: ReportSortType;
  view_config_id?: string;

  // Deprecated by ViewConfig, preserved for backwards compat w old reports:
  grid_configuration?: ColumnState[]; // DEPRECATED
  row_configuration?: RowState[]; // DEPRECATED
  cell_overflow_method?: CellOverflowMethod; // DEPRECATED
};

// Contains high-level report data (for home screen)
// Matches backend Sheet object (stored in Postgres)
export type ReportInfo = {
  created_at: string;
  updated_at: string;
  id: string;
  name: string;
  owner_name: string;
  is_owner: boolean;
  role: MatrixRoleType;
  sheet_org_id: string;
  is_empty: boolean;
  bookmarked: boolean;
};

export type FastBuildResponse = {
  response: DocumentWithError[];
};

export type IngestFilesResponse = {
  docs: DocumentWithError[];
};

export type SheetColumnMetadata = {
  x: number;
  static_column_id: string;
  name: string;
};

export type BulkRunStatusParams = {
  sheet_build_id?: string;
  version_id?: string;
};

export type BulkRunStatus =
  | "NOT_RUN"
  | "PENDING"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "FAILED";

export type BulkRunStatusResponse = {
  status: BulkRunStatus;
  time: number;
};

export type PromptListToolSpec = {
  outputFormat?: OutputFormat;
  outputOptions?: string;
  outputUnit?: string;
  outputScale?: string;
};

export type PromptListItem = {
  num: number;
  prompt: string;
  columnName?: string;
  model?: ModelType;
  outputType?: ToolOutputType;
  toolSpec?: PromptListToolSpec;
};

export type PromptList = {
  id: string;
  prompts: PromptListItem[];
  name: string;
  description?: string;
  icon?: string;
  toolSpec?: PromptListToolSpec;
  created_at: string;
  updated_at: string;
  document_types?: string[];
};

export type MatrixTemplateDisplay = {
  pinned?: boolean;
  template: PromptList;
};

export enum CellOverflowMethod {
  ONE_LINE = "oneLine",
  TWO_LINES = "twoLines",
  THREE_LINES = "threeLines",
  FOUR_LINES = "fourLines",
  WRAP_TEXT = "wrapText",
  CUSTOM = "custom",
}

export type DuplicateResponse = {
  id: string;
  version_id: string;
};

export type ReportVersionsResponse = {
  version_id: string;
  created_at: string;
  restore_version_id?: string;
  restore_version_timestamp?: string;
}[];

export type FeedbackPayload = {
  org_id: string;
  user_id: string;
  email: string;
  doc_id: string;
  result: FindResultType;
  cell_id: string;
  prompt: string;
  report_id: string;
  version_id: string;
  model: ModelType;
  fast_build: boolean;
  score?: number;
  reason?: string;
  created_at: string;
  tool_params: ReportToolParamType;
  feedback_text?: string;
  is_user_feedback: boolean;
};

export type MatrixCreateGigaResult = {
  matrix_id: string;
};

export type MatrixUser = {
  id: string;
  name: string;
  email: string;
  picture: string;
  role: MatrixRoleType;
};

export type AutoTitleReportParams = {
  doc_names: string[];
  col_names: string[];
};

export type MatrixListMetadata = {
  result: Record<string, { unread: boolean }>;
};

export type RowIdDocIdMap = {
  [rowId: string]: string;
};

// Maps column -> list of rows
export type ColumnIdCoordinateMap = {
  [columnId: string]: number[];
};

export type UserTemplateMetadata = Record<string, { pinned: boolean }>;
