import { ColumnIdToToolMapType } from "source/redux/matrix";
import {
  CellContent,
  ReportCellType,
  HydratedReportCellType,
  SSRMRow,
  HydratedDocumentCellType,
  DocumentResultType,
  HydratedAnswerCellType,
  ReportTableRow,
} from "../../components/matrix/types/cells.types";
import { v4 as uuidv4 } from "uuid";
import logger from "source/utils/logger";
import { UserType } from "source/Types";
import { ViewConfig } from "../../components/matrix/types/reports.types";
import { difference } from "lodash";
import { isAnswerToolType } from "./tools";
import { FastBuildStatus } from "../../types/matrix/fastBuild.types";

export const splitCellWithResult = (cell: HydratedReportCellType) => {
  const cellContent: CellContent = {
    id: cell.id,
    loading: cell.loading,
    error: cell.error,
    tool: cell.tool,
    result: cell.result,
    static_column_id: cell.static_column_id,
  };
  const reportCell: ReportCellType = {
    id: cell.id,
    x: cell.x,
    y: cell.y,
    tool: cell.tool,
    disabled: cell.disabled,
    static_column_id: cell.static_column_id,
  };

  return { reportCell, cellContent };
};

type GetDocCellFromReportTabelRowParams = {
  row?: SSRMRow;
  /** @deprecated Don't rely on the retrieveColumnId, check for retrieve tool instead */
  retrieveColumnId?: string;
};

/***
 * @deprecated You probably don't need this, you can get the document directly from the row data
 */
export const getDocCellFromReportTableRow = ({
  row,
  retrieveColumnId,
}: GetDocCellFromReportTabelRowParams): CellContent | undefined => {
  if (!row) return;

  const cell = Object.values(row.cells).find(({ tool }) => tool === "retrieve");

  if (cell) {
    return cell as HydratedDocumentCellType;
  }

  if (retrieveColumnId) {
    const cell = row.cells[retrieveColumnId];
    if (cell) {
      return cell as HydratedDocumentCellType;
    }
  }
};

export const getYFromReportTableRow = (
  params: GetDocCellFromReportTabelRowParams
) => {
  // @ts-ignore: TODO look into this
  return getDocCellFromReportTableRow(params)?.y;
};

export const getDocIdFromReportTableRow = (
  params: GetDocCellFromReportTabelRowParams
) => params.row?.document?.id;

export const updateRowCoordinate = (row: SSRMRow, newY: number) => {
  Object.keys(row).forEach((column) => {
    const cell = row[column];
    if (cell)
      row[column] = {
        ...cell,
        y: newY,
      };
  });
  return row;
};

type GeneratePaddingRowsProps = {
  numRows: number;
  readOnly?: boolean;
  docLimit?: number;
};

export const generatePaddingRows = ({
  numRows,
  readOnly,
  docLimit,
}: GeneratePaddingRowsProps): SSRMRow[] => {
  if (readOnly) return [];

  return Array(numRows)
    .fill(0)
    .map((_, idx) => {
      const fishMan = generateDefaultY();

      return {
        id: fishMan,
        cells: {
          ["padding-column-id"]: {
            tool: "padding",
            y: numRows + idx,
            id: fishMan,
            static_column_id: "padding-column-id",
          },
        },
      };
    });
};

export const generateDefaultY = () => `fish-man-${uuidv4()}`;

export const generateOptimisticLoadingCellId = () =>
  `temporary-loading-cell-${uuidv4()}`;

export const isPaddingRow = (data?: SSRMRow) =>
  Object.values(data?.cells ?? {}).some((cell) => cell.tool === "padding");

export const checkAndAlertForErrorCells = (
  reportId: string,
  tabId: string,
  cells: HydratedReportCellType[],
  user?: UserType
) => {
  const errorCells = cells.filter((cell) => !!cell.error);
  if (errorCells.length > 0) {
    errorCells.forEach((c) =>
      logger.error(
        `Error with cell: ${c.error}. X: ${c.static_column_id}, Y: ${c.y}. Report: ${reportId}`
      )
    );

    logger.error("Report generated with error cells", {
      user: user,
      tabId: tabId,
      reportId: reportId,
    });
  }
};

type SortRowsProps = {
  rows: SSRMRow[];
  viewConfig?: ViewConfig;
};

/** Sorts rows based on the view config. If the view config is not present, it will return the rows as is
 * @param rows - The server data for rows
 * @param viewConfig - The view configuration
 * @returns The rows sorted by the order in the view config
 */
export const sortRowsByViewConfig = ({ rows, viewConfig }: SortRowsProps) => {
  if (!viewConfig?.row_configuration) return [...rows];

  const staticColumnId = getDocCellFromReportTableRow({
    row: rows[0],
  })?.static_column_id;

  if (!staticColumnId) {
    logger.error(
      "Unable to sort rows based on view config. Can't find static column id"
    );
    return [...rows];
  }

  const orderMap = viewConfig.row_configuration.reduce<
    Record<number, { order: number }>
  >((acc, rowConfig) => {
    acc[rowConfig.y] = { order: rowConfig.order ?? 0 };
    return acc;
  }, {});

  // Note that rows without a y value will be sorted to the bottom
  // TODO: Re-implement this with ROW IDS!!!!
  return rows;
  // return [...rows].sort((a, b) => {
  //   const aY = a.cells[staticColumnId]?.y ?? 0;
  //   const bY = b.cells[staticColumnId]?.y ?? 0;
  //   const aOrder = orderMap[aY]?.order ?? Infinity;
  //   const bOrder = orderMap[bY]?.order ?? Infinity;
  //   return aOrder - bOrder;
  // });
};

/**
 * Gets the list of y-coordinates in the matrix
 * Calculates the missing cells in cellMap based on total y-coordinates - coordinates populated (loading, error, result)
 */
export const getNumUnrunCells = (
  rows: string[],
  cellMap?: {
    [x: string]: {
      [y: string]: ReportCellType;
    };
  },
  formattedCellContent?: ReportTableRow[],
  toolMap?: ColumnIdToToolMapType
) => {
  if (Object.values(cellMap ?? {}).length) {
    return Object.values(cellMap ?? {}).reduce((acc, column) => {
      const missingRows = difference(rows, Object.keys(column));
      return acc + missingRows.length;
    }, 0);
  } else if (formattedCellContent && toolMap) {
    const allColumns = Object.keys(toolMap);

    return formattedCellContent.reduce((acc, row) => {
      const missingColumns = difference(allColumns, Object.keys(row));
      return acc + missingColumns.length;
    }, 0);
  }
  return 0;
};

export const getExcludedCoordinates = (
  docStatusMap: { [docId: string]: FastBuildStatus },
  hydratedCells: HydratedReportCellType[]
) => {
  return new Set(
    hydratedCells
      .filter(
        (cell) =>
          cell.tool === "retrieve" &&
          docStatusMap[(cell.result as DocumentResultType)?.id] === "FAILED"
      )
      .map(({ y }) => y)
  );
};

// Type guard function for all answer tools
export const isAnswerCell = (
  cell: CellContent | HydratedReportCellType,
  includeSummary = true
): cell is HydratedAnswerCellType => {
  return isAnswerToolType(cell.tool, includeSummary);
};
