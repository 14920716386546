import _ from "lodash";
import { ResultType } from "source/Types";
import { isMobile } from "react-device-detect";
import Swal, { SweetAlertIcon } from "sweetalert2";
import tinycolor from "tinycolor2";
import {
  MATCH_THRESHOLD,
  STRONG_MATCH_THRESHOLD,
  SLACK_WEBHOOK_URL,
  MAX_LINES,
} from "../constants";
import { isProd } from "../envConstants";
import { TabType } from "source/components/tab-bar/tabs/Tabs";
import { STOPWORDS } from "source/constants/strings";

export const openPrettyAlert = ({
  title,
  text,
  icon,
  showConfirmButton = true,
  showCancelButton,
  showDenyButton,
  denyButtonText,
  confirmButtonText = icon === "error" ? "Notify Hebbia Support" : "Ok",
  cancelButtonText,
  allowOutsideClick = true,
  width,
  error,
  url,
  imageUrl,
  imageWidth,
  imageHeight,
  imageAlt,
  html,
}: {
  title?: string;
  text?: string;
  icon?: SweetAlertIcon;
  showConfirmButton?: boolean;
  showCancelButton?: boolean;
  showDenyButton?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  denyButtonText?: string;
  allowOutsideClick?: boolean;
  width?: number;
  error?: any;
  url?: string;
  imageUrl?: string;
  imageWidth?: number | string;
  imageHeight?: number | string;
  imageAlt?: string;
  html?: string;
}) => {
  // Send error notification if prod (will cause CORS issues on localhost)
  if (icon === "error" && error && isProd) {
    const today = new Date();
    let message = `Date: ${
      today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
    }  `;
    message =
      message +
      `Time: ${
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
      }\n\n`;
    if (typeof error === "string") message = message + error;
    else {
      const split_stack: string[] = error.stack.split("\n");
      const num_lines = Math.min(split_stack.length, MAX_LINES);
      for (let step = 0; step < num_lines; step++) {
        message = message + split_stack[step];
      }
    }
    if (url) {
      message = message + "\n\n-----------------------\n\nAt URL: " + url;
    }
    fetch(SLACK_WEBHOOK_URL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify({
        text: message,
      }),
    });
  }
  return Swal.fire({
    title,
    text,
    html,
    icon: icon === "error" ? undefined : icon,
    showConfirmButton,
    confirmButtonColor: "#0576FF",
    showCancelButton,
    confirmButtonText,
    cancelButtonText,
    showDenyButton,
    denyButtonText,
    allowOutsideClick,
    imageUrl,
    imageWidth,
    imageHeight,
    imageAlt,
    customClass: {
      popup: "swal",
    },
    heightAuto: true,
    backdrop: "rgba(0 0 0 / 40%)",
    ...(!isMobile && { width: width || 600 }),
  });
};

export const removeStopwords = (text: string): string =>
  text
    .toLowerCase()
    .split(" ")
    .map((word) =>
      word.replaceAll(".", "").replaceAll("?", "").replaceAll(",", "")
    )
    .filter((word_clean) => !STOPWORDS.includes(word_clean))
    .join(" ");

export const areEqual = <T>(obj1: Record<string, T>, obj2: Record<string, T>) =>
  _.isEqual(obj1, obj2);

export const openContextMenu = (
  e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  yOffset = 0
) => {
  e.stopPropagation();
  // Convert to "right click to prompt menu"
  const ev3 = new MouseEvent("contextmenu", {
    bubbles: true,
    cancelable: false,
    view: window,
    button: 2,
    buttons: 0,
    clientX: (e.target as any).getBoundingClientRect().x,
    clientY: (e.target as any).getBoundingClientRect().y + yOffset,
  });
  e.target.dispatchEvent(ev3);
};

// https://github.com/microsoft/monaco-editor/issues/2427
export const getComputedColor = (v: string) =>
  tinycolor(
    window.getComputedStyle(document.documentElement).getPropertyValue(v).trim()
  ).toHexString();

// TODO: Hacky will update after poc
// Returns the base query of a question formatted as `query Ex. example`
export const getBaseQuestion = (question: string) =>
  question.split("Ex.")[0]?.trim();

export const scrollTo = (
  selector,
  yOffset = 0,
  behavior: ScrollBehavior = "smooth"
) => {
  const el = document.getElementById(selector);
  if (el) {
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior });
  }
};

export const isMacintosh = () => navigator.platform.indexOf("Mac") > -1;

export const getRelevance = (relevance: number) => {
  if (relevance >= STRONG_MATCH_THRESHOLD) return "Very Similar";
  if (relevance >= MATCH_THRESHOLD) return "Similar";
  return "Potentially Similar";
};

// get count of very similar and similar results
export const getNumSimilarResults = (results: ResultType[]) => {
  const numVerySimilar = results.filter(
    (r: ResultType) => r.smoothed_absolute_relevance >= STRONG_MATCH_THRESHOLD
  ).length;
  const numSimilar = results.filter(
    (r: ResultType) => r.smoothed_absolute_relevance >= MATCH_THRESHOLD
  ).length;

  return { numVerySimilar, numSimilar };
};

/** Gets the current page for tabs */
export const getCurrentPage = (pathname: string): TabType => {
  if (pathname.includes("/chat-docs")) return "ChatDocs";
  else if (pathname.includes("/chat")) return "Chat";
  else if (pathname.includes("/matrix")) return "Matrix";
  else if (pathname.includes("/browse")) return "Browse";
  return "Search";
};
