import React, { useState } from "react";
import { SidebarToggle } from "../SidebarToggle";
import { useSelector } from "react-redux";
import { ClearTargetButton } from "../ClearTargetButton";
import { useDispatch } from "react-redux";
import { SearchBar } from "../SearchBar";
import { OrgSection } from "./components/OrgSection";
import { PersonalSection } from "./components/PersonalSection";
import { SharedSection } from "./components/SharedSection";
import {
  clearAllAppliedFiltersByKey,
  clearReportFilterValues,
  getReportFilterKey,
  getTargetAppliedFilters,
  getTargetDisabledFilters,
  getTargetDocs,
  getTargetRepos,
} from "source/redux/advanced";
import { FiltersConfig } from "source/constants/filters";
import { SearchedFiles } from "./components/SearchedFiles";
import { SelectedFilesDropdown } from "./components/SelectedFilesDropDown";
import { useFeatureFlag } from "source/hooks/common/useFeatureFlag";

export const DOCUMENT_SECTION_TITLE = "Files";
export const FAST_BUILD_DOCUMENT_SECTION_TITLE = "FastBuildFiles";

type Props = {
  reposCanOpen?: boolean;
  hideOrgSwitcher?: boolean;
};

export const FileSection = ({ reposCanOpen, hideOrgSwitcher }: Props) => (
  <SidebarToggle
    title={DOCUMENT_SECTION_TITLE}
    badge={<FolderSectionBadge />}
    last={true}
  >
    <SearchableSection
      reposCanOpen={reposCanOpen}
      hideOrgSwitcher={hideOrgSwitcher}
    />
  </SidebarToggle>
);

const FolderSectionBadge = () => {
  const dispatch = useDispatch();
  const sectionKey = getReportFilterKey(DOCUMENT_SECTION_TITLE);
  const appliedFilters = useSelector(getTargetAppliedFilters);
  const disabledFilters = useSelector(getTargetDisabledFilters);

  const targetRepos = useSelector(getTargetRepos);

  const appliedDocFilters = appliedFilters.filter(
    (a) => a.key === FiltersConfig.CUSTOM_FILTER_KEY_DOC
  );

  const numAppliedDocFilters = appliedDocFilters.filter(
    (a) => !disabledFilters.some((d) => d.value === a.value && d.key === a.key)
  ).length;

  const targetDocs = useSelector(getTargetDocs);
  const numDocTargets =
    targetDocs.length + targetRepos.length + numAppliedDocFilters;

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(clearReportFilterValues({ sectionKey }));
    dispatch(clearAllAppliedFiltersByKey(FiltersConfig.CUSTOM_FILTER_KEY_REPO));
    dispatch(clearAllAppliedFiltersByKey(FiltersConfig.CUSTOM_FILTER_KEY_DOC));
  };

  return (
    <SelectedFilesDropdown
      trigger={
        <ClearTargetButton
          numTargets={numDocTargets}
          handleClear={handleClear}
        />
      }
      repoIds={targetRepos}
      docIds={targetDocs}
    />
  );
};

type SearchableSectionProps = {
  reposCanOpen?: boolean;
  hideOrgSwitcher?: boolean;
};

const SearchableSection = ({
  reposCanOpen,
  hideOrgSwitcher,
}: SearchableSectionProps) => {
  const [searchValue, setSearchValue] = useState("");
  const disableAllSharedContent = useFeatureFlag("disableAllSharedContent");

  return (
    <div className="flex w-full flex-col pb-2">
      <SearchBar searchable="file" setSearchValue={setSearchValue} />
      {searchValue.length ? (
        <SearchedFiles searchValue={searchValue} reposCanOpen={reposCanOpen} />
      ) : (
        <>
          <OrgSection
            reposCanOpen={reposCanOpen}
            hideOrgSwitcher={hideOrgSwitcher}
          />
          <PersonalSection reposCanOpen={reposCanOpen} />
          {!disableAllSharedContent && (
            <SharedSection reposCanOpen={reposCanOpen} />
          )}
        </>
      )}
    </div>
  );
};
