import { OrgRoleType, OrgType, PendingUserType } from "source/Types";
import axios from "../axios";
import { UserType } from "source/Types";
import { MergeIntegrationType } from "source/components/add-files/shared/MergeIntegrationCard";
import {
  FastBuildSource,
  IntegrationStatus,
} from "source/types/matrix/fastBuild.types";

type OrgMembersResponse = {
  members: UserType[];
  pending: PendingUserType[];
};

type UserAuthContextBase = {
  user_id: string;
  org_id: string;
};

export type SharepointAuthContext = UserAuthContextBase;
export type BoxAuthContext = UserAuthContextBase;
export type DropboxAuthContext = UserAuthContextBase;

export type S3AuthContext = {
  role_id: string;
  assume_role_arn: string;
  org_id: string;
  bucket_name?: string;
};

export type ThirdBridgeAuthContext = Record<string, never>;

export type AzureFileShareAuthContext = Record<string, never>;

export type GetS3AuthContextsParams = {
  bucket_name?: string; // if undefined, return all buckets
};

export type CreateS3AuthContextParams = {
  role_id: string;
  assume_role_arn: string;
};

export type AddS3BucketToAWSRoleParams = {
  role_id: string;
  bucket_name: string;
};

export type DeleteS3AuthContextParams = {
  role_id: string;
};

export const Orgs = {
  create: (
    name: string,
    enablePublicSources: boolean,
    emailDomains?: string[]
  ) =>
    axios.post("/v2/orgs", {
      name: name,
      email_domains: emailDomains,
      config: { enable_public_sources: enablePublicSources },
    }),
  get: () => axios.get("/v2/orgs"),
  updateOrg: (orgId: string, body: Partial<OrgType>) =>
    axios.put(`/v2/orgs/${orgId}`, body),
  getOrgMembers: (orgId: string): Promise<OrgMembersResponse> =>
    axios.get(`/v2/orgs/${orgId}/members`, {}).then(({ data }) => data),
  addOrgMember: (orgId: string, userEmail: string, role: OrgRoleType) =>
    axios.post(`/v2/orgs/${orgId}/members`, {
      user_email: userEmail,
      role: role,
    }),
  updateOrgMember: (orgId: string, userId: string, role: OrgRoleType) =>
    axios.put(`/v2/orgs/${orgId}/members`, {
      user_id: userId,
      role: role,
    }),
  removeOrgMember: (orgId: string, userId: string) =>
    axios.post(`/v2/orgs/${orgId}/members/remove`, {
      user_id: userId,
    }),
  removePendingOrgMember: (orgId: string, inviteeEmail: string) =>
    axios.post(`/v2/orgs/${orgId}/members/remove-pending`, {
      invitee_email: inviteeEmail,
    }),
  getMergeLinkToken: (
    orgId: string,
    integrationType: MergeIntegrationType
  ): Promise<string> =>
    axios
      .get(
        `/v2/orgs/${orgId}/integrations/${integrationType}/create-link-token`
      )
      .then(({ data }) => data.token),
  swapMergeLinkToken: (
    orgId: string,
    publicToken: string,
    integrationType: MergeIntegrationType
  ): Promise<void> =>
    axios.post(
      `/v2/orgs/${orgId}/integrations/${integrationType}/swap-link-tokens`,
      {
        public_token: publicToken,
      }
    ),
  getS3AuthContexts: (
    orgId: string,
    params: GetS3AuthContextsParams
  ): Promise<S3AuthContext[]> =>
    axios
      .get(`/v2/orgs/${orgId}/integrations/s3/s3-auth-contexts`, { params })
      .then(({ data }) => data),
  createS3AuthContext: (
    orgId: string,
    params: CreateS3AuthContextParams
  ): Promise<S3AuthContext> =>
    axios
      .post(`/v2/orgs/${orgId}/integrations/s3/aws-role`, { ...params })
      .then(({ data }) => data),
  addS3BucketToAWSRole: (orgId: string, params: AddS3BucketToAWSRoleParams) =>
    axios
      .post(`/v2/orgs/${orgId}/integrations/s3/add-bucket-access-to-aws-role`, {
        ...params,
      })
      .then(({ data }) => data),
  deleteS3AuthContext: (orgId: string, params: DeleteS3AuthContextParams) =>
    axios
      .delete(`/v2/orgs/${orgId}/integrations/s3/aws-role`, { data: params })
      .then(({ data }) => data),

  getOrgShareSettings: (orgId: string) =>
    axios.get(`/v2/orgs/${orgId}/share-settings`).then(({ data }) => data),

  updateOrgShareSettings: (orgId: string, isMatrixSharingEnabled: boolean) =>
    axios.put(`/v2/orgs/${orgId}/share-settings`, {
      is_matrix_sharing_enabled: isMatrixSharingEnabled,
      is_v2_sharing: true,
    }), // is_v2_sharing is not used, so just set to true for now.
  updateIntegrationStatus: (
    orgId: string,
    integrationType: FastBuildSource,
    status: IntegrationStatus,
    bucketName?: string
  ): Promise<OrgType> =>
    axios
      .put(`/v2/orgs/${orgId}/integrations/${integrationType}/status`, {
        status,
        bucket_name: bucketName,
      })
      .then(({ data }) => data?.org),
};
