import React, {
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  Placement,
  FloatingPortal,
  FloatingFocusManager,
  useTransitionStyles,
  UseDismissProps,
  OpenChangeReason,
  FloatingOverlay,
} from "@floating-ui/react";
import { classNames } from "core";

export const DEFAULT_POPOVER_ANIMATION_TIME = 150;

export const resolveReferenceElement = (referenceElement: string | null) => {
  if (typeof referenceElement === "string") {
    return document.getElementById(referenceElement);
  }

  return referenceElement;
};

interface PopoverOptions {
  open: boolean;
  modal?: boolean;
  animate?: boolean;
  overlay?: boolean;
  placement?: Placement;
  shouldAutoUpdate?: boolean;
  referenceElement?: Element | null;
  dismissOptions?: UseDismissProps;
  onOpenChange: (open: boolean, e?: Event, reason?: OpenChangeReason) => void;
}

export const usePopover = ({
  open,
  modal,
  animate = true,
  shouldAutoUpdate = true,
  placement = "bottom",
  referenceElement,
  dismissOptions,
  onOpenChange,
}: PopoverOptions) => {
  const [labelId, setLabelId] = useState<string | undefined>();
  const [descriptionId, setDescriptionId] = useState<string | undefined>();

  const data = useFloating({
    placement,
    open,
    elements: referenceElement
      ? {
          reference: referenceElement,
        }
      : undefined,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "end",
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
    onOpenChange,
    whileElementsMounted: shouldAutoUpdate ? autoUpdate : undefined,
  });

  const context = data.context;

  const dismiss = useDismiss(context, dismissOptions);
  const role = useRole(context);
  const { isMounted, styles } = useTransitionStyles(context, {
    duration: animate ? DEFAULT_POPOVER_ANIMATION_TIME : 0,
  });
  const interactions = useInteractions([dismiss, role]);

  return useMemo(
    () => ({
      open,
      isMounted,
      onOpenChange,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      transitionStyles: styles,
      setLabelId,
      setDescriptionId,
    }),
    [
      open,
      modal,
      isMounted,
      interactions,
      data,
      styles,
      labelId,
      descriptionId,
      onOpenChange,
    ]
  );
};

type ContextType =
  | (ReturnType<typeof usePopover> & {
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
      setDescriptionId: React.Dispatch<
        React.SetStateAction<string | undefined>
      >;
    })
  | null;

const PopoverContext = createContext<ContextType>(null);

const usePopoverContext = () => {
  const context = useContext(PopoverContext);

  if (context == null) {
    throw new Error("Popover components must be wrapped in <Popover />");
  }

  return context;
};

export const Popover = ({
  children,
  open,
  modal = false,
  overlay = false,
  ...restOptions
}: {
  children: React.ReactNode;
} & PopoverOptions) => {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ open, modal, overlay, ...restOptions });
  return (
    <PopoverContext.Provider value={popover}>
      {children}
      {open && overlay && <FloatingOverlay />}
    </PopoverContext.Provider>
  );
};

interface PopoverTriggerProps {
  children: React.ReactNode;
  asChild?: boolean;
}

export const PopoverTrigger = forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & PopoverTriggerProps
>(({ children, asChild = false, ...props }, propRef) => {
  const context = usePopoverContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      })
    );
  }

  return (
    <button
      ref={ref}
      type="button"
      // The user can style the trigger based on the state
      data-state={context.open ? "open" : "closed"}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  );
});

interface PopoverContentProps extends React.HTMLProps<HTMLDivElement> {
  style?: React.CSSProperties;
  className?: string;
}

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  ({ style, className, ...props }, propRef) => {
    const {
      context: floatingContext,
      transitionStyles,
      ...context
    } = usePopoverContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.isMounted) return null;

    return (
      <FloatingPortal>
        <FloatingFocusManager context={floatingContext} modal={context.modal}>
          <div
            ref={ref}
            style={{
              ...context.floatingStyles,
              ...transitionStyles,
              ...style,
            }}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            className={classNames(
              "rounded-md border border-gray-200 bg-white shadow-xl",
              className
            )}
            {...context.getFloatingProps(props)}
          >
            {props.children}
          </div>
        </FloatingFocusManager>
      </FloatingPortal>
    );
  }
);
