import { FilterType } from "source/Types";

// docs query key factory
export const docsKeys = {
  all: [{ scope: "docs" }] as const,
  doc: (
    docId?: string,
    matrixId?: string,
    orgId?: string,
    sharingDocPermissionChecksEnabled?: boolean
  ) =>
    [
      {
        ...docsKeys.all[0],
        docId,
        matrixId,
        orgId,
        sharingDocPermissionChecksEnabled,
        feature: "doc",
      },
    ] as const,
  batchDocs: (matrixId?: string) =>
    [
      {
        ...docsKeys.all[0],
        matrixId,
        level: "batch",
        feature: "/sheets/docs/batch/simple",
      },
    ] as const,
  parentDocs: (repoId: string | undefined) =>
    [{ ...docsKeys.all[0], level: "parent", repoId }] as const,
  childrenDocs: (docId: string | undefined, repoId: string | undefined) =>
    [{ ...docsKeys.all[0], docId, level: "children", repoId }] as const,
  docCounts: (
    docIds: string[],
    filters: FilterType[],
    repoId?: string,
    orgId?: string
  ) =>
    [
      { ...docsKeys.all[0], feature: "counts", docIds, filters, repoId, orgId },
    ] as const,
  searchSuggested: (docIds: string[]) =>
    [{ ...docsKeys.all[0], feature: "suggested", docIds }] as const,
  // Only used for reports
  previewDocs: (
    repoId: string | undefined,
    reportId: string | undefined,
    docTargets: string[]
  ) =>
    [
      {
        ...docsKeys.all[0],
        feature: "reports",
        repoId,
        reportId,
        docTargets,
        level: "preview",
      },
    ] as const,
  unsupportedDocs: (repoId: string | undefined) =>
    [{ ...docsKeys.all[0], feature: "unsupported", repoId }] as const,
  searchDocs: (repoIds: string[], searchValue?: string) =>
    [
      { ...docsKeys.all[0], feature: "docSearch", searchValue, repoIds },
    ] as const,
  repoChildrenCount: (repoId: string) =>
    [{ ...docsKeys.all[0], feature: "repoChildrenCount", repoId }] as const,
  docChildrenCount: (docId: string) =>
    [{ ...docsKeys.all[0], feature: "docChildrenCount", docId }] as const,
  docViewerAsPDF: (docId: string) =>
    [{ ...docsKeys.all[0], feature: "doc", level: "pdf", docId }] as const,
  getTablesStatus: (
    docId: string | undefined | null,
    jobId: string | undefined | null
  ) => [docsKeys.all[0].scope, "tablesStatus", docId, jobId],
};
