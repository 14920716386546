import React, { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "source/components/shared/Popover";
import { useDispatch, useSelector } from "react-redux";
import { getOrgs, setCurrentOrg } from "source/redux/organization";
import { OrgType } from "source/Types";
import { getShowAllActivities, setSearchBarCopy } from "source/redux/ui";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useActivityFeed } from "source/hooks/useActivityFeed";
import { useSearchStateManager } from "source/hooks/useSetRouter";
import { SearchBar } from "../../SearchBar";
import { useSetActiveDocumentList } from "source/hooks/document-list/useSetActiveDocumentList";
import { resetAll } from "source/redux/documentList";
import { resetAllDocumentLists } from "source/redux/documentLists";
import { useOrgSwitch } from "source/hooks/useOrgSwitch";

/**
 * Currently only used in chatdocs
 */
export const GigabarOrgSwitcher = () => {
  const orgs = useSelector(getOrgs);
  if (orgs.length <= 1) return null;

  return (
    <div className="">
      <Popover>
        <PopoverTrigger>
          <span className="cursor-pointer text-secondary hover:bg-hoverRow active:bg-hoverRowActive">
            <UnfoldMoreIcon fontSize="small" />
          </span>
        </PopoverTrigger>
        <PopoverContent alignOffset={9} align="start">
          <div className="flex w-64 items-center justify-between p-3 font-medium text-secondary">
            ORGANIZATIONS
          </div>
          <SearchableOrgs />
        </PopoverContent>
      </Popover>
    </div>
  );
};

const SearchableOrgs = () => {
  const [searchValue, setSearchValue] = useState("");
  return (
    <div className="flex w-full flex-col pb-2">
      <SearchBar searchable="org" setSearchValue={setSearchValue} />
      <OrgList searchValue={searchValue} />
    </div>
  );
};

type OrgListProps = {
  searchValue: string;
};

const OrgList = ({ searchValue }: OrgListProps) => {
  const { onOrgSwitch } = useOrgSwitch();
  const orgs = useSelector(getOrgs);

  return (
    <div className="max-h-[220px] overflow-y-scroll">
      {searchValue.length ? (
        <SearchedOrgs
          searchValue={searchValue}
          orgs={orgs}
          handleSwitch={onOrgSwitch}
        />
      ) : (
        <>
          {orgs.map((org, i) => (
            <OrgRow org={org} handleSwitch={onOrgSwitch} key={i} />
          ))}
        </>
      )}
    </div>
  );
};

type SearchedOrgsProps = {
  searchValue: string;
  orgs: OrgType[];
  handleSwitch: (org: OrgType) => void;
};

const SearchedOrgs = ({
  searchValue,
  orgs,
  handleSwitch,
}: SearchedOrgsProps) => {
  const matchingOrgs = substringMatchOrgs(searchValue, orgs);
  if (!matchingOrgs.length)
    return (
      <div className="my-0 flex h-8 select-none flex-row items-center overflow-hidden text-ellipsis whitespace-nowrap px-3 py-0.5 text-secondary">
        No matching orgs
      </div>
    );
  return (
    <>
      {matchingOrgs.map((org, i) => (
        <OrgRow org={org} handleSwitch={handleSwitch} key={i} />
      ))}
    </>
  );
};

type OrgRowProps = {
  org: OrgType;
  handleSwitch: (org: OrgType) => void;
};

const OrgRow = ({ org, handleSwitch }: OrgRowProps) => (
  <div
    className="my-0 flex h-8 cursor-pointer flex-row items-center overflow-hidden text-ellipsis whitespace-nowrap px-3 py-0.5 text-secondary hover:bg-hoverRow active:bg-hoverRowActive"
    key={org.id}
    onClick={() => handleSwitch(org)}
  >
    {org.name}
  </div>
);

export const substringMatchOrgs = (
  query: string,
  orgs: OrgType[]
): OrgType[] => {
  const regex = new RegExp(query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i");
  return orgs.filter((org) => regex.test(org.name));
};
