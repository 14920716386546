import posthog from "posthog-js";
import { useGetRouter } from "../useGetRouter";
import { useUser } from "@auth0/nextjs-auth0/client";
import { useSelector } from "react-redux";
import { getCurrentOrg } from "source/redux/organization";

export enum PosthogObject {
  SEARCH = "Search",
  CHAT = "Chat",
  CHAT_DOCS = "ChatDocs",
  MATRIX = "Matrix",
  MATRIX_CHAT = "Matrix Chat",
  SINGLE_DOC_CHAT = "Single Doc Chat",
  TABS = "Tabs",
  BUILD = "Build",
  ALERTS = "Alerts",
  FEEDBACK = "Feedback",
}

export enum PosthogAction {
  CREATED = "created",
  DUPLICATED = "duplicated",
  DUPLICATE_DOCS = "duplicate docs",
  DELETED = "deleted",
  ERROR = "error",
  FILTERS_UPDATED = "filters updated",
  LOCAL_FILES_UPLOADED = "local files uploaded",
  OPENED_EXISTING = "opened existing",
  // search-specific actions
  QUERY_RECEIEVED = "query received", // All searches log query receieved,
  SEARCH_FROM_URL = "search from url", // User loads url with query in the param
  MANUAL_SEARCH = "manual search", // User hits enter on search input box to manually search
  FILTERED_SEARCH = "filtered search", // Search with filters
  GLOBAL_SEARCH = "global search", // Search with no filters
  SEARCH_COMPLETE = "search complete", // Search completed
  INVALID_MATTER_ID = "invalid matter id", // Search with invalid MatterId (M&M)
  SEARCH_FEEDBACK = "search feedback", // User clicks thumbs up/down on search results
  // chat interface actions
  SESSION_CREATED = "session created",
  MESSAGE_SENT = "message sent",
  CLEAR_CHAT = "clear chat",
  ABORT_MESSAGE = "abort message",
  COPY_MESSAGE = "copy message",
  CLICK_MESSAGE_SUGGESTION = "click message suggestion",
  // matrix-unique actions
  CREATED_FROM_TEMPLATE = "created from template", // will double log as Matrix.CREATED
  CELLS_RUN = "cells run",
  FAST_BUILD_TRIGGERED = "fast build triggered",
  BATCH_FAST_BUILD_TRIGGERED = "batch fast build triggered",
  SHARED_AS_TEMPLATE = "share as template",
  SHARED_TO_USER = "shared to user",
  SHARE_LINK_COPIED = "share link copied",
  ADD_DOCS = "add docs",
  ADD_COLUMNS = "add columns",
  DOWNLOAD = "download",
  ROW_SELECT_ALL_NONE = "selecting all or no rows in the table",
  CELL_GENERATED = "cell generated",
  // passive matrix actions
  VIEW_CITATIONS = "view citations",
  // matrix - export rows
  EXPORT_TABLE_ROW = "export table row",
  BULK_EXPORT_TABLE_ROW = "export multiple table rows",
  EXPORT_MATRIX_CHAT = "export matrix chat message",
  EXPORT_MATRIX_CHAT_CONVERSATION = "export matrix chat conversation",
  CREATE_MATRIX_FROM_FILE_MENU = "create matrix from file menu",
}

export type LogPosthogCallback = (
  productName: PosthogObject,
  action: PosthogAction,
  eventData?: any
) => void;

export const useLogPosthog = (): { logPosthog: LogPosthogCallback } => {
  const { router } = useGetRouter();
  const { user } = useUser();
  const org = useSelector(getCurrentOrg);

  const logPosthog = (
    productName: PosthogObject,
    action: PosthogAction,
    eventData?: any
  ) => {
    const data = {
      user: user,
      org: org,
      router: router,
      ...eventData,
    };
    posthog?.capture(`${productName} - ${action}`, data);
  };
  return { logPosthog };
};
