import api from "..";
import { QueryContextFromKeys } from "../utils";
import { orgsKeys } from "./orgsKeyFactory";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { OrgType } from "source/Types";

// Typed orgs key factory context
type OrgsQueryContext = QueryContextFromKeys<typeof orgsKeys>;

export type OrgsData = {
  orgs: OrgType[];
};

const orgMembersFetcher = async ({
  queryKey: [{ orgId }],
}: OrgsQueryContext["members"]) =>
  // check id at runtime because it can be `undefined`
  typeof orgId === "undefined"
    ? Promise.resolve(undefined)
    : api.orgs.getOrgMembers(orgId);

export const useQueryOrgs = (
  opts?: Omit<UseQueryOptions<any, unknown, OrgsData, any[]>, "initialData">
) => {
  return useQuery({
    ...opts,
    queryKey: [{ method: "GET", route: "/v2/orgs" }],
    queryFn: async () => {
      const { data } = await api.orgs.get();
      return data;
    },
  });
};

export const useQueryOrgMembers = (orgId?: string) =>
  useQuery({
    queryKey: orgsKeys.members(orgId),
    queryFn: orgMembersFetcher,
    enabled: !!orgId,
    retry: 2,
  });
