import dynamic from "next/dynamic";
import { ModalTypes } from "./modals.types";

export const modalConfig: Record<ModalTypes, any> = {
  [ModalTypes.SettingsModal]: {
    import: () => import("source/components/settings"),
    render: dynamic(() =>
      import("source/components/settings").then((modal) => modal.SettingsModal)
    ),
  },
  [ModalTypes.ShareModal]: {
    import: () => import("source/components/shared/Modals/ShareModal"),
    render: dynamic(() =>
      import("source/components/shared/Modals/ShareModal").then(
        (modal) => modal.ShareModal
      )
    ),
  },
  [ModalTypes.ShareAsTemplateModal]: {
    import: () =>
      import(
        "source/components/shared/Modals/ShareAsTemplateModal/ShareAsTemplateModal"
      ),
    render: dynamic(() =>
      import(
        "source/components/shared/Modals/ShareAsTemplateModal/ShareAsTemplateModal"
      ).then((modal) => modal.ShareAsTemplateModal)
    ),
  },
  [ModalTypes.ShareMatrixModal]: {
    import: () => import("source/components/shared/Modals/ShareMatrixModal"),
    render: dynamic(() =>
      import("source/components/shared/Modals/ShareMatrixModal").then(
        (modal) => modal.ShareMatrixModal
      )
    ),
  },
  [ModalTypes.RefreshReportScreenModal]: {
    import: () =>
      import("source/components/shared/Modals/RefreshReportScreenModal"),
    render: dynamic(() =>
      import("source/components/shared/Modals/RefreshReportScreenModal").then(
        (modal) => modal.RefreshReportScreenModal
      )
    ),
  },
  [ModalTypes.AddFolderModal]: {
    import: () =>
      import(
        "source/components/gigabar/components/FileSection/components/AddFolder/AddFolderModal"
      ),
    render: dynamic(() =>
      import(
        "source/components/gigabar/components/FileSection/components/AddFolder/AddFolderModal"
      ).then((modal) => modal.AddFolderModal)
    ),
  },
  [ModalTypes.WelcomeMatrixChatModal]: {
    import: () =>
      import("source/components/matrix/home/WelcomeMatrixChatModal"),
    render: dynamic(() =>
      import("source/components/matrix/home/WelcomeMatrixChatModal").then(
        (modal) => modal.WelcomeMatrixChatModal
      )
    ),
  },
  [ModalTypes.MatrixDocModal]: {
    import: () =>
      import("source/components/matrix/modals/MatrixDocModal/MatrixDocModal"),
    render: dynamic(() =>
      import(
        "source/components/matrix/modals/MatrixDocModal/MatrixDocModal"
      ).then((modal) => modal.MatrixDocModal)
    ),
  },
  [ModalTypes.AddDocumentsModal]: {
    import: () =>
      import(
        "source/components/matrix/menu/AddDocumentsModal/AddDocumentsModalRouter"
      ),
    render: dynamic(() =>
      import(
        "source/components/matrix/menu/AddDocumentsModal/AddDocumentsModalRouter"
      ).then((modal) => modal.AddDocumentsModalRouter)
    ),
  },
  [ModalTypes.AddDocsUserPermissionsModal]: {
    import: () =>
      import(
        "source/components/shared/Modals/AddDocsUserPermissionsModal/AddDocsUserPermissionsModal"
      ),
    render: dynamic(() =>
      import(
        "source/components/shared/Modals/AddDocsUserPermissionsModal/AddDocsUserPermissionsModal"
      ).then((modal) => modal.AddDocsUserPermissionsModal)
    ),
  },
  [ModalTypes.PasswordInputModal]: {
    import: () => import("source/components/matrix/build/PasswordInputModal"),
    render: dynamic(() =>
      import("source/components/matrix/build/PasswordInputModal").then(
        (modal) => modal.PasswordInputModal
      )
    ),
  },
  [ModalTypes.DeleteRowModal]: {
    import: () => import("source/components/matrix/modals/DeleteRowModal"),
    render: dynamic(() =>
      import("source/components/matrix/modals/DeleteRowModal").then(
        (modal) => modal.DeleteRowModal
      )
    ),
  },
  [ModalTypes.ForceClaimLeaseModal]: {
    import: () =>
      import("source/components/matrix/modals/ForceClaimLeaseModal"),
    render: dynamic(() =>
      import("source/components/matrix/modals/ForceClaimLeaseModal").then(
        (modal) => modal.ForceClaimLeaseModal
      )
    ),
  },
  [ModalTypes.SplitDocumentModal]: {
    import: () =>
      import(
        "source/components/matrix/modals/SplitDocumentModal/SplitDocumentModal"
      ),
    render: dynamic(() =>
      import(
        "source/components/matrix/modals/SplitDocumentModal/SplitDocumentModal"
      ).then((modal) => modal.SplitDocumentModal)
    ),
  },
  [ModalTypes.ImportCSVModal]: {
    import: () =>
      import(
        "source/components/matrix/modals/ImportTabularDataModals/ImportCSVModal/ImportCSVModal"
      ),
    render: dynamic(() =>
      import(
        "source/components/matrix/modals/ImportTabularDataModals/ImportCSVModal/ImportCSVModal"
      ).then((modal) => modal.UploadCSVModal)
    ),
  },
  [ModalTypes.ImportExcelModal]: {
    import: () =>
      import(
        "source/components/matrix/modals/ImportTabularDataModals/ImportExcelModal/ImportExcelModal"
      ),
    render: dynamic(() =>
      import(
        "source/components/matrix/modals/ImportTabularDataModals/ImportExcelModal/ImportExcelModal"
      ).then((modal) => modal.ImportExcelModal)
    ),
  },
  [ModalTypes.DeleteMatrixModal]: {
    import: () =>
      import("source/components/matrix/home/sidebar/modals/DeleteMatrixModal"),
    render: dynamic(() =>
      import(
        "source/components/matrix/home/sidebar/modals/DeleteMatrixModal"
      ).then((modal) => modal.DeleteMatrixModal)
    ),
  },
  [ModalTypes.S3AuthorizationModal]: {
    import: () =>
      import(
        "source/components/settings/components/Integrations/S3/S3AuthorizationModal"
      ),
    render: dynamic(() =>
      import(
        "source/components/settings/components/Integrations/S3/S3AuthorizationModal"
      ).then((modal) => modal.S3AuthorizationModal)
    ),
  },
  [ModalTypes.LoadMatrixFromTemplateModal]: {
    import: () =>
      import("source/components/matrix/modals/LoadMatrixFromTemplateModal"),
    render: dynamic(() =>
      import(
        "source/components/matrix/modals/LoadMatrixFromTemplateModal"
      ).then((modal) => modal.LoadMatrixFromTemplateModal)
    ),
  },
  [ModalTypes.IntegrationsEnablementModal]: {
    import: () =>
      import(
        "source/components/settings/components/Integrations/IntegrationsEnablementModal"
      ),
    render: dynamic(() =>
      import(
        "source/components/settings/components/Integrations/IntegrationsEnablementModal"
      ).then((modal) => modal.IntegrationsEnablementModal)
    ),
  },
};
