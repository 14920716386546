import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
} from "react";
import { classNames } from "core";
import { ButtonSize, ButtonVariant } from "./Button";

export interface IconButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: ReactNode;
  size?: ButtonSize;
  variant?: ButtonVariant;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const {
      children,
      className,
      size = "md",
      variant = "primary",
      ...rest
    } = props;

    return (
      <button
        {...rest}
        ref={ref}
        className={classNames(
          // base styling
          "group flex w-fit items-center justify-center truncate rounded font-medium leading-3 transition",

          // color icons children
          "[&_svg]:fill-current [&_svg]:text-inherit",

          // handle the size prop
          size === "lg" &&
            "h-9 min-h-9 w-9 min-w-7 gap-2 text-sm [&>.material-symbols-sharp]:max-w-5 [&>.material-symbols-sharp]:text-xl [&_svg]:h-5 [&_svg]:w-5",
          size === "md" &&
            "h-7 min-h-7 w-7 min-w-7 gap-1 text-xs [&_svg]:h-4 [&_svg]:w-4",
          size === "sm" &&
            "h-6 min-h-6 w-6 min-w-6 gap-1 text-xs [&_svg]:h-4 [&_svg]:w-4",
          size === "xs" &&
            "h-5 min-h-5 w-5 min-w-5 gap-1 text-xs [&_svg]:h-4 [&_svg]:w-4",

          // handle hover - here we're mixing 6% opacity black with the current bg color
          "hover:bg-gradient-to-r hover:from-black/[0.06] hover:to-black/[0.06] hover:bg-blend-multiply",

          // handle active state (when mouse is pressed over the button)
          "active:bg-gradient-to-r active:from-black/[0.1] active:to-black/[0.1] active:bg-blend-multiply",

          // handle disabled state
          "disabled:cursor-default disabled:from-black/0 disabled:to-black/0 [&:disabled>svg]:text-neutral-300",

          // handle focused state
          "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-300",

          // handle variants
          variant === "primary" &&
            "bg-brand-500 text-white active:from-black/[0.20] active:to-black/[0.20] disabled:bg-neutral-300 [&:disabled>svg]:text-white",
          variant === "outlined" &&
            "border border-neutral-200 bg-white text-neutral-600 shadow-sm disabled:border-neutral-200 disabled:bg-white disabled:text-neutral-300 disabled:shadow-none [&_svg]:text-neutral-500",
          variant === "text" &&
            "text-neutral-600 disabled:bg-transparent disabled:text-neutral-300 [&_svg]:text-neutral-500",

          className
        )}
      >
        {children}
      </button>
    );
  }
);

export default IconButton;
