import { LDContext } from "launchdarkly-react-client-sdk";
import {
  FeatureFlagName,
  FeatureFlags,
} from "source/hooks/common/useFeatureFlag";
import { OrgType, UserType } from "source/Types";

export const getFeatureFlag = <K extends FeatureFlagName>(
  flagKey: K,
  defaultValue?: FeatureFlags[K]
): FeatureFlags[K] | undefined => {
  if (typeof window === "undefined" || !window.ldClient) {
    return defaultValue;
  }
  return window.ldClient?.variation(flagKey, defaultValue);
};

export const getLDContext = (
  user: UserType | undefined,
  currentOrg: OrgType | null
): LDContext => {
  // Multi-context with user/org/emailDomain
  if (user && currentOrg?.id) {
    const userEmailDomain =
      user.email.split("@")[1]?.trim().toLowerCase() ?? "";

    return {
      kind: "multi",
      user: {
        key: user.id,
        email: user.email,
        firstName: user.name,
      },
      organization: {
        key: currentOrg.id,
        name: currentOrg.name,
      },
      emailDomain: {
        key: userEmailDomain,
      },
    };
  }

  // Default user context
  if (user) {
    return {
      kind: "user",
      key: user.id,
      email: user.email,
      firstName: user.name,
    };
  }

  return { anonymous: true, kind: "user" };
};

/**
 * @summary Wipes all local storage entries related to LaunchDarkly
 */
export const clearLDLocalStorage = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith("ld:")) {
      localStorage.removeItem(key);
    }
  });
};
