import React, {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { ReactNode } from "react";
import { classNames } from "core";
import { useComponentId } from "../../hooks/useComponentId";
import AutosizeInput from "react-input-autosize";

export interface TextFieldProps
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "prefix"
  > {
  label?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  styles?: {
    Wrapper?: string;
    Input?: string;
  };
  autosize?: boolean;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props: TextFieldProps, ref) => {
    const {
      label,
      className,
      value,
      defaultValue,
      id,
      prefix,
      suffix,
      autoFocus,
      styles,
      autosize,
      ...rest
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    // Forward the ref to parent
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    // For some reason React's native autoFocus functionality doesn't work, so we manually handle it here.
    useEffect(() => {
      if (autoFocus) {
        inputRef?.current?.focus();
      }
    }, []);

    const textFieldId = useComponentId(id, "TextField");

    const inputClassNames = classNames(
      "w-full px-3 py-2 text-sm font-medium placeholder-gray-400 focus:outline-none",
      styles?.Input
    );

    const inputProps = {
      ...rest,
      id: textFieldId,
      ref: inputRef,
      value: value,
      defaultValue: defaultValue,
      className: inputClassNames,
      inputClassName: inputClassNames, // AutosizeInput
      autoFocus: autoFocus,
    };

    return (
      <div className={classNames("flex flex-col gap-1", className)}>
        {label && (
          <label htmlFor={textFieldId} className={"text-sm font-medium"}>
            {label}
          </label>
        )}
        <div
          className={classNames(
            "flex items-center overflow-hidden rounded border border-gray-200 bg-white",
            styles?.Wrapper
          )}
        >
          {prefix && (
            <div className={"h-fit w-fit pl-3 [&>span]:block"}>{prefix}</div>
          )}
          {autosize ? (
            <AutosizeInput {...inputProps} />
          ) : (
            <input {...inputProps} />
          )}
          {suffix && (
            <div className={"h-fit w-fit pr-3 [&>span]:block"}>{suffix}</div>
          )}
        </div>
      </div>
    );
  }
);

export default TextField;
