import {
  EXTENSION_TO_MIME_TYPE,
  FAILED_DOCUMENT_MAPPING,
  FAST_BUILD_ALLOWED_MIMES_SET_OLD,
  THIRD_BRIDGE_COMPANY_MAP,
  THIRD_BRIDGE_PDF_BUCKET_NAME,
} from "source/constants/fastBuild";
import {
  DocumentFailureReasonType,
  EarningsTranscript,
  IDocumentMime,
  InvestorPresentation,
  SecSearchResult,
  SimpleDocumentType,
} from "source/Types";
import {
  SharepointNodeIdentifierType,
  FastBuildNode,
  SharepointFastBuildNode,
  SelectedResource,
  IntegrationStatus,
  ThirdBridgeParentIdentifier,
  ThirdBridgeDisplayData,
  FastBuildIngestParams,
  FastBuildIngestResponse,
  DocumentIdFastBuildNodeMap,
  FastBuildSource,
  ThirdBridgeFastBuildNode,
  S3FastBuildNode,
  AzureFastBuildNode,
} from "source/types/matrix/fastBuild.types";
import { pluralize } from "../common/strings";
import { isValidSharepointSite } from "./addDocs";
import {
  FastBuildSearchFilterMap,
  SortDirection,
} from "source/components/matrix/menu/AddDocumentsModal/shared/types";
import {
  getFastBuildAllowedMimesSet,
  MAX_FAST_BUILD_FILE_SIZE,
  // Start of Selection
} from "../documents";
import { DocumentType } from "../../Types";
import logger from "source/utils/logger";

const PUBLICS_IDENTIFIERS = [
  "earnings_query_params",
  "filings_query_params",
] as const;

export const getFailureReasonTooltip = (
  failureReason?: DocumentFailureReasonType
) =>
  failureReason && FAILED_DOCUMENT_MAPPING[failureReason]
    ? (FAILED_DOCUMENT_MAPPING[failureReason]?.tooltip ?? "Retry")
    : "Retry";

export const getFailureReasonIcon = (
  failureReason?: DocumentFailureReasonType
) =>
  failureReason && FAILED_DOCUMENT_MAPPING[failureReason]?.icon
    ? (FAILED_DOCUMENT_MAPPING[failureReason]?.icon ?? "replay")
    : "replay";

export const getFailureReasonLabel = (
  failureReason?: DocumentFailureReasonType
) =>
  failureReason && FAILED_DOCUMENT_MAPPING[failureReason]
    ? (FAILED_DOCUMENT_MAPPING[failureReason]?.label ?? "Retry")
    : "Retry";

// Conversion of files to FileSystemEntry objects and then back to File objects which
// is done in getAllFileEntries (afaik necessary for local directory traversal) can result in
// unrecognized mimetype for application/vnd.ms-outlook (.msg) files.
//
// For .msg files we check extension and create a new File object with the correct File.type
//
// "Uncommon file extensions would return an empty string" - https://developer.mozilla.org/en-US/docs/Web/API/Blob/type
export const transformIngestFiles = (files: File[]) => {
  return files.reduce<File[]>((acc, file) => {
    const fileType = getFileType(file);

    if (file.type === "" && fileType) {
      const newFile = new File([file], file.name, {
        type: fileType,
      });
      acc.push(newFile);
    } else {
      acc.push(file);
    }

    return acc;
  }, []);
};

// TODO: This function is evil. Need a better solve for this
export const getRemoteIdForDoc = (doc: SimpleDocumentType) => {
  // Sharepoint
  if (doc?.data?.remote_id) {
    return `msft-doc-${doc.data.remote_id}`;
  }

  // Azure share
  if (doc?.data?.azure_file_share_id) {
    return `azure-doc-${doc.data.azure_file_share_id}`;
  }

  // Investor Prezzes
  if (doc?.data?.physical_documents_fields?.key) {
    return `investor-presentation-${doc?.data?.physical_documents_fields?.key}`;
  }

  // Earnings transcripts
  if (doc?.data?.sp_event_id) {
    return `earnings-doc-${doc.data.sp_event_id}`;
  }

  // Earnings data from fastbuildv2
  if (doc?.data?.earnings_query_params?.id) {
    return `earnings-doc-${doc.data.earnings_query_params.id}`;
  }

  // SEC filings from fastbuildv2
  if (doc?.data?.filings_query_params?.accession_number) {
    const exhibitSuffix = doc.data.filings_query_params.exhibit_type
      ? `-${doc.data.filings_query_params.exhibit_type}`
      : "";
    return `sec-doc-${doc.data.filings_query_params.accession_number}${exhibitSuffix}`;
  }

  // SEC filings from old fastbuild
  if (doc?.data?.accession_number) {
    const exhibitSuffix = doc.data.exhibit_type
      ? `-${doc.data.exhibit_type}`
      : "";
    return `sec-doc-${doc.data.accession_number}${exhibitSuffix}`;
  }

  if (doc?.data?.id) {
    return doc.data.id;
  }

  if (doc?.data?.path) {
    return doc.data.path;
  }

  return undefined;
};

export const getRemoteIdForIntegrationDoc = (
  integration: FastBuildSource,
  node: FastBuildNode<unknown, unknown>
): string => {
  switch (integration) {
    case FastBuildSource.THIRD_BRIDGE:
      return (node as ThirdBridgeFastBuildNode).node_identifier.id;
    case FastBuildSource.S3:
      return (node as S3FastBuildNode).node_identifier.id;
    case FastBuildSource.AZURE_FILE_SHARE:
      return (node as AzureFastBuildNode).node_identifier.id;
  }

  return "";
};

export const getRemoteIdForPublicDocument = (
  doc: EarningsTranscript | SecSearchResult | InvestorPresentation
) => {
  if ("accession_number" in doc) {
    return getRemoteIdForSecFiling(doc);
  }
  if ("key" in doc) {
    return getRemoteIdForInvestorPresentation(doc);
  }

  return getRemoteIdForEarningsCall(doc);
};

export const getRemoteIdForEarningsCall = (doc: EarningsTranscript) => {
  return `earnings-doc-${doc.id}`;
};

export const getRemoteIdForInvestorPresentation = (
  doc: InvestorPresentation
) => {
  return `investor-presentation-${doc.key}`;
};

export const getRemoteIdForSecFiling = (doc: SecSearchResult) => {
  const { accession_number, exhibit_type } = doc;
  return `sec-doc-${accession_number}${exhibit_type ? `-${exhibit_type}` : ""}`;
};

type SharepointNodeIdentifierKeys = "site_id" | "drive_id" | "remote_id";

export const SHAREPOINT_RESOURCE_TYPE_TO_KEY_ID_MAP: Record<
  SharepointNodeIdentifierType,
  SharepointNodeIdentifierKeys
> = {
  doc: "remote_id",
  folder: "remote_id",
  drive: "drive_id",
  site: "site_id",
};

export const getSharepointNodeIdentifierType = (
  node?: SharepointFastBuildNode
): SharepointNodeIdentifierType | null => {
  if (!node) return null;

  const { node_identifier } = node;

  if (node_identifier["remote_id"] && node_identifier["drive_id"]) {
    if (node.has_children) return "folder";
    return "doc";
  } else if (node_identifier["site_id"]) {
    return "site";
  } else if (node_identifier["drive_id"]) {
    return "drive";
  } else {
    return null;
  }
};

export const getSharepointNodeIdentifierId = (
  node: SharepointFastBuildNode
): string => {
  const resourceType = getSharepointNodeIdentifierType(node);

  const { node_identifier } = node;

  const key_id = resourceType
    ? SHAREPOINT_RESOURCE_TYPE_TO_KEY_ID_MAP[resourceType]
    : undefined;

  if (!key_id) return "";
  return node_identifier[key_id] ?? "";
};

export const getRemoteIdForSharepointResource = (
  node: SharepointFastBuildNode
) => {
  const resourceType = getSharepointNodeIdentifierType(node);

  if (!resourceType) return "";

  return `msft-${resourceType}-${getSharepointNodeIdentifierId(node)}`;
};

export const getFilePathForSelectedResource = <T>(
  item: SelectedResource<T>
): string[] => {
  return [
    ...(item?.file_path?.flatMap((file_path) =>
      Object.values(file_path ?? {})
    ) ?? []),
    ...(item.title ? [item.title] : []),
  ];
};

export const sortSharePointResources = (
  resources: SharepointFastBuildNode[]
) => {
  return resources.sort((a, b) => {
    if (a.has_children && !b.has_children) return -1;
    if (!a.has_children && b.has_children) return 1;
    return 0;
  });
};

export const parseDocSize = (size: number | undefined) => {
  if (!size) {
    return "";
  }
  if (size < 1024) {
    return size + " B";
  }
  if (size < 1024 * 1024) {
    return (size / 1024).toFixed(1) + " KB";
  }
  return (size / (1024 * 1024)).toFixed(1) + " MB";
};

export const parseChildCount = (count: number | undefined) => {
  if (count === undefined) {
    return "";
  } else if (count === 0) {
    return "Empty";
  }
  return count + ` ${pluralize(count, "item")}`;
};

export const parseURL = (url: string) => {
  return url
    .split(/\s|,/g) // split on either comma or empty space
    .map((s) => s.trim())
    .filter(Boolean); // filter out empty strings
};

export const isFastBuildMimeSupported = (
  mime: string,
  oldFastBuild?: boolean
) => {
  const docMime = mime as IDocumentMime | undefined;
  if (oldFastBuild) {
    return docMime && FAST_BUILD_ALLOWED_MIMES_SET_OLD.has(docMime);
  }
  return docMime && getFastBuildAllowedMimesSet().has(docMime);
};

export const isFastBuildSupported = (
  doc: FastBuildNode<unknown, unknown>,
  oldFastBuild?: boolean
) => {
  // Supported if the document is a public SNP document
  if (
    doc.node_identifier &&
    typeof doc.node_identifier === "object" &&
    PUBLICS_IDENTIFIERS.some((key) => key in (doc.node_identifier as any))
  ) {
    return true;
  }
  const docMime = doc.mime as IDocumentMime;
  return (
    (!docMime || isFastBuildMimeSupported(docMime, oldFastBuild)) && // search results often lack mime, so only check if mime is defined
    !!doc?.size &&
    doc.size < MAX_FAST_BUILD_FILE_SIZE
  );
};

export const getAllowedSharepointSites = (
  siteAllowList: string[] | null,
  docs: SharepointFastBuildNode[] | undefined
) => {
  return siteAllowList === null
    ? docs
    : docs?.filter(({ node_identifier }) =>
        isValidSharepointSite(siteAllowList, node_identifier["site_id"] ?? "")
      );
};

export const fastBuildSort =
  (activeSort: SortDirection | null | undefined) =>
  (a: FastBuildNode<unknown, unknown>, b: FastBuildNode<unknown, unknown>) => {
    if (a.last_modified === undefined || b.last_modified === undefined)
      return 1;
    if (a.has_children && !b.has_children) return -1;
    if (b.has_children && !a.has_children) return 1;
    if (
      a.mime &&
      isFastBuildMimeSupported(a.mime) &&
      b.mime &&
      !isFastBuildMimeSupported(b.mime)
    )
      return -1;
    if (
      b.mime &&
      isFastBuildMimeSupported(b.mime) &&
      a.mime &&
      !isFastBuildMimeSupported(a.mime)
    )
      return 1;
    return activeSort === "asc" ||
      activeSort === null ||
      activeSort === undefined
      ? new Date(b.last_modified).valueOf() -
          new Date(a.last_modified).valueOf()
      : new Date(a.last_modified).valueOf() -
          new Date(b.last_modified).valueOf();
  };

/**
 * Takes SNP publics fastBuildNode, formats the date, and sets mime to PDF (which is standard for SNP docs)
 */
export const hydrateSNPNode = (node: FastBuildNode<unknown, unknown>) => {
  let formattedDate = node.last_modified;
  if (formattedDate) {
    const date = new Date(formattedDate);
    formattedDate = date.toISOString().split(".")[0] + "Z";
  }
  const mime = node.mime ?? "application/pdf";
  return {
    ...node,
    last_modified: formattedDate,
    mime: mime,
  };
};

export const isS3Active = (integrationStatuses: Record<string, any>) => {
  return (
    integrationStatuses.s3 &&
    Object.values(integrationStatuses.s3).some(
      (val) => val === IntegrationStatus.ENABLED_ACTIVE
    )
  );
};

export const isThirdBridgeDoc = (doc: Partial<DocumentType>): boolean => {
  return doc.data?.display_path?.includes(
    "proda-forum-data-delivery-hebbia-pdf"
  );
};

// use base 64 encoding to get file ID from path
export const getS3FileIDFromPath = (bucketName: string, path: string) => {
  try {
    return Buffer.from(`${bucketName}/${path}`).toString("base64");
  } catch (e) {
    logger.error("Failed to base-64 encode S3 path", {
      bucket_name: bucketName,
      path: path,
      err: e,
    });
    return undefined;
  }
};

export const getKeyForSharepointDoc = ({
  node_identifier,
}: SharepointFastBuildNode) => {
  return (
    (node_identifier["site_id"] ?? "") + (node_identifier["remote_id"] ?? "")
  );
};

export const sortAndFilterThirdBridgeDocs = (
  docs: ThirdBridgeFastBuildNode[] | undefined,
  activeSort: SortDirection,
  fastBuildSearchFilters: FastBuildSearchFilterMap
) => {
  if (!docs) return [];

  const timestampFilter = fastBuildSearchFilters.timestamp?.value;
  const now = new Date();
  let filterDate;

  if (timestampFilter === "month") {
    filterDate = new Date(now.setMonth(now.getMonth() - 1));
  } else if (timestampFilter === "3 months") {
    filterDate = new Date(now.setMonth(now.getMonth() - 3));
  } else if (timestampFilter === "year") {
    filterDate = new Date(now.setFullYear(now.getFullYear() - 1));
  }

  const filteredDocs = docs.filter((doc) => {
    if (timestampFilter && filterDate) {
      return new Date(doc.last_modified).getTime() >= filterDate.getTime();
    }
    return true;
  });

  return filteredDocs.sort((a, b) => {
    const aTime = new Date(a.last_modified).getTime();
    const bTime = new Date(b.last_modified).getTime();
    return activeSort === "asc" ? bTime - aTime : aTime - bTime;
  });
};

export const getFileType = (file: File) => {
  let fileType = file.type;

  const extension = file.name.split(".").pop()?.toLowerCase();

  if (!fileType && extension) {
    fileType = EXTENSION_TO_MIME_TYPE[extension] ?? "";
  }

  return fileType;
};
