import { FilterModel, SortModelItem } from "ag-grid-community";
import { DocumentType, IDocumentMime } from "source/Types";
import {
  FastBuildIngestDocRequest,
  FastBuildSource,
} from "../matrix/fastBuild.types";

export type DocumentRow = (FolderItem | DocumentSearchResult) & {
  filePath: string[];
} & {
  metadata?: DocumentMetadata;
};

export type DocumentListIndexingStatus = "PENDING" | "INDEXED" | "FAILED";

export type DocumentListUser = {
  id: string;
  email: string;
  name: string;
  picture: string;
};

export type DocumentListUserPermissions = {
  user_id: string;
  role: "owner" | "editor" | "viewer";
};

export type DocumentList = {
  id: string;
  name: string;
  color: string | null;
  created_at: string;
  updated_at: string;
  owner: DocumentListUser;
  user_permissions: DocumentListUserPermissions;
  indexing_status: DocumentListIndexingStatus;
  is_quick_upload: boolean;
  is_empty: boolean;
  document_count?: number;
  documents_updated_at?: string;
};

export type DocumentListResponseV0 = {
  document_list_id: string;
  sources: DocumentListSource[];
  name: string;
  description: string | null;
  icon: string | null;
  color: string | null;
};

export type DocumentListV0 = Omit<
  DocumentListResponseV0,
  "document_list_id"
> & {
  id: string;
};

export type DocumentListSource = {
  source_id: string;
  source_name: string;
  integration_type: string; // todo: connect to fastbuild integration enum
  enabled: string;
  schedule: Record<string, any>; // todo: better define the schedule
};

export type DocumentListDisplayDataType = {
  id: string;
  name: string;
  icon: string | null;
  color: string | null;
  indexing_status?: DocumentListIndexingStatus;
};

export type DocumentListDocument = Pick<
  DocumentType,
  "id" | "timestamp" | "mime" | "title"
> & {
  document_list_id: string;
};

export type DocumentsListStatusResponse = {
  completed_count: number;
  total_count: number;
  is_empty: boolean;
};

export enum FolderItemType {
  FOLDER = "folder",
  DOCUMENT = "document",
}

export type FolderItem = {
  id: string;
  name: string;
  parent_id: string | null;
  type: FolderItemType;
  path: string;
  timestamp?: string;
  document_id?: string;
  mime?: IDocumentMime;
  crawl_id?: string;
  is_root_crawl_node?: boolean;
};

export type GetChildrenResponse = {
  items: FolderItem[];
  total_count: number;
  has_more: boolean;
  total_document_count: number;
};

export type CreateDocumentListRequest = {
  name: string;
  color: string;
};

export type GetDocumentsRequest = {
  sorts: SortModelItem[];
  filters: FilterModel | null;
  offset: number;
  limit: number;
};

export type MoveFolderRequest = {
  target_folder_id: string | null;
  items: FolderItem[];
};

export type UploadFolderRequest = {
  documentListId: string;
  files: File[];
  rootFolderId?: string;
  rootFolderPath?: string[];
  ignoreRoot?: boolean;
};

export type UploadIntegrationDocRequest<AuthContext, Identifier> = {
  document_list_id: string;
  root_folder_id?: string;
  request_id: string;
  requests: FastBuildIngestDocRequest<Identifier>[];
  integration: FastBuildSource;
  auth_context_info: AuthContext;
};

export type UploadIntegrationDocResponse = {
  document_id: string;
};

export type UploadURLsResponse = {
  document_ids: string[];
  row_ids: string[];
};

export type AddDocumentsResponse = {
  document_ids: string[];
};

export type UploadFolderResponse = {
  document_ids: string[];
  paths: string[][];
};

export type UpdateFolderParams = {
  id: string;
  name: string;
};

export type CreateFolderRequest = {
  name: string;
  parent_id?: string;
  is_quick_upload?: boolean;
  items?: FolderItem[];
};

export type SyncExternalFolderRequest<AuthContext, Identifier> = {
  integration: FastBuildSource;
  integration_auth_context: AuthContext;
  node_identifiers: Identifier[];
  parent_id?: string;
};

export type SyncExternalFolderResponse = {
  crawls: { crawl_id: string; folder_id: string }[];
};

export type ResyncExternalFolderRequest = {
  crawl_ids: string[];
};

export type CrawlIdRunId = {
  crawl_id: string;
  run_id: string;
};

export type ResyncExternalFolderResponse = {
  crawls: CrawlIdRunId[];
};

export type DocumentStatusCount = {
  completed: number;
  total: number;
};

export type MoveItemToDocumentList = {
  items: string[];
  target_document_list_id: string;
};

export type UploadURLsRequest = {
  urls: {
    url: string;
  }[];
};

export type GetDocumentsSearchHybridRequest = {
  query: string;
  page_size: number;
  offset: number;
};

export type GetDocumentsSearchRequest = {
  query: string;
  sorts: SortModelItem[];
  metadata_filters?: DocumentListFilterMap;
  folder_id?: string;
};

export type DocumentListSearchType = "keyword" | "semantic";

export type DocumentListSearchResponse = {
  hits: DocumentSearchResult[];
  total_document_count: number;
  source: DocumentListSearchType | null;
};

export type DocumentSearchResult = FolderItem & {
  score: number;
  keyword_count: Record<string, number>;
  total_keyword_count: number;
  excerpts: string[];
};

export type DocumentSearchHybridResponse = {
  hits: DocumentSearchResult[];
  has_more: boolean;
  total_document_count: number;
  total_count: number;
};

export type BulkDeleteResponse = {
  deleted_document_ids?: string[];
  success: boolean;
};

export type DocumentListDateRange = {
  start: string;
  end?: string;
};
export type DocumentListFilterMap = {
  [key: string]: string | string[] | DocumentListDateRange;
};

export type DocumentListMetadataFilter = {
  id: string;
  display_name: string;
  type: "list" | "keyword" | "date";
  display_type: "multiselect" | "select" | "date";
};

export type DocumentListFilterOption = {
  value: string;
  name: string;
  count?: number;
};

// Maps filter key to the options available
export type DocumentListFilterOptions = {
  [key: string]: DocumentListFilterOption[];
};
export type DocumentListFilterOptionsResponse = {
  options: DocumentListFilterOptions;
};

export type DocumentListFiltersResponse = {
  filters: DocumentListMetadataFilter[];
};

export type DocumentListDateFilterType =
  | "month"
  | "3 months"
  | "6 months"
  | "year"
  | "ytd"
  | "custom";

export type DocumentMetadataStatus = "GENERATING" | "FAILED";

export type DocumentMetadataValue = {
  value: string | null;
  status: DocumentMetadataStatus | null;
};

export type DocumentMetadata = {
  [metadataId: string]: DocumentMetadataValue;
};

export type DocumentMetadataMap = {
  [rowId: string]: DocumentMetadata;
};

export type DocumentListDocumentMetadataResponse = {
  data: DocumentMetadataMap;
};
