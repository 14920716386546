import React from "react";
import { useSelector } from "react-redux";
import { RepoType } from "source/Types";
import { useQueryBookmarks } from "source/api/bookmarks/useQueryBookmarks";
import {
  useCreateRepoMutation,
  useUpdateRepoMutation,
} from "source/api/repos/useQueryRepos";
import { DEFAULT_REPO_NAME } from "source/constants";
import { useSidebarRepos } from "source/hooks/useSidebarRepos";
import { getCurrentOrg } from "source/redux/organization";
import { SubSectionHeader } from "./SubSections";
import { AllRepos } from "./AllRepos";
import { GigabarOrgSwitcher } from "./GigabarOrgSwitcher";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { getOrgRepos } from "../utils";
import { useFeatureFlag } from "source/hooks/common/useFeatureFlag";
import { getUser } from "source/redux/user";

/** Gets orgs repos and filters out bookmarked repos */
const getOrgReposWithNoBookmarks = (
  repos: RepoType[],
  bookmarkedRepos: RepoType[],
  currentOrgId?: string
) => {
  const orgRepos = getOrgRepos(repos, currentOrgId);
  return orgRepos.filter(
    (repo) => !bookmarkedRepos.find((r) => r.id === repo.id)
  );
};

type OrgSectionProps = {
  reposCanOpen?: boolean;
  hideOrgSwitcher?: boolean;
};

export const OrgSection = ({
  reposCanOpen,
  hideOrgSwitcher,
}: OrgSectionProps) => {
  const currentOrg = useSelector(getCurrentOrg);
  const currentUser = useSelector(getUser);
  const { goToRepo } = useGlobalNavigator();
  const disableAllSharedContent = useFeatureFlag("disableAllSharedContent");
  const disableMemberRepoCreation = useFeatureFlag("disableMemberRepoCreation");

  const sidebarRepos = useSidebarRepos();
  const bookmarkedRepos = useQueryBookmarks().data?.repos ?? [];

  // If sharing is blocked in the org, hide all repos
  const bookmarkedOrgRepos = !disableAllSharedContent
    ? getOrgRepos(bookmarkedRepos, currentOrg?.id)
    : [];
  const orgRepos = !disableAllSharedContent
    ? getOrgReposWithNoBookmarks(sidebarRepos, bookmarkedRepos, currentOrg?.id)
    : [];

  const createRepoMutation = useCreateRepoMutation();
  const updateRepoMutation = useUpdateRepoMutation();

  const isOrgAdmin =
    currentOrg?.members?.some(
      (member) => member.org_role === "admin" && member.id === currentUser?.id
    ) || currentUser?.platform_role === "admin";

  if (!currentOrg) return null;

  const handleRepoClick = (repoId: string) => {
    goToRepo(repoId);
  };

  const handleRename = (newTitle: string, id: string) => {
    const payload = {
      repoId: id,
      body: {
        name: newTitle,
      },
    };
    updateRepoMutation.mutate(payload);
  };

  return (
    <div className="flex flex-col">
      <AllRepos
        bookmarkedRepos={bookmarkedOrgRepos}
        repos={orgRepos}
        handleRepoClick={reposCanOpen ? handleRepoClick : undefined}
        handleRename={handleRename}
        header={
          <div className="flex items-center justify-start">
            <SubSectionHeader title={currentOrg.name} />
            {!hideOrgSwitcher && (
              <div onClick={(e?: React.MouseEvent) => e?.stopPropagation()}>
                <GigabarOrgSwitcher />
              </div>
            )}
          </div>
        }
        onAddFolder={
          !disableAllSharedContent && (isOrgAdmin || !disableMemberRepoCreation)
            ? (name?: string) => {
                const payload = {
                  name: name ?? DEFAULT_REPO_NAME,
                  is_private: true,
                  organization_id: currentOrg.id,
                };
                createRepoMutation.mutate(payload);
              }
            : undefined
        }
      />
    </div>
  );
};
