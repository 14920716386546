import { ColumnIdToToolMapType } from "source/redux/matrix";
import { DEFAULT_TOOL_PARAM_OUTPUT_TYPE } from "source/constants";
import { RETRIEVE_COL_MAGIC_VALUE } from "../../components/matrix/tables/config";
import {
  AnswerToolType,
  ReduxTool,
  ReportToolDependency,
  ReportToolParamType,
  ReportToolType,
} from "../../components/matrix/types/tools.types";
import {
  ReportTableRow,
  SSRMRow,
} from "../../components/matrix/types/cells.types";
import { v4 as uuidv4 } from "uuid";
import { ModelType } from "source/constants/llms";

export const generateRetrieveTool = (tool: Partial<ReduxTool>): ReduxTool => ({
  tool: "retrieve",
  request_id: "",
  x: 0,
  dependencies: [-1],
  static_column_id: uuidv4(),
  dependency_column_ids: [],
  ...tool,
});

export const getNextX = (tools?: ColumnIdToToolMapType): ReduxTool["x"] => {
  const lastX = Object.values(tools ?? []).reduce((acc: number, { x }) => {
    if (x !== undefined && x > acc) {
      return x;
    }
    return acc;
  }, 0);
  return lastX + 1;
};

export const getRetrieveColumnFromToolMap = (
  toolMap?: ColumnIdToToolMapType
): ReduxTool | undefined => {
  const tools = Object.values(toolMap ?? {});
  return tools.find((tool) => tool.tool === "retrieve");
};

export const getRetrieveColumnIdFromToolMap = (
  toolMap?: ColumnIdToToolMapType
): ReduxTool["static_column_id"] | undefined => {
  return getRetrieveColumnFromToolMap(toolMap)?.static_column_id;
};

export const generateFindTool = ({
  tool,
  defaultToolParams,
}: {
  tool: Partial<ReduxTool>;
  defaultToolParams?: ReportToolParamType;
}): ReduxTool => {
  return {
    ...tool,
    tool: tool.tool ?? "find",
    request_id: tool.request_id || uuidv4(),
    x: tool.x,
    static_column_id: tool.static_column_id || uuidv4(),
    versioned_column_id: tool.versioned_column_id || uuidv4(),
    tool_params: {
      ...defaultToolParams,
      model: tool.tool_params?.model ?? defaultToolParams?.model,
      output_type:
        tool.tool_params?.output_type ??
        defaultToolParams?.output_type ??
        DEFAULT_TOOL_PARAM_OUTPUT_TYPE,
      tool_spec: tool.tool_params?.tool_spec ?? defaultToolParams?.tool_spec,
      experiment_config:
        tool.tool_params?.experiment_config ??
        defaultToolParams?.experiment_config,
    },
    dependencies: [0],
    dependency_column_ids: tool.dependency_column_ids?.length
      ? tool.dependency_column_ids
      : isAnswerToolType(tool.tool)
        ? [RETRIEVE_COL_MAGIC_VALUE]
        : [],
    drop_cells: false,
  };
};

export const generateStaticColumnIdDataMapping = <
  T extends { static_column_id?: string },
  K,
>(
  items: T[],
  key?: string
): { [static_column_id: string]: K } => {
  return (
    items?.reduce((acc, item) => {
      if (item.static_column_id) {
        acc = {
          ...acc,
          [item.static_column_id]: (key ? item[key] : item) as K,
        };
      }
      return acc;
    }, {}) ?? {}
  );
};

export const transformReportToolDependencyToReduxTool = (
  reportToolDependency: ReportToolDependency,
  reduxTool?: Partial<ReduxTool>
): ReduxTool => {
  const static_column_id = reportToolDependency.static_column_id ?? "";
  return {
    ...reportToolDependency,
    ...reduxTool,
    static_column_id: static_column_id,
    dependency_column_ids: reportToolDependency.dependency_columns ?? [],
  };
};

export const getSummaryColumnIdFromToolMap = (
  toolMap?: ColumnIdToToolMapType
): ReduxTool["static_column_id"] | undefined => {
  return getSummaryColumnFromToolMap(toolMap)?.static_column_id;
};

export const getSummaryColumnFromToolMap = (
  toolMap?: ColumnIdToToolMapType
): ReduxTool | undefined => {
  const tools = Object.values(toolMap ?? {});
  return tools.find((tool) => isSummaryTool(tool.tool));
};

export const getFindColumnIdsFromTools = (
  toolMap?: ColumnIdToToolMapType
): ReduxTool["static_column_id"][] => {
  return Object.values(toolMap ?? {}).reduce(
    (acc, tool) => {
      if (isAnswerToolType(tool.tool)) acc.push(tool.static_column_id);
      return acc;
    },
    [] as ReduxTool["static_column_id"][]
  );
};

export const getToolTypeFromColumnAndData = (
  row: ReportTableRow | SSRMRow,
  columnId: ReduxTool["static_column_id"]
) => row?.cells?.[columnId]?.tool;

export const getToolTypeFromColumnAndTools = (
  tools: ColumnIdToToolMapType,
  columnId: ReduxTool["static_column_id"]
) => tools[columnId]?.tool;

// Type guard function for all answer tools
export const isAnswerToolType = (
  type?: ReportToolType,
  includeSummary = true
): type is AnswerToolType => {
  return (
    type === "find" ||
    (includeSummary && type === "precomputed_summary") ||
    type === "precomputed_fast_find"
  );
};

export const isSummaryTool = (type?: ReportToolType) =>
  type === "precomputed_summary";

export const getFallbackModel = (model?: ModelType) => {
  switch (model) {
    case "gpt-4-1106-preview":
      return "gpt-4t-hebbia";
    default:
      return model;
  }
};

export const countAnswerTools = (toolMap: ColumnIdToToolMapType) =>
  Object.values(toolMap).filter(({ tool }) => isAnswerToolType(tool)).length;

export const updateToolInToolMap = (
  toolMap: ColumnIdToToolMapType,
  columnId: string,
  update: Partial<ReduxTool>
): void => {
  // Check if the columnId exists in the toolMap
  if (!toolMap[columnId]) {
    throw new Error(
      `Column with ID ${columnId} does not exist in the tool map.`
    );
  }

  toolMap[columnId] = {
    ...toolMap[columnId],
    ...update,
  } as ReduxTool;
};

export const addToolToToolMap = (
  toolMap: ColumnIdToToolMapType,
  columnId: string,
  tool: ReduxTool
): void => {
  toolMap[columnId] = tool;
};
