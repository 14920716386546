import { OrgType } from "source/Types";
import { SourceType } from "./shared/types";
import {
  MOCK_INTEGRATIONS_SOURCE_TAB_MAPPING,
  MOCK_INTEGRATION_SOURCE_TAB_ORGS,
  SOURCE_TYPE_CONFIG_MAPPING,
  SourceConfig,
} from "./shared/config";
import {
  FastBuildSource,
  IntegrationStatus,
} from "source/types/matrix/fastBuild.types";
import { isS3Active } from "source/utils/matrix/fastBuild";
import { getFeatureFlag } from "source/utils/ldClient";

export const getTabName = (
  item: { value: SourceType; name: string },
  currentOrg: OrgType | null
) => {
  if (item.value === SourceType.ORGANIZATION) {
    return currentOrg?.name ?? "";
  }

  if (item.value === SourceType.AZURE_FILE_SHARE) {
    return `${currentOrg?.name} Secure Drive`;
  }

  return item.name;
};

export const getSourceTabs = ({
  currentOrgId,
  enablePublicSources,
  enableThirdBridgeIntegration,
  statuses,
  disablePersonalUploads,
}: {
  currentOrgId?: string;
  enablePublicSources: boolean;
  enableThirdBridgeIntegration: boolean;
  statuses: Partial<Record<FastBuildSource, any>>;
  disablePersonalUploads: boolean;
  enableSharePoint?: boolean;
  showAzureFileShare?: boolean;
  enableS3FastBuild?: boolean;
  enableBoxFastBuildIntegration?: boolean;
}): SourceConfig[] => {
  const isIntegrationSourceTabOrg =
    currentOrgId && MOCK_INTEGRATION_SOURCE_TAB_ORGS.includes(currentOrgId);
  const mergedSourceConfig = {
    ...SOURCE_TYPE_CONFIG_MAPPING,
    ...(isIntegrationSourceTabOrg ? MOCK_INTEGRATIONS_SOURCE_TAB_MAPPING : {}),
  };

  const enableSharePointFastBuild =
    statuses.sharepoint === IntegrationStatus.ENABLED_ACTIVE;
  const enableS3FastBuild = isS3Active(statuses);
  const enableBoxFastBuildIntegration =
    statuses.box === IntegrationStatus.ENABLED_ACTIVE;
  const enableDropboxFastBuildIntegration =
    statuses.dropbox === IntegrationStatus.ENABLED_ACTIVE;

  return Object.values(mergedSourceConfig).filter((item) => {
    const isOrgDisabled =
      item.value === SourceType.ORGANIZATION &&
      (!currentOrgId || getFeatureFlag("disableAllSharedContent", false));
    const isPublicCompaniesDisabled =
      item.value === SourceType.PUBLIC_COMPANIES && !enablePublicSources;
    const isSharedWithMeDisabled =
      item.value === SourceType.SHARED_WITH_ME &&
      getFeatureFlag("disableAllSharedContent", false);
    const isSharePointDisabled =
      item.value === SourceType.SHAREPOINT && !enableSharePointFastBuild;
    const isAzureFileShareDisabled =
      item.value === SourceType.AZURE_FILE_SHARE &&
      !getFeatureFlag("enableAzureFileShare", false);
    const isS3Disabled = item.value === SourceType.S3 && !enableS3FastBuild;
    const isBoxDisabled =
      item.value === SourceType.BOX && !enableBoxFastBuildIntegration;
    const isThirdBridgeDisabled =
      item.value === SourceType.THIRD_BRIDGE && !enableThirdBridgeIntegration;

    const isDropboxDisabled =
      item.value === SourceType.DROPBOX && !enableDropboxFastBuildIntegration;
    const isPersonalUploadsDisabled =
      item.value === SourceType.PERSONAL_UPLOADS && disablePersonalUploads;

    return !(
      isOrgDisabled ||
      isPublicCompaniesDisabled ||
      isSharedWithMeDisabled ||
      isSharePointDisabled ||
      isAzureFileShareDisabled ||
      isS3Disabled ||
      isBoxDisabled ||
      isThirdBridgeDisabled ||
      isDropboxDisabled ||
      isPersonalUploadsDisabled
    );
  });
};
