import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
} from "react";
import { classNames } from "core";

export type ButtonVariant = "primary" | "outlined" | "text";
export type ButtonSize = "xs" | "sm" | "md" | "lg";

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  children: ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    iconRight,
    iconLeft,
    className,
    variant = "primary",
    size = "md",
    ...rest
  } = props;

  return (
    <button
      {...rest}
      ref={ref}
      className={classNames(
        // base styling
        "group flex w-fit items-center justify-center truncate rounded py-1.5 font-medium leading-3 transition",

        // color icons children
        "[&_svg]:fill-current [&_svg]:text-inherit",

        // handle the size prop
        size === "lg" &&
          "h-9 min-h-9 gap-2 px-4 text-sm [&>.material-symbols-sharp]:max-w-5 [&>.material-symbols-sharp]:text-xl [&_svg]:h-5 [&_svg]:w-5",
        size === "md" &&
          "h-7 min-h-7 gap-1 px-2 text-xs [&_svg]:h-4 [&_svg]:w-4",
        size === "sm" &&
          "h-6 min-h-6 gap-1 px-1.5 text-xs [&_svg]:h-4 [&_svg]:w-4",
        size === "xs" &&
          "h-5 min-h-5 gap-1 px-1 text-xs [&_svg]:h-4 [&_svg]:w-4",

        // handle hover - here we're mixing 6% opacity black with the current bg color
        "hover:bg-gradient-to-r hover:from-black/[0.06] hover:to-black/[0.06] hover:bg-blend-multiply",

        // handle active state (when mouse is pressed over the button)
        "active:bg-gradient-to-r active:from-black/[0.1] active:to-black/[0.1] active:bg-blend-multiply",

        // handle disabled state
        "disabled:cursor-default disabled:from-black/0 disabled:to-black/0 [&:disabled>svg]:text-neutral-300",

        // handle focused state
        "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-300",

        // handle variants
        variant === "primary" &&
          "bg-brand-500 text-white active:from-black/[0.20] active:to-black/[0.20] disabled:bg-neutral-300 [&:disabled>svg]:text-white",
        variant === "outlined" &&
          "border border-neutral-200 bg-white text-neutral-600 shadow-sm disabled:border-neutral-200 disabled:bg-white disabled:text-neutral-300 disabled:shadow-none [&_svg]:text-neutral-500",
        variant === "text" &&
          "text-neutral-600 disabled:bg-transparent disabled:text-neutral-300 [&_svg]:text-neutral-500",

        className
      )}
    >
      {iconLeft}
      {children}
      {iconRight}
    </button>
  );
});

export default Button;
