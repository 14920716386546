import {
  BackendReportFilter,
  BASIC_REPORT_FILTER_VALUES,
  FilterType,
  ReportFilter,
  ReportFilterOperation,
  UserType,
} from "source/Types";
import {
  PromptListItem,
  PromptList,
  SheetColumnMetadata,
} from "./types/reports.types";
import _ from "lodash";
import { GOALIE_USERGROUP, sendSlackAlert } from "source/utils/slack";
import LogRocket from "logrocket";
import {
  ALERTS_PUBLICS_SLACK_WEBHOOK_URL,
  DEFAULT_TOOL_PARAM_OUTPUT_TYPE,
  REPORTS_SLACK_WEBHOOK_URL,
} from "source/constants";
import { FILTER_KEY_SECTION_TITLE_MAP } from "source/components/shared/NoDocumentsOverlay/components/SelectedTargetFilterChip";
import { ValidSlackURLType } from "source/utils/slack";
import { ColumnIdToToolMapType } from "source/redux/matrix";
import logger from "source/utils/logger";
import { getTickersFromFilter } from "source/api/filter/filters";
import { ReportPrompt, ReportToolParamType } from "./types/tools.types";
import { v4 as uuidv4 } from "uuid";
import { ModelType } from "source/constants/llms";
import {
  setActiveDocSource,
  LoginState,
  setSharePointLoginState,
  setBoxLoginState,
} from "source/redux/addDocs";
import { SourceType } from "./menu/AddDocumentsModal/shared/types";
import api from "source/api";
import { Dispatch } from "react";
import { UnknownAction } from "@reduxjs/toolkit";
import removeMd from "remove-markdown";
import { markdownToHtml } from "../markdown-editor/markdownParser";

export type LogRocketSlackErrorParams = {
  msg: string;
  user?: UserType;
  webhook_url: ValidSlackURLType;
  errorMsg?: string;
  stack?: string;
  error_id?: string;
};

export const sendLogRocketSlackError = ({
  msg,
  user,
  webhook_url,
  errorMsg,
  stack,
  error_id,
}: LogRocketSlackErrorParams) => {
  if (user) {
    msg = `${msg}\n\nFor user: ${user.email}`;
  }

  let logRocketURL = "";
  LogRocket.getSessionURL((sessionURL) => {
    logRocketURL = sessionURL;
  });

  if (logRocketURL) {
    msg = `${msg}\n\nLogRocket: ${logRocketURL}`;
  }

  if (errorMsg) {
    msg = `${msg}\n\nError Message: ${errorMsg}`;
  }
  if (stack) {
    msg = `${msg}\n\nStack:\n${stack}`;
  }

  if (error_id) {
    msg = `${msg}\n\nError ID: ${error_id}`;
  }

  msg = `${msg}\n\n${GOALIE_USERGROUP}`;
  logger.error(msg, { user: user });
  sendSlackAlert(msg, webhook_url);
};

export type ReportsSlackErrorParams = {
  msg: string;
  reportId?: string;
  tabId?: string;
  user?: UserType;
  batchDeleteReports?: string[];
  toolMap?: ColumnIdToToolMapType;
  cells?: string[];
  errorMsg?: string;
  stack?: string;
  details?: Record<string, any>;
};

// Helper function for slack messages for reports
export const sendReportsSlackError = ({
  msg,
  reportId,
  tabId,
  user,
  batchDeleteReports,
  toolMap,
  cells,
  errorMsg,
  stack,
  details,
}: ReportsSlackErrorParams) => {
  if (user) {
    msg = `${msg}\n\nFor user: ${user.email}`;
  }
  if (reportId) {
    const url = `search.hebbia.ai/matrix?matrix_id=${reportId}&tab_id=${tabId}`;
    msg = `${msg}\n\nFor report: ${reportId}\n\nURL: ${url}`;
  }
  if (batchDeleteReports) {
    msg = `${msg}\n\nFor batch reports: ${batchDeleteReports}`;
  }
  if (toolMap) {
    msg = `${msg}\n\nWith tool map: ${toolMap}`;
  }
  if (cells) {
    msg = `${msg}\n\nWith cells: ${cells.join(",")}`;
  }
  if (errorMsg) {
    msg = `${msg}\n\nError Message: ${errorMsg}`;
  }
  if (stack) {
    msg = `${msg}\n\nStack:\n${stack}`;
  }
  if (details) {
    msg = `${msg}\n\nDetails:\n${JSON.stringify(details)}`;
  }
  sendLogRocketSlackError({
    msg: msg,
    webhook_url: REPORTS_SLACK_WEBHOOK_URL,
    errorMsg: errorMsg,
    stack: stack,
  });
};

/* Helper function to convert from ReportFilter to BackendReportFilter */
export const convertToBackendReportFilter = (
  filter: ReportFilter
): BackendReportFilter => ({
  key: filter.key,
  // If values is a string / number / boolean, no conversion necessary
  values: BASIC_REPORT_FILTER_VALUES.includes(typeof filter.values)
    ? (filter.values as string | number | boolean)
    : filter.values // Otherwise, recursively convert the ReportFilter values in the Record object
      ? Object.values(filter.values)
          .map((val: ReportFilter) => convertToBackendReportFilter(val))
          // Exclude empty values
          .filter((filter) => filter.values && !_.isEmpty(filter.values))
      : [],
  operation: filter.operation,
});

/* Returns true if report filter is empty */
export const getFilterIsEmpty = (report: ReportFilter) =>
  _.isEmpty(report.values) ||
  // Check every sidebar section is empty
  (!BASIC_REPORT_FILTER_VALUES.includes(typeof report.values) &&
    Object.values(report.values)
      .map((sectionFilter: ReportFilter) => getFilterIsEmpty(sectionFilter))
      .every((val) => val));

/* Adds list of FilterType filters into BackendReportFilter */
export const addAppliedFiltersToBackendReportFilter = (
  backendFilter: BackendReportFilter,
  appliedFilters: FilterType[]
) => {
  const backendFilterCopy = _.cloneDeep(backendFilter);
  // Map filter sections to list of applied filters
  const appliedFilterSectionMap: { [section: string]: FilterType[] } = {};
  appliedFilters.forEach((filter) => {
    const filterSection = FILTER_KEY_SECTION_TITLE_MAP[filter.key];
    if (filterSection !== undefined) {
      if (appliedFilterSectionMap[filterSection] !== undefined)
        appliedFilterSectionMap[filterSection]?.push(filter);
      else appliedFilterSectionMap[filterSection] = [filter];
    }
  });
  const nestedFilters = backendFilterCopy.values as BackendReportFilter[];
  nestedFilters.forEach((nestedFilter) => {
    // Check if the nested filter key matches a filter section
    if (
      nestedFilter.key &&
      Object.keys(appliedFilterSectionMap).includes(nestedFilter.key) &&
      !BASIC_REPORT_FILTER_VALUES.includes(typeof nestedFilter.values)
    ) {
      // Add the applied filters within the matching filter section
      const nestedNestedFilters = nestedFilter.values as BackendReportFilter[];
      const matchingAppliedFilters = appliedFilterSectionMap[nestedFilter.key];
      matchingAppliedFilters?.forEach((filter) => {
        nestedNestedFilters.push({
          key: filter.key,
          operation: ReportFilterOperation.IS,
          values: filter.value,
        });
      });
      // Remove the applied filters from the map
      delete appliedFilterSectionMap[nestedFilter.key];
    }
  });
  // If there are remaining applied filters, create new sections for these
  Object.entries(appliedFilterSectionMap).forEach((item) => {
    const sectionKey = item[0];
    const filters = item[1];
    // Create list of filters
    const filterValues: BackendReportFilter[] = filters.map((filter) => ({
      key: filter.key,
      operation: ReportFilterOperation.IS,
      values: filter.value,
    }));
    // Create section to OR this list of filters
    const newSection: BackendReportFilter = {
      key: sectionKey,
      operation: ReportFilterOperation.OR,
      values: filterValues,
    };
    // Push to main filters object
    nestedFilters.push(newSection);
  });
  return backendFilterCopy;
};

type ReportMetaFromTemplate = {
  prompts: ReportPrompt[];
  columnMetadata: SheetColumnMetadata[];
  columnToolParams: { [col: number]: ReportToolParamType };
};

export const getReportMetaFromTemplate = (
  defaultTool: ModelType,
  template: PromptList
): ReportMetaFromTemplate => {
  const orderedPrompts = template.prompts.sort((a, b) => a.num - b.num);

  return orderedPrompts.reduce(
    (
      acc: ReportMetaFromTemplate,
      promptItem: PromptListItem,
      index: number
    ) => {
      const static_column_id = uuidv4();
      const x = index + 1;
      acc.prompts.push({ x, prompt: promptItem.prompt, static_column_id });
      acc.columnToolParams[static_column_id] = {
        model: promptItem.model ?? defaultTool,
        output_type: promptItem.outputType ?? DEFAULT_TOOL_PARAM_OUTPUT_TYPE,
        tool_spec: {
          output_format: promptItem.toolSpec?.outputFormat,
          output_options: promptItem.toolSpec?.outputOptions?.split(", "),
          output_unit: promptItem.toolSpec?.outputUnit,
          output_scale: promptItem.toolSpec?.outputScale,
        },
      };
      acc.columnMetadata.push({
        x,
        name: promptItem.columnName ?? `Column ${x}`,
        static_column_id,
      });
      return acc;
    },
    {
      prompts: [],
      columnToolParams: {},
      columnMetadata: [],
    }
  );
};

export const compareArrays = (a: any[], b: any[]): boolean =>
  a.length === b.length && a.every((element, index) => element === b[index]);

export const getDiffBetweenArrays = (arr1: any[], arr2: any[]) => {
  const difference = new Set(arr1.filter((num) => !arr2.includes(num)));
  return Array.from(difference);
};

export const getDefaultToolParams = (defaultModel: ModelType) => {
  const defaultToolParams: ReportToolParamType = {
    output_type: DEFAULT_TOOL_PARAM_OUTPUT_TYPE,
    model: defaultModel,
    tool_spec: undefined,
    experiment_config: undefined,
  };

  return defaultToolParams;
};

export const countDigits = (num?: number): number =>
  num !== undefined ? Math.abs(num)?.toString()?.length : 0;

export const nth = (day: number): string => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const getDefaultMatrixName = (): string => {
  const date = new Date();
  return (
    "Matrix on " +
    date.toLocaleString("default", { month: "long" }) +
    " " +
    date.getDate() +
    nth(date.getDate())
  );
};

type VerifyValidReportSessionProps = {
  reportId?: string | null;
  orgId?: string;
  tabId?: string;
  errorMessage?: string;
};

/**
 * Helper functions that verifies a report, org, and tab all exist
 * @returns {boolean}
 */
export const verifyValidReportSession = ({
  reportId,
  orgId,
  tabId,
  errorMessage,
}: VerifyValidReportSessionProps) => {
  if (!reportId || !orgId || !tabId) {
    logger.error(
      errorMessage ??
        "Can't run matrix operations with missing matrix, orgId or tab",
      {
        reportId,
        orgId,
        tabId,
      }
    );
    return false;
  }
  return true;
};

export type AddDocsSlackError = {
  user?: UserType;
  filters?: BackendReportFilter;
  search?: string;
};

export const sendAddDocsSlackError = ({
  user,
  filters,
  search,
}: AddDocsSlackError) => {
  const error_text = `Publics Search Failure\nQuery: ${
    search ?? ""
  }\nTickers: ${filters ? getTickersFromFilter(filters) : ""}\nUser: ${
    user?.email
  }\nFull Filters: ${JSON.stringify(filters)} \n`;
  sendSlackAlert(error_text, ALERTS_PUBLICS_SLACK_WEBHOOK_URL);
};

export const sendAddDocsCompanySearchError = ({
  user,
  search,
}: AddDocsSlackError) => {
  const error_text = `Company Search Failure\nQuery: ${search ?? ""}\nUser: ${
    user?.email
  }\n`;
  sendSlackAlert(error_text, ALERTS_PUBLICS_SLACK_WEBHOOK_URL);
};

export const setFastBuildSource = (
  defaultSource: string | undefined,
  loginState: LoginState,
  orgId: string | undefined,
  dispatch: Dispatch<UnknownAction>,
  clearParams: (filters: string[]) => void
) => {
  if (defaultSource) {
    if (
      defaultSource === "sharepoint" &&
      loginState !== LoginState.SUCCESS &&
      orgId
    ) {
      // set loading state temporarily
      dispatch(setSharePointLoginState(LoginState.INITIAL_FETCH));
      const fetchData = async () => {
        const userAuthState = await api.reportsBuild.getUserAuthState(
          SourceType.SHAREPOINT,
          orgId
        );
        // then set to either success or not started
        if (userAuthState.logged_in)
          dispatch(setSharePointLoginState(LoginState.SUCCESS));
        else dispatch(setSharePointLoginState(LoginState.NOT_STARTED));
      };
      fetchData();
    }

    if (defaultSource === "box" && loginState !== LoginState.SUCCESS && orgId) {
      // set loading state temporarily
      dispatch(setBoxLoginState(LoginState.INITIAL_FETCH));

      const fetchData = async () => {
        const userAuthState = await api.reportsBuild.getUserAuthState(
          SourceType.BOX,
          orgId
        );
        // then set to either success or not started
        if (userAuthState.logged_in)
          dispatch(setBoxLoginState(LoginState.SUCCESS));
        else dispatch(setBoxLoginState(LoginState.NOT_STARTED));
      };
      fetchData();
    }

    if (defaultSource === "local") {
      dispatch(setActiveDocSource(SourceType.PERSONAL_UPLOADS));
    } else {
      dispatch(setActiveDocSource(defaultSource as SourceType));
    }
    clearParams(["source"]);
  }
};

export const copyText = async (text: string) => {
  const removedCitations = text.replace(/\s?\[\d+]/gi, "");
  const htmlMarkdown = markdownToHtml(removedCitations);

  const plainText = removeMd(removedCitations, {
    stripListLeaders: true,
    listUnicodeChar: "•",
    gfm: true,
    useImgAltText: true,
  });

  await navigator.clipboard.write([
    new ClipboardItem({
      "text/plain": new Blob([plainText], {
        type: "text/plain",
      }),
      "text/html": new Blob([htmlMarkdown], { type: "text/html" }),
    }),
  ]);
};

export const pathToParts = (path: string): { path: string; name: string }[] => {
  const parts = path.replace(/^\//, "").split("/");

  return parts.map((part, index) => ({
    path: parts.slice(0, index + 1).join("/"),
    name: part,
  }));
};
