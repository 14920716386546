import { useDispatch, useSelector } from "react-redux";
import { getCurrentOrg, setCurrentOrg } from "source/redux/organization";
import { OrgType } from "source/Types";
import { useSetActiveDocumentList } from "./document-list/useSetActiveDocumentList";
import { getShowAllActivities, setSearchBarCopy } from "source/redux/ui";
import { useActivityFeed } from "./useActivityFeed";
import { useGlobalNavigator, useSearchStateManager } from "./useSetRouter";
import { resetAll } from "source/redux/documentList";
import { resetAllDocumentLists } from "source/redux/documentLists";
import router from "next/router";

export const useOrgSwitch = () => {
  const dispatch = useDispatch();
  const setActiveDocumentList = useSetActiveDocumentList();
  const { fetchAndReplaceActivities } = useActivityFeed();
  const { clearUserTargetState } = useSearchStateManager();
  const showAll = useSelector(getShowAllActivities);
  const currentOrg = useSelector(getCurrentOrg);
  const activeReportId = router.query.matrix_id;
  const { goToMatrixHome } = useGlobalNavigator();

  const onOrgSwitch = (org: OrgType) => {
    if (org.id === currentOrg?.id) return;

    setActiveDocumentList(null);
    dispatch(setCurrentOrg(org));
    dispatch(setSearchBarCopy(`Ask all ${org.name} projects`));
    fetchAndReplaceActivities(showAll);
    clearUserTargetState();
    dispatch(resetAll());
    dispatch(resetAllDocumentLists());

    if (activeReportId) goToMatrixHome();
  };

  return { onOrgSwitch };
};
