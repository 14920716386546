import React, { ReactElement, useEffect } from "react";
import { classNames } from "core";
import {
  FloatingPortal,
  useFloating,
  useDismiss,
  useInteractions,
  UseFloatingOptions,
} from "@floating-ui/react";
import Button from "../Button/Button";
import { useComponentId } from "../../hooks/useComponentId";
import { MenuContext, MenuProvider, useMenu } from "./MenuContext";
import { useMenuKeyboardEvents } from "../../hooks/useMenuKeyboardEvents";
import { flip } from "@floating-ui/core";

export type DropdownProps<T extends React.ElementType> = {
  isOpen?: boolean;
  setIsOpen?: (v: boolean) => void;
  className?: string;
  renderMenu: () => ReactElement;
  floatingOptions?: Partial<UseFloatingOptions>;
  disabled?: boolean;
} & React.ComponentPropsWithoutRef<T>;

const DropdownInner = <T extends React.ElementType>({
  className,
  as = Button,
  onClick,
  renderMenu,
  children,
  id,
  floatingOptions,
  isOpen: isOpenProps,
  setIsOpen: setIsOpenProps,
  menuStyleOverrides,
  disabled = false, // Default to false

  ...rest
}: DropdownProps<T>) => {
  const { setFocusedIndex } = useMenu();
  const [isOpen, _setIsOpen] = React.useState(Boolean(isOpenProps));

  const setIsOpen = (value: boolean) => {
    if (disabled) return;
    _setIsOpen(value);
    setIsOpenProps?.(value);
  };

  useEffect(() => {
    if (isOpenProps !== undefined && isOpenProps !== isOpen) {
      _setIsOpen(isOpenProps);
    }
  }, [isOpen, isOpenProps]);

  useEffect(() => {
    if (!isOpen) {
      // we need to reset the focusedIndex each time the menu is closed
      setFocusedIndex(-1);
    }
  }, [isOpen, setFocusedIndex]);

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-end",
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [flip()],
    ...floatingOptions,
  });
  const dismiss = useDismiss(context);
  const menuContext = useMenu();

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const buttonId = useComponentId(id, "Dropdown");
  const menuId = `Menu_${buttonId}`;
  const Component = as;
  const handleKeyDown = useMenuKeyboardEvents({
    handleEscapeKeyDown: () => {
      setIsOpen(false);
    },
    enabled: isOpen && !disabled,
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Component
        {...rest}
        {...getReferenceProps()}
        className={classNames("", className)}
        ref={refs.setReference}
        id={buttonId}
        aria-haspopup="true"
        aria-expanded={isOpen}
        onClick={(e) => {
          if (disabled) return;
          setIsOpen(true);
          onClick?.(e);
        }}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      >
        {children}
      </Component>
      {isOpen && !disabled && (
        <FloatingPortal>
          <MenuContext.Provider
            value={{
              ...menuContext,
              getMenuProps: () => ({
                ref: refs.setFloating,
                style: floatingStyles,
                role: "menu",
                variant: "elevated",
                className: classNames(
                  "origin-top-right mt-2 z-50",
                  menuStyleOverrides
                ),
                id: menuId,
                ...getFloatingProps(),
              }),
              getMenuItemProps: () => ({
                // close the menu anytime a MenuItem is selected
                onClick: () => {
                  // we need to blur the click event so that it does not propagate
                  // to the dropdown button (which would re-open the <Menu>)
                  if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                  }
                  handleClose();
                },
              }),
            }}
          >
            {renderMenu()}
          </MenuContext.Provider>
        </FloatingPortal>
      )}
    </>
  );
};

const Dropdown = <T extends React.ElementType>(props: DropdownProps<T>) => {
  return (
    <MenuProvider id={props.id}>
      <DropdownInner {...props} />
    </MenuProvider>
  );
};

export default Dropdown;
