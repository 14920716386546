import axios from "../axios";
import { GenericAbortSignal } from "axios";
import { IServerSideGroupSelectionState } from "ag-grid-community";
import {
  DocumentList,
  DocumentsListStatusResponse,
  DocumentListUser,
  DocumentListUserPermissions,
  FolderItem,
  CreateDocumentListRequest,
  GetDocumentsRequest,
  MoveFolderRequest,
  UpdateFolderParams,
  CreateFolderRequest,
  UploadFolderRequest,
  UploadFolderResponse,
  GetChildrenResponse,
  MoveItemToDocumentList,
  UploadURLsRequest,
  DocumentListIndexingStatus,
  DocumentListSearchResponse,
  DocumentSearchHybridResponse,
  GetDocumentsSearchHybridRequest,
  BulkDeleteResponse,
  GetDocumentsSearchRequest,
  DocumentListFilterOptionsResponse,
  DocumentListFiltersResponse,
  DocumentListDocumentMetadataResponse,
  UploadIntegrationDocRequest,
  UploadIntegrationDocResponse,
  SyncExternalFolderResponse,
  SyncExternalFolderRequest,
  ResyncExternalFolderRequest,
  ResyncExternalFolderResponse,
  AddDocumentsResponse,
  UploadURLsResponse,
} from "source/types/document-list/documentList.types";

export const DocumentListAPI = {
  getDocumentLists: (): Promise<DocumentList[]> =>
    axios.get(`/docs/lists/all`).then(({ data }) => data),
  createDocumentList: (
    request: CreateDocumentListRequest
  ): Promise<DocumentList> =>
    axios.post(`/docs/lists/`, request).then(({ data }) => data),
  updateDocumentList: async (
    documentListId: string | undefined,
    request: CreateDocumentListRequest
  ): Promise<DocumentList> => {
    if (!documentListId) throw new Error("documentListId must be provided");
    const { data } = await axios.patch(
      `/docs/lists/${documentListId}`,
      request
    );
    return data;
  },
  deleteDocumentList: async (documentListId: string): Promise<DocumentList> => {
    const { data } = await axios.delete(`/docs/lists/${documentListId}`);
    return data;
  },
  getChildren: (
    documentListId: string,
    folderId: string | undefined,
    request: GetDocumentsRequest
  ): Promise<GetChildrenResponse> =>
    axios
      .post(
        `/docs/lists/${documentListId}/get-children${folderId ? `/${folderId}` : ""}`,
        request
      )
      .then(({ data }) => data),
  search: (
    documentListId: string,
    request: GetDocumentsSearchRequest
  ): Promise<DocumentListSearchResponse> =>
    axios
      .post(`/docs/lists/${documentListId}/documents-search`, request)
      .then(({ data }) => data),
  hybridSearch: (
    documentListId: string,
    request: GetDocumentsSearchHybridRequest
  ): Promise<DocumentSearchHybridResponse> =>
    axios
      .post(`/docs/lists/${documentListId}/documents-search-hybrid`, request)
      .then(({ data }) => data),
  getDocumentListMetadata: (documentListId: string): Promise<DocumentList> =>
    axios.get(`/docs/lists/${documentListId}`).then(({ data }) => data),
  getDocumentListStatus: (
    documentListId: string
  ): Promise<DocumentsListStatusResponse> =>
    axios
      .get(`/docs/lists/${documentListId}/documents/status`)
      .then(({ data }) => data),
  getUsersInOrg: (): Promise<DocumentListUser[]> =>
    axios.get(`/docs/users/`).then(({ data }) => data),
  getDocumentListPermissions: (
    documentListId: string
  ): Promise<DocumentListUserPermissions[]> =>
    axios.get(`/docs/permissions/${documentListId}`).then(({ data }) => data),
  createDocumentListPermissions: (
    documentListId: string,
    permissions: DocumentListUserPermissions[]
  ): Promise<DocumentListUserPermissions[]> =>
    axios
      .post(`/docs/permissions/${documentListId}`, {
        permissions,
      })
      .then(({ data }) => data),
  updateDocumentListPermissions: (
    documentListId: string,
    permissions: DocumentListUserPermissions[]
  ): Promise<DocumentListUserPermissions[]> =>
    axios
      .patch(`/docs/permissions/${documentListId}`, {
        permissions,
      })
      .then(({ data }) => data),
  deleteDocumentListPermissions: (
    documentListId: string,
    permissions: DocumentListUserPermissions[]
  ): Promise<DocumentListUserPermissions[]> =>
    axios
      .post(`/docs/permissions/${documentListId}/delete`, {
        permissions,
      })
      .then(({ data }) => data),
  createFolder: (
    documentListId: string,
    request: CreateFolderRequest
  ): Promise<{ id: string; path: string }> =>
    axios
      .post(`/docs/lists/${documentListId}/folder`, request)
      .then(({ data }) => data),
  uploadFolder: async ({
    documentListId,
    rootFolderId,
    files,
    ignoreRoot,
  }: UploadFolderRequest): Promise<UploadFolderResponse> => {
    const form = new FormData();

    files.forEach((file) => {
      if (file) {
        form.append("files", file);
      }
    });
    if (rootFolderId) form.set("root_folder_id", rootFolderId);
    if (ignoreRoot) form.set("ignore_root", ignoreRoot.toString());

    const res = await axios.post(
      `/docs/lists/${documentListId}/upload-folder`,
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res.data as UploadFolderResponse;
  },
  uploadIntegrationDoc: async (
    request: UploadIntegrationDocRequest<unknown, unknown>
  ): Promise<UploadIntegrationDocResponse> => {
    const res = await axios.post(
      `/docs/lists/${request.document_list_id}/upload-integration-doc`,
      request
    );
    return res.data as UploadIntegrationDocResponse;
  },
  uploadURLs: async (
    documentListId: string,
    request: UploadURLsRequest
  ): Promise<UploadURLsResponse> =>
    axios
      .post(`/docs/lists/${documentListId}/upload-urls`, request)
      .then(({ data }) => data),
  moveItems: async (
    documentListId: string,
    request: MoveFolderRequest
  ): Promise<{ success: boolean }> => {
    const response = await axios.post(
      `/docs/lists/${documentListId}/move-items`,
      request
    );
    return { success: response.status >= 200 && response.status < 300 };
  },
  syncIntegrationFolder: async (
    documentListId: string,
    request: SyncExternalFolderRequest<unknown, unknown>
  ): Promise<SyncExternalFolderResponse> => {
    const res = await axios.post(
      `/docs/lists/${documentListId}/sync-external-folder`,
      request
    );
    return res.data as SyncExternalFolderResponse;
  },
  resyncIntegrationFolder: async (
    documentListId: string,
    request: ResyncExternalFolderRequest
  ): Promise<ResyncExternalFolderResponse> => {
    const res = await axios.post(
      `/docs/lists/${documentListId}/resync-external-folder`,
      request
    );
    return res.data as ResyncExternalFolderResponse;
  },
  createNewRootFolder: (
    documentListId: string,
    rootFolderName: string
  ): Promise<{ id: string }> =>
    axios
      .post(`/docs/lists/${documentListId}/create-new-root-folder`, {
        name: rootFolderName,
      })
      .then(({ data }) => data),
  bulkDelete: async (
    documentListId: string,
    documentIds: string[],
    folderIds: string[]
  ): Promise<BulkDeleteResponse> => {
    const response = await axios.post<
      Pick<BulkDeleteResponse, "deleted_document_ids">
    >(`/docs/lists/${documentListId}/bulk-delete`, {
      document_ids: documentIds,
      folder_ids: folderIds,
    });
    return {
      deleted_document_ids: response.data.deleted_document_ids,
      success: response.status >= 200 && response.status < 300,
    };
  },
  updateFolder: async (
    documentListId: string | undefined,
    request: UpdateFolderParams
  ): Promise<any> => {
    if (!documentListId) throw new Error("documentListId must be provided");
    const { data } = await axios.patch(
      `/docs/lists/${documentListId}/folder`,
      request
    );
    return data;
  },
  getSelectionCount: async (
    documentListId: string,
    selectionState: IServerSideGroupSelectionState
  ) => {
    const response = await axios.post(
      `/docs/lists/selection/${documentListId}/document-count`,
      selectionState
    );
    return response.data as { doc_count: number };
  },
  getSelectionDocumentIds: async (
    documentListId: string,
    selectionState: IServerSideGroupSelectionState,
    limit: number,
    abortSignal?: GenericAbortSignal
  ) => {
    const response = await axios.post(
      `/docs/lists/selection/${documentListId}/document-ids`,
      { ...selectionState, limit },
      { signal: abortSignal }
    );
    return response.data as { doc_ids: string[] };
  },
  getSelectionForDocIds: async (documentListId: string, docIds: string[]) => {
    // if no docs are selected we don't need to go to the server
    if (docIds.length === 0) {
      return {
        selectAllChildren: false,
        toggledNodes: [],
        nodeId: undefined,
      } as IServerSideGroupSelectionState;
    }
    const response = await axios.post(
      `/docs/lists/selection/${documentListId}/get-selection`,
      {
        doc_ids: docIds,
      }
    );
    return response.data as IServerSideGroupSelectionState;
  },
  moveItemToDocumentList: async (
    documentListId: string,
    request: MoveItemToDocumentList
  ): Promise<{ success: boolean }> => {
    const response = await axios.post(
      `/docs/lists/${documentListId}/move-to-document-list`,
      request
    );
    return { success: response.status >= 200 && response.status < 300 };
  },
  getFolderPath: async (documentListId: string, nodeId: string) => {
    const response = await axios.get(
      `/docs/lists/${documentListId}/${nodeId}/path`
    );
    return response.data.path as FolderItem[];
  },
  getIndexingStatus: async (
    documentListId?: string
  ): Promise<DocumentListIndexingStatus> => {
    const response = await axios.post(
      `/docs/lists/${documentListId}/indexing-status`
    );
    return response.data.indexing_status;
  },
  getFilters: async (
    documentListId?: string
  ): Promise<DocumentListFiltersResponse> => {
    const response = await axios.get(`/docs/lists/${documentListId}/filters`);
    return response.data;
  },
  getFilterOptions: async (
    documentListId?: string
  ): Promise<DocumentListFilterOptionsResponse> => {
    const response = await axios.get(
      `/docs/lists/${documentListId}/filter-options-v2`
    );
    return response.data;
  },
  getDocumentMetadata: async (
    documentListId?: string
  ): Promise<DocumentListDocumentMetadataResponse> => {
    const response = await axios.get(
      `/docs/lists/${documentListId}/document-metadata`
    );
    return response.data;
  },
  addDocuments: async (
    documentListId: string,
    documentIds: string[]
  ): Promise<AddDocumentsResponse> => {
    const res = await axios.post(
      `/docs/lists/${documentListId}/add-documents`,
      { document_ids: documentIds }
    );
    return res.data as AddDocumentsResponse;
  },
};
