import { ReduxState } from ".";
import dayjs from "dayjs";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SOURCE_TYPE_CONFIG_MAPPING } from "source/components/matrix/menu/AddDocumentsModal/shared/config";
import {
  FastBuildSearchFilterMap,
  FilterMap,
  FilterType,
  PublicsFilterMap,
  SearchFilterResultType,
  SearchFilterType,
  SearchType,
  SortDirection,
  SourceType,
  UploadFileType,
} from "source/components/matrix/menu/AddDocumentsModal/shared/types";
import {
  addNewSelectionsToMap,
  calculateNumChildrenSelections,
  getSelectionState,
} from "source/utils/matrix/addDocs";
import _, { last, uniq } from "lodash";
import { PublicDocType, SimpleDocumentType } from "source/Types";
import { getCurrentOrg } from "./organization";
import { FiltersConfig } from "source/constants/filters";
import {
  SharePointMimeType,
  SharePointMetadataType,
  AzureFastBuildNode,
  SharepointFastBuildNode,
  SelectedResource,
  SelectedBoxResource,
  SelectedThirdBridgeResource,
  FastBuildNode,
  SelectedDropboxResource,
} from "source/types/matrix/fastBuild.types";
import {
  CustomRange,
  PresetFilterSelection,
} from "source/components/library/DateSelect/utils";
import { CompanySourceFilter } from "source/types/matrix/matrixChat.types";
import { getTabName } from "source/components/matrix/menu/AddDocumentsModal/helpers";
import { S3AuthContext } from "source/api/orgs/orgs";
import { getRemoteIdForDoc } from "source/utils/matrix/fastBuild";

export type DefaultModalPropsType = {
  activeDocSource?: SourceType;
  companyOptions?: CompanySourceFilter[];
  onAddDocuments?: () => void;
  hideSidebar?: boolean;
  addToDocumentList?: boolean;
};
export const DEFAULT_SEARCH_FILTER_RESULTS = {
  [SearchType.FILE_NAMES]: {
    isLoading: true,
    data: [],
  },
  [SearchType.FILE_NAMES_BM25]: {
    isLoading: true,
    data: [],
  },
  [SearchType.SEMANTIC_SIMILARITY]: {
    isLoading: true,
    data: [],
  },
  [FilterType.FILTER]: {
    isLoading: true,
    data: [],
  },
  [SearchType.COMPANY_SEARCH]: {
    isLoading: true,
    data: [],
  },
  [SearchType.KEYWORD]: {
    isLoading: true,
    data: [],
  },
  [SearchType.PREFIX]: {
    isLoading: true,
    data: [],
  },
};

export enum LoginState {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  SUCCESS = "success",
  FAILURE = "failure",
  INITIAL_FETCH = "initial_fetch",
}

export type ClickPointsType = {
  x: number | null;
  y: number | null;
  fastBuildNode: FastBuildNode<unknown, unknown> | null;
};

export type SelectionState = {
  [documentListId: string]: string[];
};

export type AddDocumentsReduxType = {
  activeDocSource: SourceType;
  search: string | null; // Stores search that is displayed while typing, before hitting enter
  activeSearch: string | null; // Stores search that is displayed in panel, after hitting enter
  activeFilters: FilterMap;
  fastBuildSearchFilters: FastBuildSearchFilterMap;
  publicsFilters: PublicsFilterMap;
  openSearchPalette: boolean;
  activeSort: SortDirection;
  checkSelections: { [key: string]: boolean }; // Store explicit user checkbox actions. True for selections, False for deselections
  expandedSearchType: SearchType | null;
  searchFilterResults: {
    [key in SearchFilterType]: SearchFilterResultType;
  };
  isPreviewInitializing: boolean;
  loadingDocIds: { [key: string]: boolean };
  shouldRunDocsQuery: boolean;

  selectedSharepointIds: string[]; // Tracks selected SharePoint IDs (different to Hebbia doc IDs)
  selectedSharepointResources: SharepointFastBuildNode[]; // For Fast Build integrations, tracks a selected parent
  sharePointLoginState: LoginState;

  boxLoginState: LoginState;
  dropboxLoginState: LoginState;
  defaultOpenUpload: boolean;
  defaultUploadType: UploadFileType | null; // default upload type for upload file popover
  hasSelectAll: boolean;
  selectedAzureFolders: AzureFastBuildNode[];
  clickPoints: ClickPointsType;

  // -- Document selection state -- //

  // This is the list of document ids that are already on the report, but can be manipulated in the modal
  existingDocumentIds: string[];

  // This is the list of document ids that are newly added in the modal
  newDocumentIds: string[];

  documentListSelectedDocIds: SelectionState;

  documentDetailMap: { [key: string]: SimpleDocumentType };
  remoteIdToDocIdMap: Record<string, string>;
  defaultModalProps: DefaultModalPropsType;
  searchFilterChip: SelectedResource<unknown> | null;

  // This filters the search results to the selected mime type, currently only present in the SharePoint integration
  mimeTypeSearchFilter: SharePointMimeType | null;
  sharePointMetadataFilter: SharePointMetadataType | null;
  selectedS3AuthContext: S3AuthContext | null; // for s3
  selectedS3Key: string | null; // for s3
  selectedBoxResource: SelectedBoxResource | null; // for box
  selectedDropboxResource: SelectedDropboxResource | null; // for dropbox

  enableAncestors: boolean;
  selectedThirdBridgeResource: SelectedThirdBridgeResource | null; // for third bridge

  // - For Add URL Button click from
  openAddURLButton: boolean;

  selectedCrawlNodes: FastBuildNode<unknown, unknown>[];
};

const DEFAULT_PUBLICS_FILTERS: PublicsFilterMap = {
  companyId: null,
  docTypes: [],
  searchQuery: "",
  dateRange: {
    start: dayjs(new Date(0)).toISOString(),
    end: null,
  },
  selectedDatePreset: "all time",
  companyOptions: null,
};

const initialState: AddDocumentsReduxType = {
  activeDocSource: SourceType.ORGANIZATION,
  search: "",
  activeSearch: "",
  activeFilters: {},
  fastBuildSearchFilters: {},
  activeSort: "asc",
  openSearchPalette: false,
  checkSelections: {},
  expandedSearchType: null,
  searchFilterResults: DEFAULT_SEARCH_FILTER_RESULTS,
  isPreviewInitializing: false,
  loadingDocIds: {},
  shouldRunDocsQuery: false,
  selectedSharepointIds: [],
  selectedSharepointResources: [],
  sharePointLoginState: LoginState.NOT_STARTED,
  boxLoginState: LoginState.NOT_STARTED,
  dropboxLoginState: LoginState.NOT_STARTED,
  defaultOpenUpload: false,
  defaultUploadType: null,
  hasSelectAll: false,
  publicsFilters: DEFAULT_PUBLICS_FILTERS,
  selectedAzureFolders: [],
  clickPoints: {
    x: null,
    y: null,
    fastBuildNode: null,
  },
  // Document selection state
  existingDocumentIds: [],
  newDocumentIds: [],
  documentListSelectedDocIds: {},
  documentDetailMap: {},
  remoteIdToDocIdMap: {},
  defaultModalProps: {},
  searchFilterChip: null,
  mimeTypeSearchFilter: null,
  sharePointMetadataFilter: null,
  selectedS3AuthContext: null,
  selectedS3Key: null,
  selectedBoxResource: null,
  selectedDropboxResource: null,
  enableAncestors: true,
  selectedThirdBridgeResource: null,
  openAddURLButton: false,
  selectedCrawlNodes: [],
};

export const getActiveDocSource = (state: ReduxState) =>
  state.addDocs.activeDocSource;
export const getSearch = (state: ReduxState) => state.addDocs.search;
export const getActiveSearch = (state: ReduxState) =>
  state.addDocs.activeSearch;
export const getActiveFilters = (state: ReduxState) =>
  state.addDocs.activeFilters;
export const getFastBuildSearchFilters = (state: ReduxState) =>
  state.addDocs.fastBuildSearchFilters;
export const getExistingDocumentIds = (state: ReduxState) =>
  state.addDocs.existingDocumentIds;
export const getNewDocumentIds = (state: ReduxState) =>
  state.addDocs.newDocumentIds;
export const getOpenSearchPalette = (state: ReduxState) =>
  state.addDocs.openSearchPalette;
export const getActiveSort = (state: ReduxState) => state.addDocs.activeSort;
export const getCheckSelections = (state: ReduxState) =>
  state.addDocs.checkSelections;
export const getDocumentDetailMap = (state: ReduxState) =>
  state.addDocs.documentDetailMap;
export const getRemoteIdToDocIdMap = (state: ReduxState) =>
  state.addDocs.remoteIdToDocIdMap;
export const getExpandedSearchType = (state: ReduxState) =>
  state.addDocs.expandedSearchType;
export const getSearchFilterResults = (state: ReduxState) =>
  state.addDocs.searchFilterResults;
export const getLoadingDocIds = (state: ReduxState) =>
  state.addDocs.loadingDocIds;
export const getIsPreviewPanelLoading = (state: ReduxState) =>
  Object.keys(state.addDocs.loadingDocIds).length > 0 ||
  state.addDocs.isPreviewInitializing;
export const getShouldRunDocsQuery = (state: ReduxState) =>
  state.addDocs.shouldRunDocsQuery;
export const getSelectedSharepointResources = (state: ReduxState) =>
  state.addDocs.selectedSharepointResources;
export const getSelectedRemoteIds = (state: ReduxState) =>
  state.addDocs.selectedSharepointIds;
export const getSharePointLoginState = (state: ReduxState) =>
  state.addDocs.sharePointLoginState;
export const getBoxLoginState = (state: ReduxState) =>
  state.addDocs.boxLoginState;
export const getDropboxLoginState = (state: ReduxState) =>
  state.addDocs.dropboxLoginState;
export const getDefaultOpenUpload = (state: ReduxState) =>
  state.addDocs.defaultOpenUpload;
export const getDefaultUploadType = (state: ReduxState) =>
  state.addDocs.defaultUploadType;
export const getHasSelectAll = (state: ReduxState) =>
  state.addDocs.hasSelectAll;
export const getPublicsDocTypes = (state: ReduxState) =>
  state.addDocs.publicsFilters.docTypes;
export const getPublicsSearchQuery = (state: ReduxState) =>
  state.addDocs.publicsFilters.searchQuery;
export const getPublicsSelectedDateRange = (state: ReduxState) =>
  state.addDocs.publicsFilters.dateRange;
export const getPublicsSelectedDatePreset = (state: ReduxState) =>
  state.addDocs.publicsFilters.selectedDatePreset;
export const getPublicsSelectedCompanyId = (state: ReduxState) =>
  state.addDocs.publicsFilters.companyId;
export const getPublicsCompanyOptions = (state: ReduxState) =>
  state.addDocs.publicsFilters.companyOptions;
export const getSelectedAzureFolders = (state: ReduxState) =>
  state.addDocs.selectedAzureFolders;
export const getClickPoints = (state: ReduxState) => state.addDocs.clickPoints;
export const getDefaultModalProps = (state: ReduxState) =>
  state.addDocs.defaultModalProps;
export const getSearchFilterChip = (state: ReduxState) =>
  state.addDocs.searchFilterChip;
export const getMimeTypeSearchFilter = (state: ReduxState) =>
  state.addDocs.mimeTypeSearchFilter;
export const getSharePointMetadataFilter = (state: ReduxState) =>
  state.addDocs.sharePointMetadataFilter;
export const getSelectedS3AuthContext = (state: ReduxState) =>
  state.addDocs.selectedS3AuthContext;
export const getSelectedS3Key = (state: ReduxState) =>
  state.addDocs.selectedS3Key;
export const getSelectedBoxResource = (state: ReduxState) =>
  state.addDocs.selectedBoxResource;
export const getSelectedDropboxResource = (state: ReduxState) =>
  state.addDocs.selectedDropboxResource;
export const getEnableAncestors = (state: ReduxState) =>
  state.addDocs.enableAncestors;
export const getSelectedThirdBridgeResource = (state: ReduxState) =>
  state.addDocs.selectedThirdBridgeResource;
export const getOpenAddURLButton = (state: ReduxState) =>
  state.addDocs.openAddURLButton;

export const getSelectedCrawlNodes = (state: ReduxState) =>
  state.addDocs.selectedCrawlNodes;

export const getDocDetailsById = (docId?: string) =>
  createSelector(getDocumentDetailMap, (docMap) =>
    docId ? docMap[docId] : undefined
  );

/**
 * Returns true if id is in checkOverrides. This is used for repos that have no children and therefore shouldn't be checked on initial load of docs list.
 * If item has children, check if all children selected = "checked", some = "indeterminate", otherwise "unchecked".
 * If no children and doc is selected = "checked"
 */
export const getIsIdSelected = ({
  id,
  numChildren,
  repoId,
  docCountMap,
}: {
  id: string;
  numChildren?: number;
  repoId?: string;
  docCountMap?: { [id: string]: number };
}) =>
  createSelector(
    [
      getCheckSelections,
      getDocumentDetailMap,
      getNumChildrenSelections(id, repoId, docCountMap),
    ],
    (
      checkSelections,
      documentDetailMap,
      { numChildrenSelected, numChildrenDeselected }
    ) => {
      if (numChildren) {
        if (numChildrenSelected === numChildren) return "checked";
        if (numChildrenDeselected === numChildren) return "unchecked";
      }
      // When some children are deselected but the doc / its ancestor is checked
      // we then want to show "indeterminate"
      const someChildrenDeselected =
        numChildrenDeselected > 0 &&
        !!numChildren &&
        numChildrenDeselected < numChildren;
      // Some children selected
      const someChildrenSelected =
        !!numChildren &&
        numChildrenSelected > 0 &&
        numChildrenSelected < numChildren;
      // If the given ID itself is selected or unselected
      const idSelectionState = getSelectionState(
        id,
        _.cloneDeep(checkSelections),
        someChildrenDeselected,
        someChildrenSelected
      );
      if (idSelectionState !== undefined) return idSelectionState;
      // If ancestor is explicitly selected
      if (!repoId && documentDetailMap[id]) {
        // If ancestor folder is checked
        const path = documentDetailMap[id]?.path;
        if (path) {
          // Loop through path from end to beginning
          for (let i = path.length - 1; i >= 0; i--) {
            const pathSelectionState = getSelectionState(
              path[i] ?? "",
              _.cloneDeep(checkSelections),
              someChildrenDeselected,
              someChildrenSelected
            );
            if (pathSelectionState !== undefined) return pathSelectionState;
          }
        }
        // If repo Id is checked
        const docRepoId = documentDetailMap[id]?.repo_id;
        const repoSelectionState = getSelectionState(
          docRepoId ?? "",
          _.cloneDeep(checkSelections),
          someChildrenDeselected,
          someChildrenSelected
        );
        if (repoSelectionState !== undefined) return repoSelectionState;
      }
      // If some children are selected but no other selections
      if (someChildrenSelected) return "indeterminate";
      return "unchecked";
    }
  );

export const getAllSelectedDocumentIds = createSelector(
  [getExistingDocumentIds, getNewDocumentIds],
  (existingDocumentIds, newDocumentIds) => {
    return [...existingDocumentIds, ...newDocumentIds];
  }
);

export const getDocSourceName = createSelector(
  [getActiveDocSource, getCurrentOrg],
  (activeDocSource, currentOrg) => {
    return getTabName(SOURCE_TYPE_CONFIG_MAPPING[activeDocSource], currentOrg);
  }
);

// Returns selected documents that are not folder
export const getAllSelectedDocuments = createSelector(
  [getAllSelectedDocumentIds, getDocumentDetailMap],
  (selectedDocumentIds, documentDetailMap) => {
    return selectedDocumentIds.filter(
      (docId) => documentDetailMap[docId]?.type !== "hebbia_folder"
    );
  }
);

export const getAllNewlySelectedAddDocsDocuments = createSelector(
  [getNewDocumentIds, getDocumentDetailMap],
  (newDocumentIds, documentDetailMap) => {
    return newDocumentIds.filter(
      (docId) => documentDetailMap[docId]?.type !== "hebbia_folder"
    );
  }
);

/**
 * Returns numChildrenSelected and numChildrenDeselected based on parent id
 */
export const getNumChildrenSelections = (
  id: string,
  repoId?: string,
  docCountMap?: {
    [id: string]: number;
  }
) =>
  createSelector(
    [getCheckSelections, getDocumentDetailMap],
    (checkSelections, documentDetailMap) =>
      calculateNumChildrenSelections(
        checkSelections,
        id,
        documentDetailMap,
        repoId,
        docCountMap
      )
  );

export const getActiveTickerFilter = createSelector(
  getActiveFilters,
  (filters) => FiltersConfig.TICKER_FILTER_KEY in filters
);

// Checks if a remote id is selected.
export const getRemoteIdIsSelected = (
  remoteId: string,
  allowDuplicates = true,
  getRemoteId?: (node: FastBuildNode<unknown, unknown>) => string
) =>
  createSelector(
    [
      getExistingDocumentIds,
      getNewDocumentIds,
      getRemoteIdToDocIdMap,
      getSelectedCrawlNodes,
    ],
    (existingDocIds, newDocIds, remoteIdMap, selectedCrawlNodes) => {
      if (getRemoteId) {
        const isCrawlSelected = selectedCrawlNodes.some(
          (node) => getRemoteId(node) === remoteId
        );
        return { checked: isCrawlSelected, previouslyAdded: false };
      }

      const docId = remoteIdMap[remoteId];

      // Sanity check
      if (!docId) {
        return { checked: false, previouslyAdded: false };
      }

      // If we allow duplicates (i.e. Sharepoint), we only need to check if the docId is in the new docs
      if (allowDuplicates) {
        return { checked: newDocIds.includes(docId), previouslyAdded: false };
      }

      if (newDocIds.includes(docId)) {
        return { checked: true, previouslyAdded: false };
      }

      if (existingDocIds.includes(docId)) {
        return {
          checked: existingDocIds.includes(docId),
          previouslyAdded: true,
        };
      }

      return { checked: false, previouslyAdded: false };
    }
  );

// Returns the selected remote resource rather than the array of selected resources
// Default to null if none are selected
export const getCurrentSelectedResource = createSelector(
  getSelectedSharepointResources,
  (selectedRemoteResource) => last(selectedRemoteResource)
);

// Returns selected documents that are not folder
export const getReportRepoIds = createSelector(
  [getDocumentDetailMap],
  (documentDetailMap) => {
    return uniq(
      Object.values(documentDetailMap)
        .map(({ repo_id }) => repo_id)
        .filter((repoId) => !!repoId)
    );
  }
);

const addDocsSlice = createSlice({
  name: "addDocs",
  initialState,
  reducers: {
    setActiveDocSource: (state, action: PayloadAction<SourceType>) => {
      state.activeDocSource = action.payload;
      state.activeFilters = {};
      state.search = null;
      state.activeSearch = null;
      state.openSearchPalette = false;
      state.searchFilterResults = DEFAULT_SEARCH_FILTER_RESULTS;
      state.expandedSearchType = null;
      state.publicsFilters = DEFAULT_PUBLICS_FILTERS;
      state.mimeTypeSearchFilter = null;
      state.selectedSharepointResources = [];
      state.selectedBoxResource = null;
      state.searchFilterChip = null;
      return state;
    },
    setSearch: (state, action: PayloadAction<string | null>) => {
      state.search = action.payload;
      return state;
    },
    setActiveSearch: (state, action: PayloadAction<string | null>) => {
      state.activeSearch = action.payload;
      return state;
    },
    setActiveFilters: (state, action: PayloadAction<FilterMap>) => {
      state.activeFilters = action.payload;
      return state;
    },
    setFastBuildSearchFilters: (
      state,
      action: PayloadAction<FastBuildSearchFilterMap>
    ) => {
      state.fastBuildSearchFilters = action.payload;
      return state;
    },
    setActiveSort: (state, action: PayloadAction<SortDirection>) => {
      state.activeSort = action.payload;
      return state;
    },
    setOpenSearchPalette: (state, action: PayloadAction<boolean>) => {
      state.openSearchPalette = action.payload;
      return state;
    },
    removeDocByDocId: (state, action: PayloadAction<string>) => {
      const docId = action.payload;

      // Remove doc from existing docs
      state.existingDocumentIds = state.existingDocumentIds.filter(
        (id) => id !== docId
      );

      // Remove doc from new docs
      state.newDocumentIds = state.newDocumentIds.filter((id) => id !== docId);

      // Update parentID too
      state.checkSelections = addNewSelectionsToMap({
        currentSelectionMap: _.cloneDeep(state.checkSelections),
        docMap: state.documentDetailMap,
        newSelectionIds: [docId],
        checked: false,
      });

      return state;
    },
    initializeDocumentList: (
      state,
      action: PayloadAction<SimpleDocumentType[]>
    ) => {
      const docs = action.payload;

      // Update document detail map
      state.documentDetailMap = docs.reduce(
        (map, doc) => {
          map[doc.id] = doc;
          return map;
        },
        { ...state.documentDetailMap }
      );

      // Update the remote id mapping
      state.remoteIdToDocIdMap = docs.reduce((acc, cur) => {
        const remoteId = getRemoteIdForDoc(cur);

        if (remoteId) {
          acc[remoteId] = cur.id;
        }

        return acc;
      }, state.remoteIdToDocIdMap);

      // Initialize doc ids
      const docIds = docs.map(({ id }) => id);
      state.existingDocumentIds = docIds;
      state.newDocumentIds = [];

      return state;
    },
    updateDocumentList: (
      state,
      action: PayloadAction<{
        checked: boolean;
        value: SimpleDocumentType[];
        updateCheckMap?: boolean;
        parentId?: string;
        childrenCount?: number;
        numChildrenDeselected?: number;
      }>
    ) => {
      const { checked, value, updateCheckMap, parentId } = action.payload;

      const docIds = value.map(({ id }) => id);

      if (checked) {
        // Dedupe new docs
        const allDocs = [...state.existingDocumentIds, ...state.newDocumentIds];
        const docListSet = new Set(allDocs);
        const filteredDocs = docIds.filter((docId) => !docListSet.has(docId));
        state.newDocumentIds = [...state.newDocumentIds, ...filteredDocs];
      } else {
        const docIdSet = new Set(docIds);
        state.newDocumentIds = state.newDocumentIds.filter(
          (doc) => !docIdSet.has(doc)
        );
      }

      // Update the remote id mapping
      state.remoteIdToDocIdMap = value.reduce((acc, cur) => {
        const remoteId = getRemoteIdForDoc(cur);

        if (remoteId) {
          acc[remoteId] = cur.id;
        }

        return acc;
      }, state.remoteIdToDocIdMap);

      /// Update the document detail map
      state.documentDetailMap = value.reduce((map, doc) => {
        map[doc.id] = doc;
        return map;
      }, state.documentDetailMap);

      // Update checkbox selection map if specified
      if (updateCheckMap) {
        // If specified, update parentID too
        const idsToUpdate = parentId ? [parentId] : docIds;
        state.checkSelections = addNewSelectionsToMap({
          currentSelectionMap: _.cloneDeep(state.checkSelections),
          docMap: state.documentDetailMap,
          newSelectionIds: idsToUpdate,
          checked,
        });
      }

      return state;
    },
    clearDocumentListAndSelections: (state) => {
      state = {
        ...initialState,
        documentDetailMap: state.documentDetailMap,
        remoteIdToDocIdMap: state.remoteIdToDocIdMap,
        existingDocumentIds: state.existingDocumentIds,
      };
      return state;
    },
    resetAddDocsOnClose: (state) => {
      state = {
        ...initialState,
        documentDetailMap: state.documentDetailMap,
        remoteIdToDocIdMap: state.remoteIdToDocIdMap,
        existingDocumentIds: state.existingDocumentIds,
      };
      return state;
    },
    resetAllAddDocsStates: (state) => {
      state = initialState;
      return state;
    },
    setCheckSelection: (
      state,
      action: PayloadAction<{ checked: boolean; id: string }>
    ) => {
      // Add doc to checkbox state map
      const { checked, id } = action.payload;
      state.checkSelections = {
        ...state.checkSelections,
        ...{ [id]: checked },
      };
      // Clear selection state for children
      Object.keys(state.checkSelections).forEach((selectionId) => {
        if (selectionId === id) return;
        const doc = state.documentDetailMap[selectionId];
        if (doc && (doc.path?.includes(id) || doc.repo_id === id)) {
          delete state.checkSelections[selectionId];
        }
      });
      return state;
    },
    upsertDocumentDetailMap: (
      state,
      action: PayloadAction<SimpleDocumentType[]>
    ) => {
      // Add new docs to doc detail map
      const updates = action.payload.reduce<Record<string, SimpleDocumentType>>(
        (map, doc) => {
          map[doc.id] = doc;
          return map;
        },
        {}
      );
      state.documentDetailMap = {
        ...state.documentDetailMap,
        ...updates,
      };
      return state;
    },
    setExpandedSearchType: (
      state,
      action: PayloadAction<SearchType | null>
    ) => {
      state.expandedSearchType = action.payload;
      return state;
    },
    setSearchFilterResults: (
      state,
      action: PayloadAction<
        SearchFilterResultType & {
          type: SearchFilterType;
        }
      >
    ) => {
      const { type, ...rest } = action.payload;
      state.searchFilterResults = {
        ...state.searchFilterResults,
        [type]: rest,
      };
      return state;
    },
    setInitialDocuments: (state, action: PayloadAction<string[]>) => {
      state.existingDocumentIds = action.payload;
      return state;
    },
    setIsPreviewInitializing: (state, action: PayloadAction<boolean>) => {
      state.isPreviewInitializing = action.payload;
      return state;
    },
    addLoadingDocId: (state, action: PayloadAction<string>) => {
      state.loadingDocIds[action.payload] = true;
    },
    removeLoadingDocId: (state, action: PayloadAction<string>) => {
      delete state.loadingDocIds[action.payload];
    },
    setShouldRunDocsQuery: (state, action: PayloadAction<boolean>) => {
      state.shouldRunDocsQuery = action.payload;
      return state;
    },
    addSelectedRemoteResource: (
      state,
      action: PayloadAction<SharepointFastBuildNode>
    ) => {
      state.selectedSharepointResources = [
        ...state.selectedSharepointResources,
        action.payload,
      ];
      return state;
    },
    setSelectedSharepointResources: (
      state,
      action: PayloadAction<SharepointFastBuildNode[]>
    ) => {
      state.selectedSharepointResources = action.payload;
      return state;
    },
    removeSelectedRemoteIds: (
      state,
      action: PayloadAction<{ remoteIds: string[]; allowDuplicates: boolean }>
    ) => {
      const { remoteIds, allowDuplicates } = action.payload;

      // Get ingested doc ids for remote docs
      const docIds = remoteIds.reduce<string[]>((acc, cur) => {
        if (!cur) return acc;

        const docId = state.remoteIdToDocIdMap[cur];

        if (docId) {
          acc.push(docId);
        }

        return acc;
      }, []);

      // Remove the selected doc id
      const docIdSet = new Set(docIds);
      state.newDocumentIds = state.newDocumentIds.filter(
        (doc) => !docIdSet.has(doc)
      );

      // Remove the document from existing docs if we don't allow duplicates
      if (!allowDuplicates) {
        state.existingDocumentIds = state.existingDocumentIds.filter(
          (doc) => !docIdSet.has(doc)
        );
      }

      // Clean up non-static resources so we reference the most recently built document for the remote docs
      if (allowDuplicates) {
        const updatedDocMap = { ...state.documentDetailMap };
        docIds.forEach((id) => {
          delete updatedDocMap[id];
        });
        state.documentDetailMap = updatedDocMap;
        return state;
      }
    },
    setSharePointLoginState: (state, action: PayloadAction<LoginState>) => {
      state.sharePointLoginState = action.payload;
      return state;
    },
    setBoxLoginState: (state, action: PayloadAction<LoginState>) => {
      state.boxLoginState = action.payload;
      return state;
    },
    setDropboxLoginState: (state, action: PayloadAction<LoginState>) => {
      state.dropboxLoginState = action.payload;
      return state;
    },
    setDefaultOpenUploadState: (state, action: PayloadAction<boolean>) => {
      state.defaultOpenUpload = action.payload;
      return state;
    },
    setDefaultUploadType: (
      state,
      action: PayloadAction<UploadFileType | null>
    ) => {
      state.defaultUploadType = action.payload;
      return state;
    },
    setHasSelectAll: (state, action: PayloadAction<boolean>) => {
      state.hasSelectAll = action.payload;
      return state;
    },
    setPublicsSearchQuery: (state, action: PayloadAction<string>) => {
      state.publicsFilters.searchQuery = action.payload;
      return state;
    },
    setPublicsSelectedRange: (state, action: PayloadAction<CustomRange>) => {
      state.publicsFilters.dateRange = action.payload;
      return state;
    },
    setPublicsSelectedPreset: (
      state,
      action: PayloadAction<PresetFilterSelection | null>
    ) => {
      state.publicsFilters.selectedDatePreset = action.payload ?? null;
      return state;
    },
    setPublicsDocTypes: (state, action: PayloadAction<PublicDocType[]>) => {
      state.publicsFilters.docTypes = action.payload;
      return state;
    },
    setPublicsSelectedCompanyId: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.publicsFilters.companyId = action.payload;
      return state;
    },
    setPublicsCompanyOptions: (
      state,
      action: PayloadAction<CompanySourceFilter[] | null>
    ) => {
      state.publicsFilters.companyOptions = action.payload;
      return state;
    },
    addSelectedAzureFolder: (
      state,
      action: PayloadAction<AzureFastBuildNode>
    ) => {
      state.selectedAzureFolders = [
        ...state.selectedAzureFolders,
        action.payload,
      ];
      return state;
    },
    setSelectedAzureFolders: (
      state,
      action: PayloadAction<AzureFastBuildNode[]>
    ) => {
      state.selectedAzureFolders = action.payload;
      return state;
    },
    setClickPoints: (
      state,
      action: PayloadAction<{
        x: number | null;
        y: number | null;
        fastBuildNode: FastBuildNode<unknown, unknown> | null;
      }>
    ) => {
      state.clickPoints = {
        x: action.payload.x,
        y: action.payload.y,
        fastBuildNode: action.payload.fastBuildNode ?? null,
      };
      return state;
    },
    setDefaultModalProps: (
      state,
      action: PayloadAction<DefaultModalPropsType>
    ) => {
      state.defaultModalProps = action.payload;
      return state;
    },
    setSearchFilterChip: <T>(
      state,
      action: PayloadAction<SelectedResource<T> | null>
    ) => {
      state.searchFilterChip = action.payload;
      return state;
    },
    setMimeTypeSearchFilter: (
      state,
      action: PayloadAction<SharePointMimeType | null>
    ) => {
      state.mimeTypeSearchFilter = action.payload;
      return state;
    },
    setSharePointMetadataFilter: (
      state,
      action: PayloadAction<SharePointMetadataType | null>
    ) => {
      state.sharePointMetadataFilter = action.payload;
      return state;
    },
    setSelectedS3AuthContext: (
      state,
      action: PayloadAction<S3AuthContext | null>
    ) => {
      state.selectedS3AuthContext = action.payload;
      return state;
    },
    setSelectedS3Key: (state, action: PayloadAction<string | null>) => {
      state.selectedS3Key = action.payload;
      return state;
    },
    setSelectedBoxResource: (
      state,
      action: PayloadAction<SelectedBoxResource | null>
    ) => {
      state.selectedBoxResource = action.payload;
      return state;
    },
    setSelectedDropboxResource: (
      state,
      action: PayloadAction<SelectedDropboxResource | null>
    ) => {
      state.selectedDropboxResource = action.payload;
      return state;
    },
    setEnableAncestors: (state, action: PayloadAction<boolean>) => {
      state.enableAncestors = action.payload;
      return state;
    },
    setDocumentListSelectedIds: (
      state,
      action: PayloadAction<{ documentListId: string; documentIds: string[] }>
    ) => {
      const { documentListId, documentIds } = action.payload;

      // if a document was deselected we need to remove it from the existingDocumentIds list
      const last = state.documentListSelectedDocIds[documentListId];
      const nextIds = new Set(documentIds);
      const removals = last ? last.filter((i) => !nextIds.has(i)) : [];

      if (removals.length) {
        const removalSet = new Set(removals);
        state.existingDocumentIds = state.existingDocumentIds.filter(
          (i) => !removalSet.has(i)
        );
      }

      state.documentListSelectedDocIds = {
        ...state.documentListSelectedDocIds,
        [action.payload.documentListId]: action.payload.documentIds,
      };
      return state;
    },
    setSelectedThirdBridgeResource: (
      state,
      action: PayloadAction<SelectedThirdBridgeResource | null>
    ) => {
      state.selectedThirdBridgeResource = action.payload;
      return state;
    },
    setOpenAddURLButton: (state, action: PayloadAction<boolean>) => {
      state.openAddURLButton = action.payload;
      return state;
    },
    setSelectedCrawlNodes: (
      state,
      action: PayloadAction<FastBuildNode<unknown, unknown>[]>
    ) => {
      state.selectedCrawlNodes = action.payload;
      return state;
    },
  },
});

export const {
  setActiveDocSource,
  setSearch,
  setActiveSort,
  setActiveSearch,
  setActiveFilters,
  setFastBuildSearchFilters,
  setOpenSearchPalette,
  updateDocumentList,
  clearDocumentListAndSelections,
  setCheckSelection,
  setExpandedSearchType,
  setSearchFilterResults,
  resetAddDocsOnClose,
  resetAllAddDocsStates,
  setShouldRunDocsQuery,
  setInitialDocuments,
  addLoadingDocId,
  removeLoadingDocId,
  setIsPreviewInitializing,
  upsertDocumentDetailMap,
  addSelectedRemoteResource,
  setSelectedSharepointResources,
  removeSelectedRemoteIds,
  setSharePointLoginState,
  setDefaultOpenUploadState,
  setDefaultUploadType,
  setHasSelectAll,
  setPublicsSearchQuery,
  setPublicsSelectedRange,
  setPublicsSelectedPreset,
  setPublicsDocTypes,
  setPublicsSelectedCompanyId,
  setPublicsCompanyOptions,
  addSelectedAzureFolder,
  setSelectedAzureFolders,
  removeDocByDocId,
  initializeDocumentList,
  setClickPoints,
  setDefaultModalProps,
  setSearchFilterChip,
  setMimeTypeSearchFilter,
  setSharePointMetadataFilter,
  setSelectedS3AuthContext,
  setSelectedS3Key,
  setBoxLoginState,
  setSelectedBoxResource,
  setSelectedDropboxResource,
  setEnableAncestors,
  setDocumentListSelectedIds,
  setSelectedThirdBridgeResource,
  setOpenAddURLButton,
  setSelectedCrawlNodes,
  setDropboxLoginState,
} = addDocsSlice.actions;
export const addDocsReducer = addDocsSlice.reducer;
