import React, { ReactElement, useEffect } from "react";
import { Button, ButtonProps, classNames, Menu, Skeleton } from "core";
import {
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  offset,
  UseDismissProps,
} from "@floating-ui/react";
import { Virtuoso } from "react-virtuoso";
import { useComponentId } from "../../hooks/useComponentId";
import { MenuContext, MenuProvider, useMenu } from "../Menu/MenuContext";
import { useMenuKeyboardEvents } from "../../hooks/useMenuKeyboardEvents";
import { useChildComponentId } from "../../hooks/useChildComponentId";

export interface SelectProps<T extends { value: string; name: string }>
  extends Omit<ButtonProps, "value"> {
  value: T | T[];
  data: T[];
  onSelectItem: (item: T) => void;
  handleSearchData: (query: string, data: T[]) => T[];
  renderItem: (item: T, selected: boolean) => ReactElement;
  styles?: {
    Menu?: string;
    Input?: string;
  };
  isLoading?: boolean;
  dropdownComponents?: ReactElement;
  search?: {
    placeholder: string;
  };
  closeOnClick?: boolean;
  multiple?: boolean;
  clear?: {
    label: string;
    onClear: (e: any) => void;
  };
  dismissProps?: UseDismissProps;
  virtualize?: boolean; // New prop to enable virtualization
}

const SelectInner = <T extends { value: string; name: string }>({
  value,
  onChange,
  handleSearchData,
  data,
  onSelectItem,
  renderItem,
  id,
  styles,
  children,
  isLoading,
  dropdownComponents,
  search,
  closeOnClick,
  multiple,
  clear,
  dismissProps,
  virtualize = false,
}: SelectProps<T>) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [query, setQuery] = React.useState("");

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    middleware: [offset(3)],
    onOpenChange: setIsOpen,
  });

  const dismiss = useDismiss(context, dismissProps);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);
  const menuContext = useMenu();
  const { setFocusedIndex } = menuContext;

  const filteredData = handleSearchData(query, data);

  const buttonId = useComponentId(id, "Select");
  const menuId = useChildComponentId(buttonId, "Menu");

  const handleKeyDown = useMenuKeyboardEvents({
    handleEscapeKeyDown: (e) => {
      if (isOpen) {
        e.stopPropagation(); // prevent event from propagating and closing outer modals
        setIsOpen(false);
      }
    },
    enabled: isOpen,
  });

  useEffect(() => {
    if (!isOpen) {
      // we need to reset the focusedIndex each time the menu is closed
      setFocusedIndex(-1);
    }
  }, [isOpen, setFocusedIndex]);

  return (
    <>
      <Button
        {...getReferenceProps()}
        onClick={(event) => {
          onChange?.(event);
          setIsOpen(!isOpen);
        }}
        className={classNames(
          "gap-1 rounded-[4px] border border-gray-200 bg-white px-2 py-1.5 shadow",
          styles?.Input
        )}
        ref={refs.setReference}
        id={buttonId}
        aria-expanded={isOpen}
        aria-owns={menuId}
        aria-haspopup="listbox"
        aria-autocomplete="list"
        onKeyDown={handleKeyDown}
        variant="outlined"
        size="lg"
      >
        {children}
      </Button>
      <FloatingPortal>
        {isOpen && (
          <Menu
            ref={refs.setFloating}
            style={floatingStyles}
            className={classNames(
              "z-50 flex max-h-[300px] w-[280px] flex-col overflow-hidden p-0",
              styles?.Menu
            )}
            variant={"elevated"}
            role="listbox"
            id={menuId}
            {...getFloatingProps()}
          >
            {search && (
              <input
                type="text"
                value={query}
                placeholder={search.placeholder}
                onChange={(e) => setQuery(e.target.value)}
                className="w-full border-b border-gray-200 px-4 py-2 text-sm leading-5 text-neutral-800 outline-none placeholder:text-gray-400"
              />
            )}
            {!!dropdownComponents && dropdownComponents}

            {clear && (
              <Button
                onClick={clear.onClear}
                variant="text"
                className="ml-1.5 mt-0.5 flex flex-none items-center text-sm font-normal text-gray-400 hover:from-black/[0] hover:to-black/[0] active:from-black/[0] active:to-black/[0]"
              >
                {clear.label}
              </Button>
            )}

            <div className="force-show-scrollbar mx-1 mt-1 flex flex-col overflow-scroll">
              {isLoading ? (
                <div className="p-2">
                  <Skeleton height={14} />
                </div>
              ) : filteredData.length === 0 ? (
                <div className="relative cursor-default select-none px-2.5 py-2 text-gray-600">
                  Nothing found.
                </div>
              ) : virtualize ? (
                <Virtuoso
                  className="force-show-scrollbar"
                  data={filteredData}
                  style={{
                    height: Math.min(filteredData.length * 28, 300),
                    maxHeight: 300,
                  }}
                  itemContent={(index, item) => (
                    <MenuContext.Provider
                      value={{
                        ...menuContext,
                        parentId: menuId,
                        getMenuItemProps: () => ({
                          role: "option",
                          tabIndex: -1,
                          onClick: () => {
                            closeOnClick && setIsOpen(false);
                            onSelectItem(item);
                          },
                        }),
                      }}
                    >
                      {renderItem(
                        item,
                        Array.isArray(value)
                          ? value.some(({ value }) => value === item.value)
                          : value?.value === item.value
                      )}
                    </MenuContext.Provider>
                  )}
                />
              ) : (
                filteredData.map((item: T, index) => (
                  <MenuContext.Provider
                    key={index}
                    value={{
                      ...menuContext,
                      parentId: menuId,
                      getMenuItemProps: () => ({
                        role: "option",
                        tabIndex: -1,
                        onClick: () => {
                          closeOnClick && setIsOpen(false);
                          onSelectItem(item);
                        },
                      }),
                    }}
                  >
                    {renderItem(
                      item,
                      Array.isArray(value)
                        ? value.some(({ value }) => value === item.value)
                        : value?.value === item.value
                    )}
                  </MenuContext.Provider>
                ))
              )}
            </div>
          </Menu>
        )}
      </FloatingPortal>
    </>
  );
};

const Select = <T extends { value: string; name: string }>(
  props: SelectProps<T>
) => {
  return (
    <MenuProvider>
      <SelectInner {...props} />
    </MenuProvider>
  );
};

export default Select;
