import { DocumentType, TableType } from "source/Types";
import axios from "../axios";
import {
  BuildResponse,
  DocByIdResponse,
  DocChildrenResponse,
  DocCountParams,
  DocCountResultsResponse,
  DocDeletionResponse,
  DocTableStatusResponse,
  DocTablesResponseTable,
  DocsBatchResponse,
  ExtractTablesParams,
  MoveDocResponse,
  UnsupportedDocsResponse,
  UpdateDocResponse,
  UpdateTablesStaticResponse,
  SimpleDocsBatchResponse,
  SharedDocViewerResponse,
} from "./types";
import fetchAccessToken from "../../auth/fetchAccessToken";
import { WEBSERVER_URL } from "../../envConstants";
import { getAuthToken } from "../../auth/localStorage";

export const Docs = {
  getDocById: (docId: string): Promise<DocByIdResponse> =>
    axios.get(`/v2/docs/${docId}`).then(({ data }) => data),

  getDocChildren: (
    docId: string,
    params: { page: number; size?: number }
  ): Promise<DocChildrenResponse> =>
    axios
      .get(`/v2/docs/${docId}/children`, { params })
      .then(({ data }) => data),

  getDocCount: (
    repoId: string,
    params: DocCountParams
  ): Promise<DocCountResultsResponse> =>
    axios.post(`/v2/docs/${repoId}/count`, params).then(({ data }) => data),

  getDocsBatch: (docIds: string[]): Promise<DocsBatchResponse> =>
    axios.post(`/v2/docs/batch`, { doc_ids: docIds }).then(({ data }) => data),

  getSimpleDocsBatch: (
    docIds: string[],
    matrixId: string
  ): Promise<SimpleDocsBatchResponse> =>
    axios
      .post(`/sheets/docs/batch/simple`, {
        doc_ids: docIds,
        sheet_id: matrixId,
      })
      .then(({ data }) => data),

  getRows: (matrixId: string): Promise<SimpleDocsBatchResponse> => {
    return axios.get(`/sheets/${matrixId}/rows`).then(({ data }) => {
      const rowIds = Object.keys(data.rows);
      const docs = rowIds.map((rowId) => {
        return data.rows[rowId];
      });
      return { docs };
    });
  },

  delete: (docId: string): Promise<DocDeletionResponse> =>
    axios.delete(`/v2/docs/${docId}`).then(({ data }) => data),

  update: async (
    docId: string,
    doc: Partial<DocumentType>
  ): Promise<UpdateDocResponse> =>
    axios.put(`/v2/docs/${docId}`, doc).then(({ data }) => data),

  updateData: async (docId: string, data: any): Promise<UpdateDocResponse> =>
    axios.put(`/v2/docs/${docId}/data`, data).then(({ data }) => data),

  updateTablesStatic: async (
    docId: string,
    data: { repo_id: string; tables_data: TableType[] }
  ): Promise<UpdateTablesStaticResponse> =>
    axios.put(`/v2/docs/${docId}/tables-static`, data).then(({ data }) => data),

  move: (docId: string, targetParentId?: string): Promise<MoveDocResponse> =>
    axios
      .put(`/v2/docs/${docId}/move`, { target_parent_id: targetParentId })
      .then(({ data }) => data),

  setPassword: async (docId: string, password: string) =>
    axios.post(`/v2/docs/${docId}/password`, { password }),

  hardBuild: async (docId: string): Promise<BuildResponse> =>
    axios.post(`/v2/docs/${docId}/build`).then(({ data }) => data),

  softBuild: async (docId: string): Promise<BuildResponse> =>
    axios.post(`/v2/docs/${docId}/soft-build`).then(({ data }) => data),

  getUnsupportedDocs: (repoId: string): Promise<UnsupportedDocsResponse> =>
    axios.get(`/v2/repos/${repoId}/unsupported-docs`).then(({ data }) => data),

  downloadTables: (
    docId: string,
    tableUrls: string[],
    imgUrls: string[] | undefined
  ) =>
    axios.post(
      `/v2/docs/${docId}/download-tables`,
      {
        table_urls: tableUrls,
        img_urls: imgUrls,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/vnd.ms-excel",
        },
        responseType: "blob",
      }
    ),

  extractSnippetTables: (docId: string, params: ExtractTablesParams) =>
    axios.post(`/v2/docs/${docId}/extract-snippet-tables`, params),

  getTablesStatus: (
    docId: string,
    jobId: string
  ): Promise<DocTableStatusResponse> =>
    axios
      .get(`/v2/docs/${docId}/tables-status/${jobId}`)
      .then(({ data }) => data),

  getDocTables: (docId: string): Promise<DocTablesResponseTable> =>
    axios.get(`/v2/docs/${docId}/tables-static`).then(({ data }) => data),

  getChildrenCount: (docId: string): Promise<number> =>
    axios
      .get(`/v2/docs/${docId}/count_children`)
      .then(({ data }) => data.count),

  getDocViewerAsPDF: (docId: string): Promise<string> =>
    axios
      .get(`/v2/docs/${docId}/viewer/as_pdf`, { responseType: "blob" })
      .then(({ data }) => URL.createObjectURL(data)),
  getSharedDocViewer: (
    docId: string,
    matrixId: string,
    orgId: string
  ): Promise<SharedDocViewerResponse> =>
    // Sheets endpoint to enable sharing local uploads and SharePoint docs on Matrix
    // NB: Expected to fail for non-local-uploads and non-SharePoint docs
    axios
      .get(`/sheets/docs/shared-doc-content/${docId}`, {
        params: { sheet_id: matrixId, org_id: orgId },
      })
      .then(({ data }) => data),
  getSharedDoc: async (
    docId: string,
    matrixId: string,
    orgId: string
  ): Promise<DocByIdResponse> => {
    // Sheets endpoint to enable local upload + sharepoint doc sharing on Matrix
    // NB: Expected to fail for non-local-upload and non-sharepoint docs
    const response = await axios.get(`/sheets/docs/shared-doc/${docId}`, {
      params: { sheet_id: matrixId, org_id: orgId },
    });
    return response.data;
  },
  getCustomerOwnedS3Doc: async (docId: string) => {
    let accessToken = getAuthToken();
    if (!accessToken) {
      const response = await fetchAccessToken();
      accessToken = response.accessToken;
    }
    const response = await fetch(`${WEBSERVER_URL}v2/docs/s3/${docId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  },
};
